import { useFetch } from 'hooks/useFetch';
import CampTicket from 'pages/Parents/components/CampTicket';
import React from 'react'
import { Link } from 'react-router-dom';
import {
    Button,
  } from "reactstrap";
export default function CampList() {
    const {data} = useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/getAllCourses');
  return (
    <div className="camps-section py-5">
    <div className="container">
        <h1 className='text-center about__h2 py-3'>choose a camp and hold tight for adventure</h1>
        {data && data.courses.length===0 && <h2 className='text-center'>Opps! No course are available.</h2>}
           <div className="row justify-content-center">
           {/* { data && data.courses.map((each)=>(<div className="col-sm-4" key={each.course_id}>
                <div className="card ticket">
                  <div className="card-header text-center text-uppercase"><p>{each.course_name}</p></div>
                  <div className="card-body text-center pt-3">
                    <h2 className='pb-3'>{new Date(each.course_from_date).toLocaleDateString('en-US', { day: '2-digit', month: 'short' })} -
                    {new Date(each.course_to_date).toLocaleDateString('en-US', { day: '2-digit', month: 'short' })}
                    </h2>
                    <div className="row">
                      <div className="col-6 inner-section py-3">
                          <p>DAYS</p>
                          <p>{(Math.ceil((new Date(each.course_to_date) - new Date(each.course_from_date)) / (1000 * 60 * 60 * 24)))+1}</p>
                      </div>
                      <div className="col-6 inner-section  py-3">
                          <p>AGES</p>
                          <p>{each.from_age}-{each.to_age}</p>
                      </div>
                    </div>
                    <div className=" text-center seats-filled py-3">
                      ₹{each.least_price} - ₹{each.most_price}
                    </div>
                    <div className=" text-center seats-filled py-3">
                      <small>SEATS ARE FILLING FAST</small>
                    </div>
                    {data && data.courses.length>0 && <div><Link to="/auth/login"><Button className='book-btn w-100 border-0 text-white'>BOOK</Button></Link></div>}
                  </div>
                </div>
              </div>))} */}
              {data && data.courses.map((each)=>(
                <CampTicket
                  key={each.course_id} 
                  title={each.course_name} 
                  startDate={new Date(each.course_from_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}
                  endDate={new Date(each.course_to_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}
                  days= {Math.ceil((new Date(each.course_to_date) - new Date(each.course_from_date)) / (1000 * 60 * 60 * 24))}
                  ages={`${each.from_age} to ${each.to_age}`} 
                  price={`${new Intl.NumberFormat('en-US').format(each.least_price)} - ${new Intl.NumberFormat('en-US').format(each.most_price)+1}`} 
                  seatsFilled={each.total_bookings} 
                  totalSeats={each.boys_capacity + each.girls_capacity}
                  link = {`/auth/login`}
                />
            ))}
            </div>
      </div>
      <div className="py-3 text-center"><Link to="/auth/login"><Button color="primary px-5">VIEW ALL</Button></Link></div>
      </div>
  )
}
