import { useFetch } from 'hooks/useFetch';
import React from 'react'
import { Link } from 'react-router-dom';
import { CardFooter } from 'reactstrap'
           
import {
    Row,
    Card,
    CardHeader,
    Container,
    Table,
    Pagination,
    Badge,
    PaginationItem,
    PaginationLink,
  } from 'reactstrap';

export default function ViewAllCourses() {
    const {data:allCourses}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getAllCourseForAdmin/');
  return (
    <div className="container pt-8 pb-4">
        <Container className="mt-2" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
                <CardHeader className="border-0">
                    <h3 className="mb-0">All Courses</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Course Name</th>
                            <th>Certification</th>
                            <th scope="col">Course Start Date</th>
                            <th scope="col">Course End Date</th>
                            <th>Age</th>
                            <th>Boys / Girls Capacity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allCourses && allCourses.map((course)=>(<tr key={course.course_id}>
                           <th scope="row">
                            <span>{course.archive===0 && <Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" />
                            </Badge>}
                            {course.archive===1 && <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                            </Badge>}</span><Link to={`/admin/course-detail/${course.course_id}`}>{course.course_name}</Link>
                            </th>
                            <td>{course.certification===1 && <span>Yes</span>}{course.certification===0 &&<span>No</span>}</td>
                            <td>{new Date(course.course_from_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>{new Date(course.course_to_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>{course.from_age} to {course.to_age}</td>
                            <td>{course.boys_capacity} / {course.girls_capacity}</td>         
                        </tr>))}
                    </tbody>
                </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}
