
// reactstrap components
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useAuthContext } from "hooks/useAuthContext";
import codes from 'country-calling-code';

const Login = () => {
  const [contactNumber,setContactNumber]= useState('');
  const [countryCode,setCountryCode] = useState('');
  const [showForm,setShowForm]=useState(true);
  const [otp,setOtp]=useState('');
  const navigate=useNavigate();
  const{dispatch}=useAuthContext();
  const [sendingOtp, setSendingOtp] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleCheckboxChange = (event) => {
    if(event.target.checked){
      setModalOpen(true);
    }
    else{
      setModalOpen(false);
    }
  };

  const handleOtpValidation=async(e)=>{
    e.preventDefault();
    console.log("+"+countryCode)
    if(countryCode === ""){
      NotificationManager.error('Please select country code' , '', 3000);
      return;
    }
    try{
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {phone_number :"+"+countryCode+String(contactNumber)}
        ),
      };
      setSendingOtp(true)
      const res = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/login-otp/', requestOptions);
      console.log(res)
      if(!res.ok){
        setSendingOtp(false)
        throw new Error('Unable to register');
      }
      const data = await res.json();
      if(data.status !==200){
        setSendingOtp(false)
        throw new Error(data.Error);
      }
      setSendingOtp(false)
      NotificationManager.success('OTP sent successfully!' , '', 3000);
      setShowForm(false);
    }catch(err){
      setShowForm(true);
      NotificationManager.error(err.message , 'Please register', 2000);
    }

  }

  const handleOtpVerification=async(e)=>{
    e.preventDefault();
    try{
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            phone_number :"+"+countryCode+String(contactNumber),
            otp:Number(otp)
          }
        ),
      };
      const res = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/verify-login/', requestOptions);
      console.log(res)
      if(!res.ok){
        throw new Error('Unable to login');
      }
      const data = await res.json();
      if(data.status !==200){
        throw new Error(data.Error);
      }
      NotificationManager.success('OTP verifed successfully!' , '', 3000);
      localStorage.setItem('user',JSON.stringify(data));
      dispatch({type:'LOGIN',payload:data});
      navigate('/parent/index')
    }catch(err){
      NotificationManager.error(err.message , '', 3000);
    }
  }
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Note</ModalHeader>
          <ModalBody>
            <b>New campers:</b> Register and book directly.
            <br/><br/>
            <b>Returning campers:</b> Let us update your profile first, then you may proceed to book your camp.
            <br/><br/>
            📝To register your profile and for tech support, contact Suraj: <a href="tel:+919860652967">+91 9860652967</a> [ Thurs-Tues, 10am-6pm]
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
      </Modal>
    <NotificationContainer/>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5 text-center">
            <div className="text-center text-muted mb-4">
              <h2>Sign In</h2>
            </div>
            {showForm && <form  onSubmit={handleOtpValidation}>
            <FormGroup>
            <div className="input-group mb-3">
            <div className="input-group-prepend">
              <Input type="select" onChange={(e)=>setCountryCode(e.target.value)} style={{ background: "#ececec" }}>
                <option value="">Select Code</option>
                {codes && codes.map((each) => (
                  <option key={each.isoCode3} value={each.countryCodes[0]}>
                    {each.isoCode3} {" "} +({each.countryCodes[0]})
                  </option>
                ))}
              </Input>
            </div>
          <Input
          placeholder="WhatsApp Number"
          type="text"
          value={contactNumber}
          onChange={e => setContactNumber(e.target.value)}
        />
      </div>
              </FormGroup>
              {/* <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="WhatsApp Number"
                    type="number"
                    value={contactNumber}
                    onChange={e=>setContactNumber(e.target.value)}
                  />
                </InputGroup>
              </FormGroup> */}
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" onChange={handleCheckboxChange} style={{ border: '2px solid black' }} /> Are you a previous camper?
                </Label>
              </FormGroup>
              <div className="text-center">
                {!sendingOtp && <Button className="my-4" color="primary" type="submit">
                  Send OTP
                </Button>}
                {sendingOtp && <Button disabled className="my-4" color="primary" type="submit">
                  Sending...
                </Button>}
              </div>
            </form>}
            {!showForm && <form  onSubmit={handleOtpVerification}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter OTP"
                    type="text"
                    value={otp}
                    onChange={e=>setOtp(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Verify OTP
                </Button>
              </div>
            </form>}
            
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col className="text-center" xs="12">
            <Link
              className="text-light"
              to="/auth/register"
            >
              <small>Create new account</small>
            </Link>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Login;
