import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  CustomInput,
} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { usePost } from 'hooks/usePost';
import { useFetch } from 'hooks/useFetch';  

export default function CreateCoupons() {
  const navigate = useNavigate();
  const [couponName, setCouponName] = useState('');
  const [couponDesc, setCouponDesc] = useState('');
  const [giftCardDiscount, setGiftCardDiscount] = useState('');
  const [couponDiscount, setCouponDiscount] = useState('');
  const [isCouponActive, setIsCouponActive] = useState('');
  const [isPercentDiscount, setIsPercentDiscount] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const {postData,resData}=usePost();
  const [isGiftCard, setIsGiftCard] = useState(0)

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic to handle form submission here
    if (!couponName) {
        NotificationManager.error('Please fill in the coupon name', '', 3000);
        return;
    }

    if (isPercentDiscount==='' && !isGiftCard) {
        NotificationManager.error('Please specify if the discount is in percentage', '', 3000);
        return;
    }
    
    if (!couponDiscount && !isGiftCard) {
        NotificationManager.error('Please fill in the coupon discount', '', 3000);
        return;
    }

    if(giftCardDiscount ==='' && isGiftCard){
      NotificationManager.error('Please fill in the gift card discount', '', 3000);
      return;
    }

    if (!couponCode) {
        NotificationManager.error('Please fill in the coupon code', '', 3000);
        return;
    }
    
    if (isCouponActive==='') {
        NotificationManager.error('Please specify if the coupon is active', '', 3000);
        return;
    }
    
  
    const formData = {
        coupon_name : couponName,
        coupon_description : couponDesc,
        coupon_discount : Number(couponDiscount),
        is_active : Number(isCouponActive),
        is_percent_discount : Number(isPercentDiscount),
        is_gift_card: Number(isGiftCard),
        gift_card_amount:Number(giftCardDiscount),
        is_gift_card_redeemed: 0,
        coupon_code:couponCode,
        archive : 0
    };
    console.log(formData);
    postData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/createCoupon/",formData,"post");
  };

  useEffect(() => {
    if(resData && resData.status===200){
        NotificationManager.success(resData.Message, '', 2000);
        navigate('/admin/view-all-coupons');
    }
  }, [resData])
  

  return (
    <div className="container pt-7">
      <NotificationContainer />
      <Form onSubmit={handleSubmit}>
        <h6 className="heading-small text-muted mb-4">Coupon Information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label for="couponName">Coupon Name</Label>
                <Input
                  type="text"
                  id="couponName"
                  placeholder="Coupon Name"
                  name="couponName"
                  onChange={(e) => setCouponName(e.target.value)}
                  value={couponName}
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label for="couponDesc">Coupon Description</Label>
                <Input
                  type="text"
                  id="couponDesc"
                  placeholder="Coupon Description"
                  name="couponDesc"
                  onChange={(e) => setCouponDesc(e.target.value)}
                  value={couponDesc}
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={isGiftCard===1?"6":"4"}>
              <FormGroup>
                <Label for="exampleCheckbox">
                  Is the Coupon a Gift Card?
                </Label>
                <div>
                  <CustomInput
                    type="radio"
                    id="isGiftCard"
                    name="isGiftCard"
                    label="Yes"
                    inline
                    value="1"
                    onChange={() => setIsGiftCard(1)}
                  />
                  <CustomInput
                    type="radio"
                    id="noGiftCard"
                    name="isGiftCard"
                    label="No"
                    inline
                    value="0"
                    onChange={() => setIsGiftCard(0)}
                  />
                </div>
              </FormGroup>
            </Col>
          { !isGiftCard && 
            <Col lg="4">
              <FormGroup>
                <Label for="exampleCheckbox">
                  Create coupon with percentage discount
                </Label>
                <div>
                  <CustomInput
                    type="radio"
                    id="yespercent"
                    name="isPercentDiscount"
                    label="Yes"
                    inline
                    value="1"
                    onChange={() => setIsPercentDiscount(1)}
                  />
                  <CustomInput
                    type="radio"
                    id="nopercent"
                    name="isPercentDiscount"
                    label="No"
                    inline
                    value="0"
                    onChange={() => setIsPercentDiscount(0)}
                  />
                </div>
              </FormGroup>
            </Col>}
          {!isGiftCard &&  
            <Col lg="4">
              <FormGroup>
                <Label for="couponDiscount">Coupon Discount</Label>
                <Input
                  type="text"
                  id="couponDiscount"
                  placeholder="Coupon Discount"
                  name="couponDesc"
                  value={couponDiscount}
                  onChange={(e) => setCouponDiscount(e.target.value)}
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
          }
          { isGiftCard===1 && <Col lg="6">
              <FormGroup>
                <Label for="giftCardDiscount">Gift Card Discount</Label>
                <Input
                  type="text"
                  id="giftCardDiscount"
                  placeholder="Mention a percent amount(eg. 100)"
                  name="giftCardDisc"
                  value={giftCardDiscount}
                  onChange={(e) => setGiftCardDiscount(e.target.value)}
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>}
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label for="couponDiscount">Coupon Code</Label>
                <Input
                  type="text"
                  id="couponCode"
                  placeholder="Coupon Code"
                  name="couponCode"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label for="exampleCheckbox">Activate Coupon</Label>
                <div>
                  <CustomInput
                    type="radio"
                    id="yes"
                    name="isCouponActive"
                    label="Yes"
                    inline
                    value="1"
                    onChange={() => setIsCouponActive(1)}
                  />
                  <CustomInput
                    type="radio"
                    id="no"
                    name="isCouponActive"
                    label="No"
                    inline
                    value="0"
                    onChange={() => setIsCouponActive(0)}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Button type="submit" color="primary">
              Create Coupon
            </Button>
          </Row>
        </div>
      </Form>
    </div>
  );
}
