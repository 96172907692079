import React from "react";
import { useFetch } from "hooks/useFetch";
import { useParams, Link } from "react-router-dom";
import Loader from "components/Loader";
import localImage from "../../assets/img/theme/counter-bg.jpg";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useAuthContext } from "hooks/useAuthContext";

import "./Cart.css";
import {
  Row,
  Container,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export default function FetchRRPOrders() {
  const { parentId } = useParams();
  const { user } = useAuthContext();
  const { data: orderData, isPending } = useFetch(
    process.env.REACT_APP_DJANGO_SERVER_URL +
      "/api/fetchRRPOrders/" +
      user.user_id
  );
  return (
    <>
      {isPending && <Loader />}
      {orderData && (
        <Card className="bg-secondary shadow mt-5">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h2 className="mb-0">RRP Order History</h2>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="row">
              {orderData && orderData.length === 0 && (
                <div className="text-center py-3">
                  <h1>Explore & Create History!</h1>
                  <small>RRP Orders Not Found!</small>
                  <br /> <br />
                  <Link to="/parent/index">
                    <Button color="primary">Explore</Button>
                  </Link>
                </div>
              )}
              {orderData &&
                orderData.map((each) => (
                  <div className="col-lg-4" key={each.rrp_order_id}>
                    <div className="card mb-4 ">
                      <div>
                        <span className="badge badge-success bg-success position-absolute top-3 left-3">
                          <strong className="text-white">
                            Guest Count: {each.guest_count}
                          </strong>
                        </span>
                        <img
                          src={localImage}
                          className="card-img-top w-100 postion-relative"
                          style={{
                            height: "180px",
                            objectFit: "cover",
                          }}
                          alt="..."
                        />
                      </div>
                      <div className="card-body">
                        <div className="row my-3">
                          <div className="col-sm-6">
                            <div>
                              <p>
                                {" "}
                                <small>Start Date</small>
                              </p>
                              <p className="mt--3">
                                <i className="ni ni-calendar-grid-58"></i>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "15px",
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;
                                  {new Date(
                                    each.checkin_date
                                  ).toLocaleDateString(undefined, {
                                    month: "short",
                                    day: "numeric",
                                    year: "2-digit",
                                  })}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div>
                              <p>
                                {" "}
                                <small>End Date</small>
                              </p>
                              <p className="mt--3 text-righ">
                                <i className="ni ni-calendar-grid-58"></i>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "15px",
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;
                                  {new Date(
                                    each.checkout_date
                                  ).toLocaleDateString(undefined, {
                                    month: "short",
                                    day: "numeric",
                                    year: "2-digit",
                                  })}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            <h3 className="card-text text-capitalize">
                              {each.course_name}
                            </h3>
                          </div>
                          <div className="col-sm-4">
                            <h3 className="card-text">
                              ₹ {each.payment_amount}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}
