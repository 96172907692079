import React, { useState } from "react";
import {
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  Row,
  Col
} from "reactstrap";

const familyPicnicFAQ = [
  {
    question: "What is included in the package?",
    answer: `During your visit, you shall be taken for a petting and animal feeding activity and served the complimentary meal of the respective slot. 
    A small bucket of animal treats is given to your child, and a member of our trained team escorts multiple groups. 
    All our animals are healthy, vaccinated, and safe to be around. Thereafter, soak in the lovely farm atmosphere under the shade of the beautiful trees, listening to the birds, and chill with our dogs.`,
  },
  {
    question: "Can we walk-in any time?",
    answer: `No. It’s necessary to make a booking in advance. We keep a count of people and stop bookings after we reach a particular number of people so that we are able to give everyone a good experience. 
    You can book your slots <a target="_blank" href="https://japalouppe.com/petting-farm/#book-now">here</a>.`,
  },
  {
    question: "Which day is the Petting Farm closed?",
    answer: `From April to June – we are closed on Mondays and Tuesdays and from July to March we are closed Tuesdays and Wednesdays.`,
  },
  {
    question:
      "My Parents are too old to walk on the farm, do I still have to pay for his/her picnic charges?",
    answer: `Yes, everyone who enters the farm has to pay the picnic charges. You can find a suitable slot for them to settle comfortably & soak in the farm atmosphere.`,
  },
  {
    question:
      "Our Child is only 16 months old, do we still have to pay the 'Kids' entry fee?",
    answer: `Babies under 2 years are not charged.`,
  },
  {
    question:
      "Can we bring our own food and can we get a discount for the same?",
    answer: `You are most welcome to bring your own food but the food we provide comes as a part of the package.`,
  },
  {
    question:
      "Our Drivers/ Maids/ Nannies need to have lunch, what would be the charges?",
    answer: `If your help would like to have a meal while you visit the farm, you may purchase it separately. The charges for the meal can be paid at our office, and they can enjoy their meal in the dedicated Driver’s Lounge located in the parking area. If they will be accompanying you inside the farm, you will be required to pay the full entry fee.`,
  },
  {
    question: "Can we stay longer than the time permitted by our package?",
    answer: `You are welcome to buy the next package and change the color bands.`,
  },
  {
    question: "Are your facilities handicapped accessible?",
    answer: `Yes, our facilities are handicapped accessible. However, we recommend contacting us in advance to discuss any specific needs you may have.`,
  },
  {
    question:
      "Can we have a birthday party or private event at the Petting Farm?",
    answer: `Yes, you can book the Petting Farm for birthday parties or private events. Please contact us for more details and to make a reservation.`,
  },
  {
    question:
      "Is there a limit to the number of people we can bring to the Petting Farm?",
    answer: `Yes, we have a limit on the number of people to ensure everyone has a good experience. Please contact us to discuss group bookings.`,
  },
  {
    question: "What safety measures are in place at the Petting Farm?",
    answer: `All our animals are healthy, vaccinated, and safe to be around. Our staff is trained to handle any emergencies, and we have first aid facilities on-site. We also follow all recommended health and safety guidelines.`,
  },
  {
    question: "Do you offer educational tours or school visits?",
    answer: `Yes, we offer educational tours and school visits. Please contact us for more details and to schedule a visit.`,
  },
  {
    question: "Can we interact with the animals?",
    answer: `Yes, you can interact with the animals during the petting and feeding activities. Our staff will guide you on how to safely interact with the animals.`,
  },
  {
    question: "Is there a dress code for visiting the Petting Farm?",
    answer: `We recommend wearing comfortable clothing and closed-toe shoes suitable for walking around the farm.`,
  },
  {
    question: "What happens if it rains on the day of our visit?",
    answer: `We have sheltered areas where you can stay if it rains. However, we recommend checking the weather forecast and rescheduling if necessary.`,
  },
  {
    question: "Can we get a refund if we cancel our booking?",
    answer: `Please refer to our cancellation policy on the website for details about refunds.`,
  },
];

const horseRidingLessonsFAQ = [
  {
    question: "How much time does it take to learn horse riding?",
    answer: `We are often asked this question? Well the answer depends “ on what you want to do.”

At Japalouppe we train beginner riders from the age of 6.5 years to grownups. Many kids who have trained with us are competing and many kids and grownups have taken it up as a life-time hobby

The speed of learning depends on one’s age, physicality, athleticism and how frequently they are taking the lessons. Lesser gap between two lessons is better. We suggest at-least one lesson a week if not more. This will help the rider make corrections from what he/she had learnt in the last lesson and even the instructor is in sync with the rider’s progress.

If you are a beginner and just want to know the basics of riding, we suggest at-least 20 hours to be spent in the saddle under the supervision of our professional instructors. If you want to take your skills to another level and do Show-Jumping then it would take a minimum of 50 hours of riding. Our horses are specially trained, in fact, we are the only riding school in India to breed and train Gypsy Vanner horses uniquely for riding purposes`,
  },
  {
    question:
      "How should I book a lesson, and what time should I arrive for my lesson? Or can I just walk in?",
    answer: `Lessons should be booked in advance so that we can match your age, weight, and skill level with a suitable horse. Please arrive at least 15 minutes before your riding lesson to obtain your riding coupon from the office. The instructor will be waiting and the horse will be saddled, all ready for you. Yes, we provide basic riding gear such as helmets and boots. However, we recommend wearing long pants and closed-toe shoes for safety.`,
  },
  {
    question: "Is there a dress code for riding?",
    answer: `Correct Riding Gear makes a lot of difference in the riding experience. If you do not have a helmet then we will provide one. It’s not necessary for the Beginners to buy a new kit. They can wear a stretch material pant with closed toe shoes with long socks. Once you progress and putting more hours on the saddle, you can invest in a Riding Kit. For more information  <a href="https://japalouppe.com/wp-content/uploads/2024/04/Riding-Lesson-Clothing.pdf" target="_blank">Click here</a>`,
  },
  {
    question: "I am 75 kgs? Can the horse carry my weight?",
    answer: `We do not have horses who can give beginner’s riding lessons to riders over 80kgs. Many times a heavier person who knows riding can be lighter on the horse’s back than a lighter person who is learning from scratch.`,
  },
  {
    question: "I know riding; can I come & ride alone?",
    answer: `At Japalouppe, even the experienced riders who compete in equestrian sports ride under the supervision of an instructor to fine tune their skills. You will be assigned an instructor according to your skill level.`,
  },
  {
    question:
      "I live far from Japalouppe & would be difficult to travel every time I need a lesson. Is there a way I can come and do a crash course?",
    answer: `Yes you can. We have dormitories & cottages which you can rent. We can make a Riding+Stay package for you.`,
  },
  {
    question: "Can I take lessons all year round at Japalouppe.?",
    answer: `Typically we ride every day except for Monday. You can call us and we will be more than happy to let you know the availability.`,
  },
  {
    question: "Do you have monthly or annual memberships?",
    answer: `Have you ever experienced the feeling of not going to the gym after paying for the full year? Exactly……we don’t like that feeling and nor do we want our riders to face that situation.

We believe that a rider should come and pay for a single ride and come as many times he or she wants to and that too at their own convenience.

For regular riders we have a package to buy 10 rides in advance and get one ride free. These package are valid for 120 days. Please refer to the ‘Riding Lessons’ service page.`,
  },
  {
    question: `Like Children's Camps, do you have horse riding camps for adults?`,
    answer: `While we don’t organise Adult Camps anymore, we do have a better  and more flexible alternative which is our Residential Riding Packages. Click here to <a href="https://japalouppe.com/riding-lessons/#residential-riding-package" target="_blank">know more<a> `,
  },
  {
    question: "How safe is horse-riding?",
    answer: `Very safe! Statistically you have a greater chance of falling off a motorcycle or a bicycle than off a horse. We at Japalouppe have a commitment to safety. Our horses are selected on the basis of their temperament and are further trained to be ‘rider friendly’. All riders are given horses suited to their level only and wearing riding helmets is compulsory.`,
  },
];
const ResidentialChildrenCampsFAQ = [
  {
    question:
      "My child has never stayed away from home before. Will he/she manage a full camp?",
    answer: `Most first time campers are happy in their new adventure. For the odd child that does get homesick we ensure that the child is comforted, receives some counselling and simple one-on-one attention. We’d appreciate your co-operation in trusting in our extensive experience in dealing with young campers. We go through approximately 1000 first time campers every year and many repeat campers. The camps at Japalouppe are designed to be entertaining, educative and eventful in a way that the kids settle down very quickly to enjoy the program.`,
  },
  {
    question: "Can I accompany my child for a camp or send a maid?",
    answer: `Japalouppe does not allow parents, family or friends to visit once the camp has begun. We find that it distracts the child from an already packed schedule. Such action also enhances the home-sickness feeling of the child and does not allow him/her to break the barrier of being able to stay away from their home. Other campers are also affected when a parent suddenly shows-up because they feel left out.`,
  },
  {
    question: `If I've forgotten to pack a few things which he/she was supposed to carry; can I come or send someone to hand deliver the things?`,
    answer: `Please notify the office or the camp coordinator about who is coming and at what time. You can leave the parcel in the office and we will make sure your child will receive it`,
  },
  {
    question: `My child is a poor eater & he/she has very specific tatse. Can I pack food for him/her?`,
    answer: `We make sure that all our food is nutritious and tasty. Not a single meal is repeated during the 6 day camp. The menu is designed in such a way that your child will enjoy most of the meals if not all.
One question we always ask the parents; would you like your child to open their own Tiffin boxes and enjoy their own food in front of other 50 campers? This would set a bad example for your own child and for the other campers who maybe are also missing their home food.
In our years of experience we have also noticed that kids bring a lot of junk-food which we strongly discourage because horse riding is a high intensity sport which needs lot of energy. Junk-food kills their appetite and they do not eat their meals which results in fatigue and a bad ‘Horse Riding Camp’ experience. If we find any kid with such food, we’ll make sure that all of it goes to Mini The Pig!
To satisfy their cravings, every evening we do give finger food like Smilies, Fries, Mini-pizzas, Chicken Nuggets, Maggie etc. which they can buy and you can settle the bills when you come on the last day for the display.`,
  },
  {
    question: `How can I stay in touch with my child while he/she is in camp?`,
    answer: `The horse riding camps at Japalouppe are designed to be an exercise in independence as well as for them to learn how to ride.
You are most welcome to send a cell phone with your child. However the cell phones will be kept in safe custody and will be given to them at a designated time every day so that your child can contact you and speak with you. Typically the time the phones will be given to them is between 1:00 pm & 3:00 pm, however please note this time may vary based on their daily activities and so we urge you to be patient in awaiting their call.
In case you choose NOT to send a cell phone with your child ask your child to come to the office and we will permit him/her to give you a call during the designated call timings. We urge you to restrict this call to not more than 5 mins as there might be other kids who would like to use the office phone.`,
  },
  {
    question: `What about I-pods, PSPs, Game Boys, Cameras and other electronic gadgets?`,
    answer: `We don’t allow any such electronic gadgets. Japalouppe does not take responsibility if any such expensive item is lost. Think of this as a chance to give your child a DIGITAL DETOX. Also, this allows the child to digitally disconnect and enjoy the wholesome experience of making new friends, interacting with the Japalouppe animals and embracing the natural beauty of the farm.`,
  },
  {
    question: `Do I need to send any cash with my child?`,
    answer: `There is no need for the children to have any money in their possession while at camp. In case the child needs anything extra, with your permission, the office will have it purchased and you can settle it on the last day of the camp.`,
  },
  {
    question: `Do you have a drop and pick up facility?`,
    answer: `We do offer a pickup facility for campers from Mumbai who have done camps at Japalouppe before. However we recommend that all first timers are dropped off so that the parents can attend the orientation session. We do not have a drop-off facility because all parents are expected to come on the last day to attend their child’s riding display.
In special cases where children are flying from different cities/countries we can arrange a pick-up and drop-off at the airport. Please contact the office for further information.

We believe that a rider should come and pay for a single ride and come as many times he or she wants to and that too at their own convenience.

For regular riders we have a package to buy 10 rides in advance and get one ride free. These package are valid for 120 days. Please refer to the ‘Riding Lessons’ service page.`,
  },
  {
    question: `What time do we check in for a camp?`,
    answer: `9:30 am is the standard reporting time for all camps. In case you are planning on reporting late please inform us in advance.`,
  },
  {
    question: `what time do we pick up the kids from the camp?`,
    answer: `Parents are expected to arrive at 2:00 pm on the last day of camp. The certificate distribution is done at 2:30 and the campers will give a horse riding display at 3:30 pm. The display gets over at around 5 pm. Friends and relatives are welcome to come and watch the display. The children always enjoy putting on a good show when they have an audience.
*Note: If there is a change in the above timing then Japalouppe will let you know.`,
  },
  {
    question: `How many camps are needed for my child to make progress?`,
    answer: `We recommend a minimum of 2 camps a year to make progress. The more camps your child attends, the better he/she will get. Many campers who continue to ride have also started to compete in various horse riding tournaments across the country.`,
  },
  {
    question: `I don't fit in to the required age group. Can I still do a camp?`,
    answer: `Yes, but not in the regular children’s camps. We do conduct specific camps for anyone who requests it. Check the Schedule for camp dates.`,
  },
  {
    question: `What will be the level of riding my child will achieve with the first camp?`,
    answer: `Level 1 will get your child to learn the correct posture of sitting astride a horse, getting comfortable around the horse and being able to rise to the trot independently. Each level subsequently, takes the rider further with various riding skills.`,
  },
  {
    question: `How safe is horse-riding?`,
    answer: `Very safe! Statistically you have a greater chance of falling off a motorcycle or a bicycle than off a horse. We at Japalouppe have a commitment to safety. Our horses are selected on the basis of their temperament and are further trained to be ‘rider friendly’. All riders are given horses suited to their level only and wearing riding helmets is compulsory.`,
  },
  {
    question: `My child does't have a riding helmet; what do I do?`,
    answer: `Don’t worry. We provide helmets to all our riders. If you have your child has a helmet please send it along`,
  },
  {
    question: `What kind of riding gear should I pack for my child?`,
    answer: `Ideally, riding breeches and boots with a fitting T-shirt (so that the instructor is able to easily gauge and correct your posture). For kids 11yrs and older, we recommend athletic supporters for boys and sports bras for girls. If you don’t have breeches and/or boots, high waist, close fitting, full length stretch trousers or jeans and closed leather shoes with a 1 inch heel would be fine. We also suggest long (higher than ankle) thick sports socks to avoid bruises from the stirrups.
NOTE: When you book a camp, a list of ‘Things to bring’ will be emailed to you. Please make sure you read and comply as it will make things more comfortable for the camper.`,
  },
];
const weekendProgramRidingFAQ = [
  {
    question: `I have never ridden before & I am nearing 50yrs; Can I learn riding if I attend the weekend riding program?`,
    answer: `We come across many grown-ups who since their childhood always dreamt that they had learnt riding but never found an opportunity or the time. Well…… It’s never too late!

You can always learn from a scratch. We ride in different batches as per the skill levels. Most of the adults venturing in this Riding Program are beginners. Our professional instructors, who communicate in at-least three languages including English, will make sure that you will rise to the trot in the correct technical manner by Sunday evening.

If you are just checking-off your bucket list than beware………Riding can be addictive!!`,
  },
  {
    question: `Me and my daughter are keen to ride but my wife just wants to be with us; can she accompany us?`,
    answer: `As the name ‘Adult/ Family Weekend Riding Program’ suggests, all are welcome. Riders will pay for the full package and non-riders will pay only for the lodging and food. You can have one of the best family time together and enjoy the relaxed and casual atmosphere at Japalouppe within nature and many more animals apart from the horses around you.`,
  },
  {
    question: `What about food and stay?`,
    answer: `The training program is always on a weekend so all the riders can get back to work on Monday.

Check -In: Friday Night after Dinner – 9 pm so that you can relax and are up and early next day 8 am to ride. If you prefer, you can arrive early morning on Saturday.

Check-Out: Sunday Evening 6 pm`,
  },
  {
    question: `What about food and accommodation?`,
    answer: `All the meals from breakfast to dinner are included in the program. We make sure that all our food is nutritious and tasty.

Saturday Night is the Barbecue Dinner Night. Riders can get their own beverages and enjoy the barbecue dinner with music, family and new friends.

Accommodations will be in our well ventilated and comfortable dorms.`,
  },
  {
    question: `How is the training program conducted?`,
    answer: `Rohan More the Director/Owner, who with his 20 years of experience will head the program with the instructors.

All riders in two days will do at-least 10 to 11 rides.

After every ride there will be different learning activities to make your learning curve smaller.

<ul>
<li>Discussions and video presentation with Rohan More</li>
<li>Feedback sessions from Instructors</li>
<li>Video of Riders: Corrections Position & Posture</li>
<li>Power Point Presentations</li>
<li>Demo on tacking up (Saddling) the horses</li>
</ul>
There will be 3 rides each on Saturday morning, evening, Sunday morning & 2 rides Sunday evening.`,
  },
  {
    question: `How do I book & what are the fees?`,
    answer: `Japalouppe usually decides the date of the ‘Adult/Family Riding Program’ 1 or 2 months before. Please contact or WhatsApp Melissa Iyer, the camp coordinator for future dates and the fees structure on 9158004104.`,
  },
];
export default function Faq() {
  const [collapseIndex, setCollapseIndex] = useState(null);

  const toggle = (index) => {
    setCollapseIndex(collapseIndex === index ? null : index);
  };

  return (
    <>
    <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "400px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/rrp-bg.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        {/* Mask */}
        <span className="mask opacity-4 bg-gradient-default-1" />
        {/* Header container */}
        <div className="text-center container">
          <Row className="text-center">
            <Col lg="12" md="12">
              <h1
                className="text-white about__h1"
                style={{ alignItems: "center" }}
              >
                frequently asked questions
              </h1>
            </Col>
          </Row>
        </div>
      </div>
    <Container className="mt-5">
      {/* <div className="text-center mt-5">
        <h1 className="mb-4 about__h2">Horse Riding Lessons</h1>
      </div>
      {horseRidingLessonsFAQ.map((faq, index) => (
        <Card key={index} className="mb-2">
          <CardHeader
            onClick={() => toggle(index)}
            className="d-flex justify-content-between align-items-center"
          >
            {faq.question}
            <Button color="primary" onClick={() => toggle(index)}>
              {collapseIndex === index ? "-" : "+"}
            </Button>
          </CardHeader>
          <Collapse isOpen={collapseIndex === index}>
            <CardBody dangerouslySetInnerHTML={{ __html: faq.answer }} />
          </Collapse>
        </Card>
      ))} */}
      <div className="text-center mt-5">
        <h1 className="mb-4 about__h2">Residential Children's Camp</h1>
      </div>
      {ResidentialChildrenCampsFAQ.map((faq, index) => (
        <Card key={index} className="mb-2">
          <CardHeader
            onClick={() => toggle(index)}
            className="d-flex justify-content-between align-items-center"
          >
            {faq.question}
            <Button color="primary" onClick={() => toggle(index)}>
              {collapseIndex === index ? "-" : "+"}
            </Button>
          </CardHeader>
          <Collapse isOpen={collapseIndex === index}>
            <CardBody dangerouslySetInnerHTML={{ __html: faq.answer }} />
          </Collapse>
        </Card>
      ))}
      <div className="text-center mt-5">
        <h1 className="mb-4 about__h2">Adult/Family Weekend Riding Program</h1>
      </div>
      {weekendProgramRidingFAQ.map((faq, index) => (
        <Card key={index} className="mb-2">
          <CardHeader
            onClick={() => toggle(index)}
            className="d-flex justify-content-between align-items-center"
          >
            {faq.question}
            <Button color="primary" onClick={() => toggle(index)}>
              {collapseIndex === index ? "-" : "+"}
            </Button>
          </CardHeader>
          <Collapse isOpen={collapseIndex === index}>
            <CardBody dangerouslySetInnerHTML={{ __html: faq.answer }} />
          </Collapse>
        </Card>
      ))}
    </Container>
    </>
  );
}
