import React, { useState,useEffect } from 'react';
import Login from './Login';
import Register from './Register';

function Tabs() {
    const [activeTab, setActiveTab] = useState('login');

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        // Extract hash from URL
        const hash = window.location.hash.substring(1);
        if (hash === 'japalouppe-register') {
          // Set active tab to 'register' if hash matches
          setActiveTab('register');
        }
        if (hash === 'japalouppe-login') {
            // Set active tab to 'register' if hash matches
            setActiveTab('login');
          }
      }, []);

    return (
        <div className='container ' style={{'zIndex':'2','marginTop':'-60px'}}>

        {/* Tabs navs */}
        <ul className="nav nav-tabs nav-fill mb-3 " id="ex1" role="tablist" aria-orientation="vertical">
            <li className="nav-item" role="presentation">
            <a
                className={`nav-link ${activeTab === 'login' ? 'active' : ''}`}
                id="ex2-tab-1"
                href="#japalouppe-login"
                role="tab"
                aria-controls="japalouppe-login"
                aria-selected={activeTab === 'login'}
                onClick={() => handleTabChange('login')}
            >
                <h3>Already A Camper? Login</h3>
            </a>
            </li>
            <li className="nav-item" role="presentation">
            <a
                className={`nav-link ${activeTab === 'register' ? 'active' : ''}`}
                id="ex2-tab-2"
                href="#japalouppe-register"
                role="tab"
                aria-controls="japalouppe-register"
                aria-selected={activeTab === 'register'}
                onClick={() => handleTabChange('register')}
            >
                <h3>New Camper? Register</h3>
            </a>
            </li>
        </ul>
        {/* Tabs navs */}

        {/* Tabs content */}
        <div className="tab-content" id="ex2-content">
            <div
            className={`tab-pane fade ${activeTab === 'login' ? 'show active' : ''}`}
            id="ex2-tabs-1"
            role="tabpanel"
            aria-labelledby="ex2-tab-1"
            >
            <Login/>
            </div>
            <div
            className={`tab-pane fade ${activeTab === 'register' ? 'show active' : ''}`}
            id="japalouppe-register"
            style={{'textAlign':'-webkit-right'}}
            role="tabpanel"
            aria-labelledby="ex2-tab-2"
            >
            <Register/>
            </div>
        </div>
        {/* Tabs content */}
        </div>
    );
}

export default Tabs;
