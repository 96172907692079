import React from 'react';
import './MemberAvatars.css';
import kid3 from "../../../assets/img/theme/avatar.jpg";
// import kid1 from "../../../assets/img/theme/kid-1.jpg";
// import kid2 from "../../../assets/img/theme/kid-2.jpg";

export default function MemberAvatars({ members, handleSelectedMemberId, selectedId }) {
  const handleError = (event) => {
    event.target.src = kid3;
  };
  return (
    <div className='container'>
      <div className="row justify-content-center" >
        {members.map((each,index) => (
          <div className="col-sm-3 text-center" key={each.member_id} onClick={() => handleSelectedMemberId(each.member_id)}>
            <img
              className={each.member_id === selectedId ? "profile-avatar active-avatar" : "profile-avatar"}
              src={`${process.env.REACT_APP_DJANGO_SERVER_URL}/api${each.photo_url}`}
              // src={`${process.env.REACT_APP_DJANGO_SERVER_URL}/api/media/media/member_avatar.png`}
              onError={handleError}
              alt=""
            />
            {each.member_id !== selectedId && <h4 onClick={() => handleSelectedMemberId(each.member_id)} className="mt--2 strong ">{each.first_name} ( {each.age} y/o )</h4>}
            {each.member_id === selectedId && <h2 onClick={() => handleSelectedMemberId(each.member_id)} className="mt--2 strong ">{each.first_name} ( {each.age} y/o )</h2>}
          </div>
        ))}
      </div>
    </div>
  );
}
