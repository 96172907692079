// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-footer {
    margin-top: 3%;
    margin-left: 0.1%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: #09173A;
    padding: 05px 0;
    color: white; /* Set text color to white or any color you prefer */
    text-align: center;
  }

  .sticky-card{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Parents/Cart.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,wBAAgB;IAAhB,gBAAgB;IAChB,SAAS;IACT,yBAAyB;IACzB,eAAe;IACf,YAAY,EAAE,oDAAoD;IAClE,kBAAkB;EACpB;;EAEA;IACE,wBAAgB;IAAhB,gBAAgB;IAChB,MAAM;EACR","sourcesContent":[".sticky-footer {\n    margin-top: 3%;\n    margin-left: 0.1%;\n    position: sticky;\n    bottom: 0;\n    background-color: #09173A;\n    padding: 05px 0;\n    color: white; /* Set text color to white or any color you prefer */\n    text-align: center;\n  }\n\n  .sticky-card{\n    position: sticky;\n    top: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
