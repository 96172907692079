// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-avatar{
    -webkit-clip-path: circle(30%);
            clip-path: circle(30%);
    width: 150px;
    cursor: pointer;
    margin-top: 20px;
    height: 200px;
    object-fit: contain;
    background-position: center center;
}
.active-avatar{
    width: 250px;
    border: 1px solid rgb(0, 0, 0) !important;
    -webkit-clip-path: circle(30%);
            clip-path: circle(30%);
    cursor: pointer;
    margin-top: -20px;
    height: 230px;
    object-fit: cover;
    background-position: center center;
}
.avatar-container{
    margin-top:-90px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Parents/components/MemberAvatars.css"],"names":[],"mappings":"AAAA;IACI,8BAAsB;YAAtB,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,kCAAkC;AACtC;AACA;IACI,YAAY;IACZ,yCAAyC;IACzC,8BAAsB;YAAtB,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,iBAAiB;IACjB,kCAAkC;AACtC;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".profile-avatar{\n    clip-path: circle(30%);\n    width: 150px;\n    cursor: pointer;\n    margin-top: 20px;\n    height: 200px;\n    object-fit: contain;\n    background-position: center center;\n}\n.active-avatar{\n    width: 250px;\n    border: 1px solid rgb(0, 0, 0) !important;\n    clip-path: circle(30%);\n    cursor: pointer;\n    margin-top: -20px;\n    height: 230px;\n    object-fit: cover;\n    background-position: center center;\n}\n.avatar-container{\n    margin-top:-90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
