// reactstrap components
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    UncontrolledTooltip
    } from "reactstrap";
    // core components
    import { Link, useParams } from "react-router-dom";
    import { useFetch } from "hooks/useFetch";
    import Loader from "components/Loader";
    import { NotificationContainer, NotificationManager } from 'react-notifications';
    import localImage from "../../assets/img/theme/counter-bg.jpg"
    import { usePost } from "hooks/usePost";
    const EditRider = () => {
    const {riderId}=useParams();
    const {data:memberData,isPending}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getMember/"+riderId);
    const {postData, resData, postError, isPending:updatePending}=usePost();
    const [newImage, setNewImage] = useState(null);

    const navigate = useNavigate();
    const [isEdit,setIsEdit]=useState(false);

    const [formDataa, setFormDataa] = useState({
        firstName: null,
        lastName: null,
        schoolName: null,
        city: null,
        level: null,
        gender: null,
        allergies: null,
        specialInst: null,
        parent2Name: null,
        parent2Email: null,
        parent2Contact: null,
        emergencyContactName: null,
        emergencyContactNumber: null
    });

    const handleImageClick = (e) => {
        e.preventDefault();
        document.getElementById('fileInput').click();
      };


    useEffect(() => {
        if(memberData && memberData.status===200){
            setFormDataa(
                {
                    firstName: memberData.memberDetails.first_name,
                    lastName: memberData.memberDetails.last_name,
                    schoolName: memberData.memberDetails.school_name,
                    city: memberData.memberDetails.city,
                    gender: memberData.memberDetails.gender,
                    level: memberData.memberDetails.current_level_id,
                    allergies: memberData.memberDetails.allergies,
                    specialInst: memberData.memberDetails.special_instructions,
                    parent2Name: memberData.memberDetails.parent2_name,
                    parent2Email: memberData.memberDetails.parent2_email,
                    parent2Contact: memberData.memberDetails.parent2_contact,
                    emergencyContactName: memberData.memberDetails.emergency_contact_name,
                    emergencyContactNumber: memberData.memberDetails.emergency_contact_number
                }
            )
        }
    }, [memberData]);

    const handleUpdateRider = async (e) =>{
        e.preventDefault();
        if(formDataa.firstName===""){
            NotificationManager.error('Please update first name / dont leave empty', '', 1500);
        }
        if(formDataa.lastName===""){
            NotificationManager.error('Please update last name / dont leave empty', '', 1500);
        }
        if(formDataa.schoolName===""){
            NotificationManager.error('Please update first name / dont leave empty', '', 1500);
        }
        if(formDataa.city===""){
            NotificationManager.error('Please update first name / dont leave empty', '', 1500);
        }
        if(formDataa.gender===""){
            NotificationManager.error('Please update gender / dont leave empty', '', 1500);
        }
        const formData = new FormData();
        formData.append("first_name", formDataa.firstName);
        formData.append("last_name", formDataa.lastName);
        formData.append("school_name", formDataa.schoolName);
        formData.append("city", formDataa.city);
        formData.append("level", formDataa.level);
        formData.append("member_id", memberData.memberDetails.member_id);
        formData.append("parent", memberData.memberDetails.parent);

        formData.append("gender", formDataa.gender);
        formData.append("dob", formDataa.dob);
        formData.append("meal_specifications", memberData.memberDetails.meal_specifications);
        formData.append("allergies",formDataa.allergies);
        formData.append("special_instructions",formDataa.specialInst);
        formData.append("parent2_contact",formDataa.parent2Contact);
        formData.append("parent2_email",formDataa.parent2Email);
        formData.append("parent2_name",formDataa.parent2Name);
        formData.append("emergency_contact_name",formDataa.emergencyContactName);
        formData.append("emergency_contact_number",formDataa.emergencyContactNumber);
        if (newImage) {
            if( ['image/jpeg','image/jpg', 'image/png', 'image/webp'].includes(newImage.type))
            {
                formData.append('photo_url', newImage);
            }else{
                NotificationManager.error('Please upload a JPEG, PNG, or WebP image.', '', 3000);
                return;
            }
        }
        formData.append("relation", memberData.memberDetails.relation);
        

        try {
            const res = await fetch(`${process.env.REACT_APP_DJANGO_SERVER_URL}/api/updateMember/${memberData.memberDetails.member_id}`, {
            method: "PUT",
            body: formData,
            });
        
            if (!res.ok) {
            throw new Error('Unable To Fetch the response!')
            }
        
            const data = await res.json();
            if (data.status !== 200) {
            if (data.Error) {
                throw new Error(data.Error);
            } else {
                throw new Error("Oops! something went wrong.")
            }
            }
        
            console.log(data, ' here is the data');
            NotificationManager.success('Member Updated Successfully!', '', 3000);
            setInterval(() => window.location.href = '/admin/view-all-students', 1111);     
        }
        catch (err) {
            NotificationManager.error(err.message, '', 3000);
            return;
        }

    }

    const genders = [
        { id: 1, name: 'male' },
        { id: 2, name: 'female' }
    ];

    return (
    <>
    {isPending && 
    <Loader/>
    }
    {memberData && 
    <div>
        <NotificationContainer/>
        <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
        minHeight: "400px",
        backgroundImage:
        "url(" + require("../../assets/img/theme/profile-cover.jpg") + ")",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        }}
        >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
            <Row>
            <Col lg="12" md="10">
            <h1 className="display-2 text-white">{memberData.memberDetails.first_name} {memberData.memberDetails.last_name}</h1>
            {!isEdit && <Button
                color="info"
                href="#pablo"
                onClick={() => setIsEdit(true)}
            >
            Edit profile
            </Button>}
            {isEdit && <Button
                color="info"
                href="#pablo"
                onClick={() => setIsEdit(true)}
                disabled = {isEdit}
            >
            Edit profile
            </Button>}
            </Col>
            </Row>
        </Container>
    </div>
    {/* Page content */}
    <Container className="mt--7" fluid>
        <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
            <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                <div className="card-profile-image">
                    <a href="#pablo"  >
                        <img
                        alt="profile-img"
                        className="profile rounded-circle"
                        style={{width:"110px !important",'height':'110px !important'}}
                        src={process.env.REACT_APP_DJANGO_SERVER_URL+"/api"+memberData.memberDetails.photo_url}
                        />
                    </a>
                </div>
                </Col>
            </Row>
            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                    {/* <Button
                        className="mr-4"
                        color="info"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                    size="sm"
                    >
                    Connect
                    </Button>
                    <Button
                        className="float-right"
                        color="default"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                    size="sm"
                    >
                    Message
                    </Button> */}
                </div>
            </CardHeader>
            <CardBody className="pt-0 pt-md-4">
                <div className="text-center mt-5">
                    <h3>
                        {memberData.memberDetails.first_name}  {memberData.memberDetails.last_name}
                        <span className="font-weight-light">, {memberData.memberDetails.age}</span>
                    </h3>
                    <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {memberData.memberDetails.city}
                    </div>
                    <div className="h3 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        {memberData.memberDetails.school_name}
                    </div>
                    <hr className="my-4" />
                    <h4>
                        Allergies: {(memberData.memberDetails.allergies!=="" || memberData.memberDetails.allergies!==null)?memberData.memberDetails.allergies:'N/A'}
                    </h4>
                    {/* <h4>
                        Meal Specifications: {memberData.memberDetails.meal_specifications}
                    </h4>
                    <h4>
                        Special Instructions: {memberData.memberDetails.special_instructions}
                    </h4> */}
                    <hr />
                    {/* <h4>Other Related Members</h4>
                    <div className="avatar-group">
                        {memberData && memberData.siblingMemberInfo && memberData.siblingMemberInfo.map((each)=>( <React.Fragment key={each.member_id}>
                        <a
                        className="avatar avatar-lg"
                        href={`/admin/rider-details/${each.member_id}`}
                        id={`tooltip${each.member_id}`}
                        >
                        <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../assets/img/theme/team-2-800x800.jpg")}
                        />
                        </a>
                        <UncontrolledTooltip
                        delay={0}
                        target={`tooltip${each.member_id}`}
                        >
                        {each.first_name}  {each.last_name}
                        </UncontrolledTooltip>
                        </React.Fragment>))}
                    </div> */}
                </div>
            </CardBody>
            </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="8">
                    <h3 className="mb-0">Rider Profile</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                    {/* <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                    size="sm"
                    >
                    Settings
                    </Button> */}
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Form onSubmit={handleUpdateRider} encType="multipart/form-data">
                    <h6 className="heading-small text-muted mb-4">
                        Member information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                        >
                                    First name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        value={formDataa.firstName}
                                        onChange={(e)=> setFormDataa({...formDataa,firstName:e.target.value})}
                                        id="input-first-name"
                                        placeholder="First name"
                                        type="text"
                                        readOnly={!isEdit}
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-last-name"
                                        >
                                    Last name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        value={formDataa.lastName}
                                        onChange={(e)=> setFormDataa({...formDataa,lastName:e.target.value})}
                                        readOnly={!isEdit}
                                        id="input-last-name"
                                        placeholder="Last name"
                                        type="text"
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="gender"
                                        >
                                    Gender
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={memberData.memberDetails.gender}
                                        onChange={(e)=> setFormDataa({...formDataa,gender:e.target.value})}
                                        readOnly={!isEdit}
                                        id="gender"
                                        type="select"
                                        name = "selectGender"
                                        >
                                        <option value="">Select an gender option </option>
                                        {  genders && 
                                            genders.map((genders)=>
                                            <option key={genders.id} value={genders.name}>{genders.name}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-school-name"
                                        >
                                    School Name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}
                                        value={formDataa.schoolName}
                                        onChange={(e)=> setFormDataa({...formDataa,schoolName:e.target.value})}                                        id="input-school-name"
                                        type="text"
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="city"
                                        >
                                    City
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        
                                        value={formDataa.city}
                                        onChange={(e)=> setFormDataa({...formDataa,city:e.target.value})}    
                                        readOnly={!isEdit}
                                        id="city"
                                        type="text"
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Level"
                                        >
                                    Level
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={memberData.memberDetails.current_level_name}
                                        readOnly
                                        id="current_level"
                                        type="text"
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                {memberData.memberDetails.aadhar_no!==''&&<FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="aadhar-number"
                                        >
                                    Aadhar number
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={memberData.memberDetails.aadhar_no!==''?memberData.memberDetails.aadhar_no:'N/A'}
                                        readOnly
                                        id="aadhar-number"
                                        type="text"
                                        />
                                </FormGroup>}
                                {memberData.memberDetails.passport_no!=='' && <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="passport-number"
                                        >
                                    Passport number
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={memberData.memberDetails.passport_no!==''?memberData.memberDetails.passport_no:'N/A'}
                                        readOnly
                                        id="passport-number"
                                        type="text"
                                        />
                                </FormGroup>}
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Level"
                                        >
                                    DOB
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={memberData.memberDetails.dob}
                                        value={formDataa.dob}
                                        onChange={(e)=> setFormDataa({...formDataa,dob:e.target.value})}   
                                        readOnly={!isEdit}
                                        id="dob"
                                        type="date"
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="allergies"
                                        >
                                    Allergies
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}

                                        value={formDataa.allergies}
                                        onChange={(e)=> setFormDataa({...formDataa,allergies:e.target.value})}                                            id="allergies"
                                        type="text"
                                        
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="special-instruction"
                                        >
                                    Special Instruction
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}

                                        value={formDataa.specialInst}
                                        onChange={(e)=> setFormDataa({...formDataa,specialInst:e.target.value})}                                            id="special-instruction"
                                        type="text"
                                        
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="allergies"
                                        >
                                    Change Profile
                                    </label>
                                    <Input
                                        id="fileInput"
                                        type="file"
                                        readOnly={!isEdit}
                                        onChange={(e)=>setNewImage(e.target.files[0])}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="my-4" />
                    {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                            Contact information
                        </h6>
                        <Row>
                        <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="parent-2-name"
                                        >
                                    Parent 2 Name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}

                                        value={formDataa.parent2Name}
                                        onChange={(e)=> setFormDataa({...formDataa,parent2Name:e.target.value})}                                            id="parent-2-name"
                                        type="text"
                                        
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="parent-2-email"
                                        >
                                    Parent 2 Email
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}
                                        value={formDataa.parent2Email}
                                        onChange={(e)=> setFormDataa({...formDataa,parent2Email:e.target.value})}  
                                        id="parent-2-email"
                                        type="text"
                                        
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="parent-2-contact"
                                        >
                                    Parent 2 Contact
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}
                                        value={formDataa.parent2Contact}
                                        onChange={(e)=> setFormDataa({...formDataa,parent2Contact:e.target.value})}                                          id="parent-2-contact"
                                        type="text"
                                        
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="emergency-name"
                                        >
                                    Emergency Contact Name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}
                                        value={formDataa.emergencyContactName}
                                        onChange={(e)=> setFormDataa({...formDataa,emergencyContactName:e.target.value})}                                          id="emergency-name"
                                        type="text"
                                        
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="emergency-number"
                                        >
                                    Emergency Contact Number
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        readOnly={!isEdit}
                                        value={formDataa.emergencyContactNumber}
                                        onChange={(e)=> setFormDataa({...formDataa,emergencyContactNumber:e.target.value})}                                          id="emergency-number"
                                        type="text"
                                        
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    {/* <h6 className="heading-small text-muted mb-4">
                        Contact information
                    </h6> */}
                    {/* <div className="pl-lg-4">
                        <Row>
                        <Col md="6">
                        {memberData.parentInfo &&
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="parent-first-name"
                                >
                            Parent first name
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={memberData.parentInfo.first_name}
                                readOnly
                                id="parent-first-name"
                                placeholder="Home Address"
                                type="text"
                                />
                        </FormGroup>
                        }
                        </Col>
                        <Col md="6">
                        {memberData.parentInfo &&
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="parent-last-name"
                                >
                            Parent last name
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={memberData.parentInfo.last_name}
                                readOnly
                                id="parent-last-name"
                                placeholder="Home Address"
                                type="text"
                                />
                        </FormGroup>
                        }
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-city"
                                >
                            City
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={memberData.memberDetails.city}
                                readOnly
                                id="input-city"
                                placeholder="City"
                                type="text"
                                />
                        </FormGroup>
                        </Col>
                        <Col lg="4">
                        {memberData.parentInfo && 
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="email"
                                >
                            Email
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={memberData.parentInfo.email}
                                readOnly
                                id="input-email"
                                placeholder="Email"
                                type="email"
                                />
                        </FormGroup>
                        }
                        </Col>
                        <Col lg="4">
                        {memberData.parentInfo &&
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-phone"
                                >
                            Phone Number
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={memberData.parentInfo.phone_number}
                                readOnly
                                id="input-phone"
                                placeholder="Phone Number"
                                type="text"
                                />
                        </FormGroup>
                        }
                        </Col>
                        </Row>
                    </div> */}
                    <Button disabled={!isEdit} type="Submit" color="primary">
                        Save 
                    </Button>
                </Form>
            </CardBody>
            </Card>
            </Col>
        </Row>
        {/* <div>
            <h3 className="mt-5">Course History</h3>
        </div> */}
        <div className="row mt-4">
            {/* {memberData.orders.map((each) => ( */}
            {/* <div className="col-sm-4" key={each.course_id}>
            <div className="card" style={{ "width": "18rem" }}>
            <img src={localImage} className="card-img-top w-100" style={{ "height": "180px", "objectFit": "cover" }} alt="..." />
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-8">
                        <h3 className="card-text">{each.course_name}</h3>
                    </div>
                    <div className="col-sm-4">
                        <h3 className="card-text">₹ 5000</h3>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-sm-6">
                        <div>
                        <p> <small>Order Date</small></p>
                        <p className='mt--3'><i className='ni ni-calendar-grid-58'></i><span style={{fontWeight:"500",fontSize:"15px"}}>&nbsp;&nbsp;&nbsp;{new Date(each.created_at).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}</span></p>
                        </div>
                    </div>
                </div>
                <Link to={`/admin/course-detail/${each.course_id}`} className="d-flex justify-content-center">
                <Button color="primary" className="w-100 primary text-white">View Details</Button>
                </Link>
            </div>
            </div>
        </div> */}
        {/* ))} */}
        </div>
    </Container>
    </div>}
    </>
    );
    };
    export default EditRider;