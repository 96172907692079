import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CustomInput,
  Table,
  CardFooter,
  Label,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import OrderTable from './OrderTable';
import WaitlistTable from './WaitlistTable';

export default function CourseDetail() {
    const { courseId } = useParams();
    const { data: course } = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getCourse/" + courseId);
    const { postData: archiveCourse,resData:archiveRes,postError } = usePost();

    const {data:levels}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getMasterLevelView/");
    const [isEditing,setIsEditing]=useState(false);
    const [rows, setRows] = useState([]);
    const [newRow, setNewRow] = useState({ level_name: '', price: 0 , boys_seats: 0 , girls_seats: 0 });
    const [isTableEditing,setIsTableEditing]=useState(false);
    const [courseDetails,setCourseDetails]=useState(null);
    const {postData:updateCourse,resData:courseUpdatedResponse}=usePost();
    const [courseSeatTable,setCourseSeatTable]=useState(null);
    const [courseArchived, setCourseArchived] = useState(null);
    const {postData,resData}=usePost();
    const [girlsTotal,setGirlsTotal]=useState(0);
    const [boysTotal,setBoysTotal]=useState(0);
    const [newRowBoysTotal,setNewRowBoysTotal]=useState(0);
    const [newRowGirlsTotal,setNewRowGirlsTotal]=useState(0);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [isTable,setIsTable]=useState('orders')

    useEffect(()=>{
        if(course){
            setCourseDetails(course.course_details);
            setCourseSeatTable(course.course_level_seats);
        }

    },[course])
    useEffect(()=>{ 
        if(course){
            setCourseArchived(course.course_details.archive)
            // console.log(' hiehhie ',courseArchived)
        course.course_level_seats.forEach((each)=>{
            console.log(each)
            setBoysTotal(prev=>prev+Number(each.boys_seats))
            setGirlsTotal(prev=>prev+Number(each.girls_seats))
        })
        }

    },[course])

    const addNewRow = () => {
        if(boysTotal+newRowBoysTotal=== courseDetails.boys_capacity && girlsTotal+newRowGirlsTotal===courseDetails.girls_capacity ){
            NotificationManager.error('Allocated seats are already equal to the maximum capacity', '', 3000);
            return;
        }
        setRows([...rows, newRow]);
        setNewRow({ level_name: '', price: 0 , boys_seats: 0 , girls_seats: 0});
    };

    const removeRow = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const handleEditing=()=>{
        setIsEditing(!isEditing);
    }

    const handleTableEditing=()=>{
        setIsTableEditing(!isTableEditing);
    }

    const saveCourseDetails=()=>{
        console.log(courseDetails)
        updateCourse(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/updateCourse/"+courseId,{...courseDetails,"list_order": 1,"archive": 0},"PUT") 
    }

    useEffect(()=>{
        if(courseUpdatedResponse && courseUpdatedResponse.status===200){
            NotificationManager.success('Course updated Successfully!', '', 3000);
            setIsEditing(!isEditing);
        }
    },[courseUpdatedResponse])

    const handleInputChange = (index, fieldName, value) => {
        // Make a copy of the courseSeatTable array to avoid mutating state directly
        const updatedCourseSeatTable = [...courseSeatTable];
      
        // Update the value in the copied array
        updatedCourseSeatTable[index][fieldName] = value;
      
        // Update the state with the new array
       
        let localBoysSeats=0
        let localGirlsSeats=0;
        updatedCourseSeatTable.forEach((each)=>{
            localBoysSeats=localBoysSeats+Number(each.boys_seats);
            localGirlsSeats=localGirlsSeats+Number(each.girls_seats);
        }) 
        if(localBoysSeats+newRowBoysTotal>courseDetails.boys_capacity){
            NotificationManager.error('Boys Maximum Capacity Exceeded', '', 3000);
            return;
        }else if(localGirlsSeats+newRowGirlsTotal>courseDetails.girls_capacity){
            NotificationManager.error('Girls Maximum Capacity Exceeded', '', 3000);
            return;
        }
        console.log(updatedCourseSeatTable)
        setBoysTotal(localBoysSeats);
        setGirlsTotal(localGirlsSeats);
        setCourseSeatTable(updatedCourseSeatTable);   
      };

    const saveSeatTableData=(e)=>{
    e.preventDefault();
    console.log(courseSeatTable);
    if(boysTotal+newRowBoysTotal<courseDetails.boys_capacity){
        NotificationManager.error('Boys Seats are less than its Maximum Capacity', '', 3000);
            return;
    }
    else if(girlsTotal+newRowGirlsTotal<courseDetails.girls_capacity){
        NotificationManager.error('Girls Seats are less than its Maximum Capacity', '', 3000);
            return;
    }
    let arr=courseSeatTable.map((each)=>{
        return{
            id:each.id,
            boys_seats:each.boys_seats,
            girls_seats:each.girls_seats,
            level:each.level,
            price:each.price,
            course:each.course
        }
    })
    let additionSeatsArr=rows.map((each)=>{
        return{
            boys_seats:Number(each.boys_seats),
            girls_seats:Number(each.girls_seats),
            level:Number(each.level_name),
            price:Number(each.price),
            course:Number(courseId)
        }
    })
    let mergedArr=[...arr,...additionSeatsArr];

    postData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/updateCourseLevelSeats/"+courseId,mergedArr,"PUT")
    }
    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success('Table updated Successfully!', '', 3000);
            setIsTableEditing(false);
            window.location.reload();
        }
    },[resData])

    const handleCourseSeatTable=(e,currentRow,index, field)=>{
        // Clone the rows array to avoid mutating the state directly
        const updatedRows = [...rows];
        console.log(field)
        // Check if the field is 'level_id' (for the <select> element)
        if (field === 'level_id') {
            updatedRows[index] = {
                ...currentRow,
                level_name: e.target.value,
            };
        } else {
            // Update the currentRow object based on the input field's name
            updatedRows[index] = {
                ...currentRow,
                [e.target.name]: e.target.value,
            };
        }
        let localBoysSeats=0;
        let localGirlsSeats=0;
        // Update the state with the new rows array
        console.log(updatedRows)
        updatedRows.forEach((each)=>{
            localBoysSeats=localBoysSeats+Number(each.boys_seats);
            localGirlsSeats=localGirlsSeats+Number(each.girls_seats);
        })
        if(localBoysSeats+boysTotal>courseDetails.boys_capacity){
            NotificationManager.error('Boys Maximum Capacity Exceeded', '', 3000);
            return;
        }
        if(localGirlsSeats+girlsTotal>courseDetails.girls_capacity){
            NotificationManager.error('Girls Maximum Capacity Exceeded', '', 3000);
            return;
        }
        setNewRowGirlsTotal(localGirlsSeats);
        setNewRowBoysTotal(localBoysSeats);
        setRows(updatedRows);
    }
    const handlePopUPOpen=(id)=>{
        setItemToDelete(id);
        setIsDeleteModalOpen(true);
    }
    const deleteSeat=async()=>{
      
        try {
            const response = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/deleteCourseLevelSeat/'+itemToDelete, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json', 
              },
            });
      
            if (response.ok) {
                NotificationManager.success('Deleted Successfully!', '', 3000);
                setIsDeleteModalOpen(false);
            } else {
              const errorData = await response.json();
              NotificationManager.error('Unable to delete'+errorData, '', 3000);
            }
          } catch (error) {
            console.error('Error:', error);
            console.log('An error occurred while making the request.');
            NotificationManager.error('Unable to delete'+error, '', 3000);
          }
          let localCourseState=courseSeatTable.filter((each)=>{
            return each.id !==itemToDelete;
        })
        let localBoysTotal=0;
        let localGirlsTotal=0;
        localCourseState.forEach((each)=>{
            localBoysTotal=localBoysTotal+each.boys_seats;
            localGirlsTotal=localGirlsTotal+each.girls_seats;
        })
        setBoysTotal(localBoysTotal);
        setGirlsTotal(localGirlsTotal);
        setCourseSeatTable(courseSeatTable.filter((each)=>{
            return each.id !==itemToDelete;
        }));
    }

    const handleArchiveCamp = (e,course_id) =>{
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this course?")) {
            archiveCourse(process.env.REACT_APP_DJANGO_SERVER_URL + "/api/deleteCourse/" + course_id, {}, "DELETE");
        }

    }

    useEffect(()=>{
        if(archiveRes && archiveRes.status===200 ){
            NotificationManager.success('Course deleted Successfully!', '', 3000);
            window.location.href = '/admin/view-all-courses';
            setCourseArchived(true);
        }
        if(postError){
            NotificationManager.error('Could not delete course!', '', 3000);
            setCourseArchived(false);
        }
    },[archiveRes])
return (
<>
    <NotificationContainer/>
    {/* Modal */}
    <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
        <ModalHeader toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>Confirm Delete</ModalHeader>
        <ModalBody>
            Are you sure you want to delete this item?
        </ModalBody>
        <ModalFooter>
            <Button color="#D10000" style={{'background':'#D10000', 'color':'#fff'}} onClick={deleteSeat}>Yes</Button>
            <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>No</Button>
        </ModalFooter>
    </Modal>
    {course && courseDetails && (
    <div>
    <div
    className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    style={{
    minHeight: "450px",
    backgroundImage:
    "url(" + require("../assets/img/theme/counter-bg.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    }}
    >
    {/* Mask */}
    <span className="mask  opacity-8" />
    {/* Header container */}
    <Container className="align-items-center flex-columns" fluid>
        <Row>
            <Col sm='12'>
                <div className='text-end'>
                    { archiveCourse && <Button className='text-end btn-danger btn-sm' onClick={(e)=>handleArchiveCamp(e,courseDetails.course_id)}>Delete Camp</Button>}
                    { !archiveCourse && <Button className='text-end btn-danger btn-sm' >Camp Deleted</Button>}

                </div>
            </Col>
        </Row>
        <Row>
            <Col lg="12" md="10">
            <h1 className="display-2 text-white">{courseDetails.course_name}</h1>
            <p className="text-white mt-0 mb-5">
                {courseDetails.description}
            </p>
            </Col>
        </Row>
    </Container>
    </div>
    {/* Page content */}
    <Container className="mt--7" fluid>
    <Row>
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="5">
        <Card className="shadow">
            <CardHeader className="border-0">
                <Row className="align-items-center">
                <div className="col">
                    <h3 className="mb-0">
                        {new Date(courseDetails.course_from_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} 
                        &nbsp; to  &nbsp;
                        {new Date(courseDetails.course_to_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} 
                    </h3>
                </div>
                <div className="col text-right">
                    {!isTableEditing && <Button
                        color="primary"
                        onClick={handleTableEditing}
                        size="sm"
                        >
                    Edit
                    </Button>}
                    {isTableEditing && <Button
                        color="primary"
                        onClick={saveSeatTableData}
                        size="sm"
                        >
                    Save
                    </Button>}
                </div>
                </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                    <th />
                    <th scope="col">Boys Seat</th>
                    <th scope="col">Girls Seat</th>
                    <th scope="col">Price &nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {courseSeatTable &&
                courseSeatTable.map((each, index) => (
                    <tr key={index}>
                    <th scope="row">{each.level_name}</th>
                    <td>
                        <Input
                            name="boys_seats"
                            type='number'
                            className="form-control-alternative"
                            defaultValue={each.boys_seats}
                            readOnly={!isTableEditing ? true : false}
                            onChange={(e) => handleInputChange(index, 'boys_seats', e.target.value)}
                        />
                    </td>
                    <td>
                        <Input
                            name="girls_seats"
                            className="form-control-alternative"
                            type='number'
                            defaultValue={each.girls_seats}
                            readOnly={!isTableEditing ? true : false}
                            onChange={(e) => handleInputChange(index, 'girls_seats', e.target.value)}
                        />
                    </td>
                    <td>
                        <Input
                            name="price"
                            className="form-control-alternative"
                            type='number'
                            defaultValue={each.price}
                            readOnly={!isTableEditing ? true : false}
                            onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                        />
                    </td>
                    <td> 
                        {isTableEditing && <FontAwesomeIcon onClick={() => handlePopUPOpen(each.id)} 
                        style={{ 'cursor':'pointer','color':'#D10000'}}  icon={faTrash} />}
                    </td>
                    </tr>
                ))
                }
                {levels && rows.map((row, index) => (
                <tr key={index}>
                    <th scope="row">
                        <FormGroup>
                            <Label for="exampleSelect">Select Level</Label>
                            <Input 
                            className="form-control-alternative" 
                            type="select" 
                            name="select" 
                            id="exampleSelect"
                            value={row.level_id}
                            onChange={(e) => handleCourseSeatTable(e, row, index,'level_id')}
                            >
                            {levels.map((level)=>(
                            <option key={level.level_id} value={level.level_id}>{level.level_name}</option>
                            ))}
                            </Input>
                        </FormGroup>
                    </th>
                    <td>
                        <Input
                        className="form-control-alternative"
                        name="boys_seats"
                        value={row.boys_seats}
                        placeholder='Boys Seat'
                        type="number"
                        onChange={(e)=>handleCourseSeatTable(e, row, index,e.target.name)}
                        />
                    </td>
                    <td>
                        <Input
                        className="form-control-alternative"
                        name="girls_seats"
                        value={row.girls_seats}
                        placeholder='Girls Seat'
                        type="number"
                        onChange={(e)=>handleCourseSeatTable(e, row, index,e.target.name)}
                        />
                    </td>
                    <td>
                        <Input
                        className="form-control-alternative"
                        name="price"
                        placeholder='Price'
                        value={row.price}
                        type="price"
                        onChange={(e)=>handleCourseSeatTable(e, row, index,e.target.name)}
                        />
                    </td>
                    <td>
                        {/* <i onClick={() => removeRow(index)} 
                        style={{'fontSize':'30px', 'cursor':'pointer'}} 
                        className="ni ni-fat-remove text-danger"> 
                        </i> */}
                        <FontAwesomeIcon onClick={() => removeRow(index)} 
                        style={{ 'cursor':'pointer','color':'#D10000'}}  icon={faTrash} />
                    </td>
                </tr>
                ))}
                <tr>
                    <th>Total</th>
                    <td>{boysTotal+newRowBoysTotal}</td>
                    <td>{girlsTotal+newRowGirlsTotal}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th>Capacity</th>
                    <td>{courseDetails.boys_capacity}</td>
                    <td>{courseDetails.girls_capacity}</td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </Table>
            <CardFooter>
                <div className="text-right">
                {isTableEditing && <Button color="primary" size="sm" onClick={addNewRow}>
                Add Row
                </Button>}
                </div>
            </CardFooter>
        </Card>
        </Col>
        <Col className="order-xl-1" xl="7">
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                <Col xs="8">
                <h3 className="mb-0">Course Details</h3>
                </Col>
                <Col className="text-right" xs="4">
                {!isEditing && <Button
                    color="primary"
                    onClick={handleEditing}
                    size="sm"
                    >
                Edit
                </Button>}
                {isEditing && <Button
                    color="primary"
                    onClick={saveCourseDetails}
                    size="sm"
                    >
                Save
                </Button>}
                </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Form>
                <h6 className="heading-small text-muted mb-4">
                    User information
                </h6>
                <div className="pl-lg-4">
                    <Row>
                        <Col lg="6">
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="coursename"
                            >
                            Course Name
                            </label>
                            <Input
                            className="form-control-alternative"
                            value={courseDetails.course_name}
                            id="coursename"
                            placeholder="Course Name"
                            type="text"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,course_name:e.target.value})}
                            />
                        </FormGroup>
                        </Col>
                        <Col lg="6">
                        <FormGroup>
                            <label htmlFor="exampleCheckbox">Certification</label>
                            <div>
                            <CustomInput
                            type="radio"
                            id="yes"
                            name="certification"
                            label="Yes"
                            inline
                            value="1"
                            disabled={!isEditing}
                            checked={String(courseDetails.certification) === "1"}
                            onChange={(e) => {setCourseDetails({ ...courseDetails, certification: e.target.value })}}
                            />
                            <CustomInput
                            type="radio"
                            id="no"
                            name="certification"
                            label="No"
                            inline
                            value="0"
                            disabled={!isEditing}
                            checked={ String(courseDetails.certification) === "0"} 
                            onChange={(e) => {setCourseDetails({ ...courseDetails, certification: e.target.value })}}
                            />
                            </div>
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="course-start-date"
                            >
                            Course Start Date
                            </label>
                            <Input
                            className="form-control-alternative"
                            value={courseDetails.course_from_date}
                            id="course-start-date"
                            type="date"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,course_from_date:e.target.value})}
                            />
                        </FormGroup>
                        </Col>
                        <Col lg="6">
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="course-end-date"
                            >
                            Course Start Date
                            </label>
                            <Input
                            className="form-control-alternative"
                            value={courseDetails.course_to_date}
                            id="course-end-date"
                            type="date"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,course_to_date:e.target.value})}
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="pl-lg-4">
                    <Row>
                        <Col lg="6">
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="from-age"
                            >
                            From Age
                            </label>
                            <Input
                            className="form-control-alternative"
                            value={courseDetails.from_age}
                            id="from-age"
                            placeholder="Age"
                            type="text"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,from_age:e.target.value})}
                            />
                        </FormGroup>
                        </Col>
                        <Col lg="6">
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="input-country"
                            >
                            To Age
                            </label>
                            <Input
                            className="form-control-alternative"
                            value={courseDetails.to_age}
                            id="input-country"
                            placeholder="To Age"
                            type="text"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,to_age:e.target.value})}
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="boys-capacity"
                            >
                            Boys Capacity
                            </label>
                            <Input
                            className="form-control-alternative"
                            defaultValue={courseDetails.boys_capacity}
                            id="boys-capacity"
                            placeholder="Boys Capacity"
                            type="number"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,boys_capacity:e.target.value})}
                            />
                        </FormGroup>
                        </Col>
                        <Col lg="6">
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="Girls-capacity"
                            >
                            Girls Capacity
                            </label>
                            <Input
                            className="form-control-alternative"
                            value={courseDetails.girls_capacity}
                            id="Girls-capacity"
                            placeholder="Girls Capacity"
                            type="number"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,girls_capacity:e.target.value})}
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                </div>
                <hr className="my-4" />
                {/* Description */}
                <div className="pl-lg-4">
                    <FormGroup>
                        <label className="form-control-label">Course Description</label>
                        <Input
                            className="form-control-alternative"
                            placeholder="Course Descriptionvalue"
                            rows="4"
                            value={courseDetails.description}
                            type="textarea"
                            readOnly={!isEditing ? true : false}
                            onChange={(e)=>setCourseDetails({...courseDetails,description:e.target.value})}
                        />
                    </FormGroup>
                </div>
                </Form>
            </CardBody>
        </Card>
        </Col>
    </Row>
    <Row>
        <div className="col-sm-12 mt-5"> 
        <div className='p-3'>
            <div className=" p-3">
                  <Button color={isTable==="orders" ? 'primary' : 'secondary'} onClick={()=>setIsTable('orders')}>Orders</Button>
                  <Button color={isTable==="waitlist" ? 'primary' : 'secondary'} onClick={()=>setIsTable('waitlist')}>Waitlist</Button>
                </div>
            {isTable==="orders" && <OrderTable courseId={courseId} courseName={courseDetails.course_name}/>}
            {isTable==="waitlist" && <WaitlistTable courseId={courseId} courseOpenToAll={courseDetails.open_for_all} courseName={courseDetails.course_name}/>}
            </div>
        </div>
    </Row>
       
    </Container>
    </div>
    )}
</>
)
}
