import React, { useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  CustomInput,
  Card,
  CardHeader,
  Table
} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { usePost } from 'hooks/usePost';
import { useFetch } from 'hooks/useFetch';
import { useNavigate } from 'react-router-dom';

function CreateCourse() {
  const [courseName, setCourseName] = useState('');
  const [courseDate, setCourseDate] = useState('');
  const [courseEndDate, setCourseEndDate] = useState('');
  const [ridingHours, setRidingHours] = useState('');
  const [certification, setCertification] = useState('');
  const [fromAge, setFromAge] = useState('');
  const [totalBoys, setTotalBoys] = useState(0);
  const [totalGirls, setTotalGirls] = useState(0);
  const [toAge, setToAge] = useState('');
  const [boys, setBoys] = useState('');
  const [girls, setGirls] = useState('');
  const [description,setDescription]=useState('');
  const [showTable,setShowTable]=useState(false);
  const [allLevels,setAllLevels]=useState([]);
  const [checkedLevels, setCheckedLevels] = useState([ ]);
  const {data}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getMasterLevelView/')
  const {postData,resData}=usePost();
  const {postData:publishCourse,resData:publishCourseDataRes}=usePost();
  const navigate = useNavigate();

    const handleLevelInputChange = (e, index, field) => {
     
        const updatedLevels = [...allLevels]; // Create a copy of the allLevels array
        const levelToUpdate = updatedLevels[index]; // Get the specific level object to update
    
        // Update the field of the specific level object
        levelToUpdate[field] = e.target.value;
    
        // Update the state with the new array
        setAllLevels(updatedLevels);
    };
  
    /* This function handles level table*/
    const handleLevelChange = (event, level) => {
        const isChecked = event.target.checked;

        if (isChecked) {
        // Add the checked level to the array
        setCheckedLevels([...checkedLevels,{ level_id: level.level_id, levelName:level.level_name}]);
        } else {
        // Remove the unchecked level from the array
        setCheckedLevels(checkedLevels.filter((level_id) => level_id !== level.level_id));
        }
    };

    const handleInputChange = (event) => {
        const { name, value, type } = event.target;

        if (type === 'radio') {
        if (name === 'certification') {
            setCertification(value);
        }
        } else {
        // Handle other input fields
        switch (name) {
            case 'courseName':
            setCourseName(value);
            break;
            case 'courseDate':
            setCourseDate(value);
            break;
            case 'courseEndDate':
                setCourseEndDate(value);
            break;
            case 'ridingHours':
            setRidingHours(value);
            break;
            case 'fromAge':
            setFromAge(value);
            break;
            case 'toAge':
            setToAge(value);
            break;
            case 'boys':
            setBoys(value);
            break;
            case 'girls':
            setGirls(value);
            break;
            default:
            break;
        }
        }
    };
  
    const handleSubmit = (event) => {
        event.preventDefault();
        setShowTable(false);
        if (
            !courseName ||
            !courseDate ||
            !ridingHours ||
            !certification ||
            !fromAge ||
            !toAge ||
            !boys ||
            !girls
        ) {
            NotificationManager.error('Please fill in all required fields', '', 3000);
            return; // Exit the function if any required field is missing
        }
        const formData = {
            course_name : courseName,
            certification : Number(certification),
            course_from_date : courseDate,
            course_to_date : courseEndDate,
            description : description,
            from_age : fromAge,
            to_age : toAge,
            boys_capacity : boys,
            girls_capacity : girls,
            list_order : 1,
            archive : 0
        };
        console.log(formData);
        console.log(checkedLevels);
        // setAllLevels(checkedLevels.map((each)=>{
        //         return {'level':each, 'boys_seats':0,'girls_seats':0,'price':0,'course':'1'}
        // }))
        postData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/createCourse/",formData,"post");
    };

    const handlePublishCourse=()=>{
        if (resData.boys_capacity > totalBoys && resData.girls_capacity > totalGirls) {
            NotificationManager.error('Both Boys and Girls Counts are Less than the Maximum Capacity', '', 3000);
        } else if (resData.boys_capacity > totalBoys) {
            NotificationManager.error('Boys Count is Less than the Maximum Capacity', '', 3000);
        } else if (resData.girls_capacity > totalGirls) {
            NotificationManager.error('Girls Count is Less than the Maximum Capacity', '', 3000);
        } else {
            const payload=allLevels.map((each)=>{
                return{
                    boys_seats: each.boys_seats,
                    girls_seats:each.girls_seats,
                    price: each.price,
                    course: each.course,
                    level: each.level_id
                }
            })
            console.log(payload)
            publishCourse(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/publishCourse/',payload,'post')
        }  
    }

    useEffect(()=>{
        if(resData){
            let totalBoysInArr=0;
            let totalGirlsInArr=0;
            allLevels.forEach((each)=>{
                totalBoysInArr=Number(each.boys_seats)+totalBoysInArr;
                totalGirlsInArr=Number(each.girls_seats)+totalGirlsInArr;    
            })
            if(totalBoysInArr<=resData.boys_capacity && totalGirlsInArr<=resData.girls_capacity){
                setTotalBoys(totalBoysInArr)
                setTotalGirls(totalGirlsInArr);
            }
            else{
                NotificationManager.error('Max Capacity Exceeding', '', 3000);
            }
        }
    },[allLevels])

    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success(resData.Message, '', 3000);
            console.log(resData)
            setAllLevels(checkedLevels.map((each)=>{
                return {'level':each.levelName,'level_id':each.level_id, 'boys_seats':0,'girls_seats':0,'price':0,'course':resData.course_id}
            }))
            setShowTable(true);
        }
    },[resData])

    useEffect(()=>{
        if(publishCourseDataRes && publishCourseDataRes.status===200){
            NotificationManager.success('Seats Allocated & Course Published!', '', 3000);
            setInterval(() => {
                navigate('/admin/index')
            }, 1000);
            
        }

    },[publishCourseDataRes])


  return (
    <div className="container pt-7">
        <NotificationContainer/>
        <Form onSubmit={handleSubmit}>
            <h6 className="heading-small text-muted mb-4">Course Information</h6>
            <div className="pl-lg-4">
                <Row>
                    <Col lg="6">
                    <FormGroup>
                        <Label for="courseName">Course Name</Label>
                        <Input
                        type="text"
                        id="courseName"
                        placeholder="Course Name"
                        name="courseName"
                        value={courseName}
                        onChange={handleInputChange}
                        className="form-control-alternative"
                        />
                    </FormGroup>
                    </Col>
                    <Col lg="6">
                    <FormGroup>
                        <Label for="exampleCheckbox">Certification</Label>
                        <div>
                        <CustomInput
                            type="radio"
                            id="yes"
                            name="certification"
                            label="Yes"
                            inline
                            value="1"
                            onChange={handleInputChange}
                        />
                        <CustomInput
                            type="radio"
                            id="no"
                            name="certification"
                            label="No"
                            inline
                            value="0"
                            onChange={handleInputChange}
                        />
                        </div>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <Label for="courseDate">Course Start Date</Label>
                            <Input
                            type="date"
                            id="courseDate"
                            name="courseDate"
                            value={courseDate}
                            onChange={handleInputChange}
                            className="form-control-alternative"
                            />
                        </FormGroup>
                        </Col>
                        <Col lg="6">
                        <FormGroup>
                            <Label for="courseDate">Course End Date</Label>
                            <Input
                            type="date"
                            id="courseEndDate"
                            name="courseEndDate"
                            value={courseEndDate}
                            onChange={handleInputChange}
                            className="form-control-alternative"
                            />
                        </FormGroup>
                    </Col> 
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                        <Label for="ridingHours">Riding Hours</Label>
                        <Input
                        type="text"
                        id="ridingHours"
                        placeholder="Riding Hours"
                        name="ridingHours"
                        value={ridingHours}
                        onChange={handleInputChange}
                        className="form-control-alternative"
                        />
                        </FormGroup>
                    </Col>
                    <Col className="lg-6">
                    <FormGroup>
                        <Label for="exampleText">Course Description</Label>
                        <Input className="form-control-alternative" 
                        type="textarea" 
                        name="text" 
                        id="exampleText" 
                        onChange={(e)=>setDescription(e.target.value)}
                        value={description}
                        />
                    </FormGroup>
                    </Col>
                    </Row>
                <Row>
                    <Col lg="6">
                    <FormGroup>
                        <Label for="fromAge">From Age</Label>
                        <Input
                        type="number"
                        id="fromAge"
                        placeholder="From Age"
                        name="fromAge"
                        min='0'
                        value={fromAge}
                        onChange={handleInputChange}
                        className="form-control-alternative"
                        />
                    </FormGroup>
                    </Col>
                    <Col lg="6">
                    <FormGroup>
                        <Label for="toAge">To Age</Label>
                        <Input
                        type="number"
                        id="toAge"
                        placeholder="To Age"
                        name="toAge"
                        min='0'
                        value={toAge}
                        onChange={handleInputChange}
                        className="form-control-alternative"
                        />
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                    <FormGroup>
                        <Label for="boys">Boys</Label>
                        <Input
                        type="number"
                        id="boys"
                        name="boys"
                        min='0'
                        value={boys}
                        onChange={handleInputChange}
                        className="form-control-alternative"
                        />
                    </FormGroup>
                    </Col>
                    <Col lg="6">
                    <FormGroup>
                        <Label for="girls">Girls</Label>
                        <Input
                        type="number"
                        id="girls"
                        name="girls"
                        min='0'
                        value={girls}
                        onChange={handleInputChange}
                        className="form-control-alternative"
                        />
                    </FormGroup>
                    </Col>
                </Row>
                <Label>Select Level(s)</Label>
                <Row>
                    {data && data.map((level) => (
                    <Col lg="3" key={level.level_id}>
                        <FormGroup check inline>
                            <Label check className='text-uppercase'>
                                <Input
                                className='text-uppercase'
                                type="checkbox"
                                name={level.level_id}
                                checked={level.checked}
                                onChange={(event) => handleLevelChange(event, level)}
                                />{' '}
                                {level.level_name}
                            </Label>
                        </FormGroup>
                    </Col>
                    ))}
                </Row>
                <Row className="mt-4">
                   {!showTable && <Button type="submit" color="primary" style={{width:"15%"}}>
                    Allocate Seats
                    </Button>}
                    {showTable && <Button type="submit" color="primary"  style={{width:"15%"}} disabled>
                    Allocate Seats
                    </Button>}
                </Row>
            </div>
        </Form>
      {/* Table */}
        {showTable && <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
                <Card className="shadow">
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">{courseDate}</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Level</th>
                            <th scope="col">Boys</th>
                            <th scope="col">Girls</th>
                            <th scope="col">Total</th>
                            <th scope="col">Price(All Inclusive)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {resData &&
                        allLevels.map((level, index) => (
                            <tr key={level.level_id}>
                            <th scope="row" className="text-uppercase">
                                {level.level}
                            </th>
                            <td>
                                <Input
                                type="number"
                                id="boys"
                                name="boys"
                                min="1"
                                value={level.boys_seats}
                                className="form-control-alternative"
                                onChange={(e) =>
                                    handleLevelInputChange(e, index, 'boys_seats')
                                }
                                />
                            </td>
                            <td>
                                <Input
                                type="number"
                                id="girls"
                                name="girls"
                                min="1"
                                value={level.girls_seats}
                                className="form-control-alternative"
                                onChange={(e) =>
                                    handleLevelInputChange(e, index, 'girls_seats')
                                }
                                />
                            </td> 
                            <td>
                                <Input
                                type="number"
                                id="total"
                                name="total"
                                className="form-control-alternative"
                                value={Number(level.boys_seats)+Number(level.girls_seats)}
                                readOnly
                                />
                            </td>
                            <td>
                                <Input
                                type="number"
                                id="price"
                                name="price"
                                value={level.price}
                                className="form-control-alternative"
                                onChange={(e) =>
                                    handleLevelInputChange(e, index, 'price')
                                }
                                />
                            </td>
                            </tr>
                            ))}                                                                
                        <tr>
                            <td>Total</td>
                            <td>{totalBoys}</td>
                            <td>{totalGirls}</td>
                            <td>{totalBoys + totalGirls}</td>
                            <td></td>
                        </tr>
                       {resData && <tr>
                            <td>Capacity</td>
                            <td>{resData.boys_capacity}</td>
                            <td>{resData.girls_capacity}</td>
                            <td>{resData.total}</td>
                            <td></td>
                        </tr>}
                        </tbody>
                    </Table>
                   
                </Card>
            </Col>
           
        </Row>}
        {showTable && <Row className="mt-4">
            <Button type="submit" color="primary" onClick={handlePublishCourse}>
                Publish Course
            </Button>
        </Row>}
    </div>
  );
}

export default CreateCourse;
