import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, {useEffect, useState} from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import { useAuthContext } from "hooks/useAuthContext";

import {
    Row,
    Col,
    Card,
    CardHeader,
    Container,
    Table,
    Pagination,
    Badge,
    PaginationItem,
    PaginationLink,
  } from 'reactstrap';

export default function ViewAllCoupons() {
  const {user}=useAuthContext();
  const {data:riderList}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getMembers/'+Number(user.user_id));
    const {postData:updateCoupon,resData:updateCouponRes}=usePost();

    const handleDeleteCoupon = (coupon_id) =>{
      const isConfirmed = window.confirm("Are you sure you want to delete this coupon?");
      if (isConfirmed){
        updateCoupon(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/deleteCoupon/"+coupon_id,{'coupon_id':coupon_id},"DELETE") 
      }
    }
    useEffect(() => {
      
      if(updateCouponRes && updateCouponRes.status===200){
        NotificationManager.success('Course deleted successfully!', '', 1000);
        window.location.reload();
      }
    
    }, [updateCouponRes])
    
  return (

    <div className="container pt-8 pb-4">
        <Container className="mt-2" fluid>
        <NotificationContainer/>
        <Row>
          <div className="col">
            <Card className="shadow">
                <CardHeader className="border-0">
                    <Row>
                      <Col lg="6">
                        <h3 className="mb-0">All Riders</h3>
                      </Col>
                      <Col lg="6" style={{'textAlign':'end'}}>
                        <Link to={'/parent/add-child'}><button class="btn btn-primary btn-sm">Add Rider/Camper</button></Link>
                      </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Rider Name</th>
                            <th scope="col">Gender</th>
                            <th className='text-center'> School Name</th>
                            <th className='text-center'>Rider Age</th>
                            <th className='text-center'>Aadhar Number</th>
                            <th className='text-center'>Passport Number</th>
                            {/* <th className='text-center'>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {riderList && riderList.member.map((rider)=>(<tr key={rider.member_id}>
                          <th scope="row">
                            <span>{rider.archive===0 && <Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" />
                            </Badge>}
                            {rider.archive===1 && <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                            </Badge>}</span><Link to={`/parent/edit-rider/${rider.member_id}`}>{rider.first_name} {rider.last_name}</Link>
                            </th>
                            <td className='text-center text-capitalize'>{rider.gender}</td>
                            <td className='text-center'>{rider.school_name}, {rider.city}</td>
                            <td className='text-center'>{rider.age}</td>
                            <td className='text-center'>{rider.aadhar_no!==''?rider.aadhar_no:'N/A'}</td>
                            <td className='text-center'>{rider.passport_no!==''?rider.passport_no:'N/A'}</td>
                            {/* <td className='text-center'><button class="btn btn-danger btn-sm">Delete</button></td> */}
                        </tr>))}
                    </tbody>
                </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </div>


  )
}
