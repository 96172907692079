import React from "react";
import campModuleImg1 from "../../../assets/img/theme/camp-module-1.jpg";
import pettingFarm from "../../../assets/img/spectators/png-16.png";
import disconight from "../../../assets/img/spectators/png-06.png";
import gardernvisit from "../../../assets/img/spectators/png-17.png";
import ghoststory from "../../../assets/img/spectators/lorraine 266.jpg";
import movienight from "../../../assets/img/spectators/png-13.png";
import watergames from "../../../assets/img/spectators/png-14.png";
export default function OtherActivities() {
  return (
    <div className="other-activity-container">
      <div className="other-activity-container container">
        <div className="coverer text-center py-5">
          <div className="row">
            <h1 className="about__h2">other camp activities</h1>
          </div>
          {/* row 1 */}
          <div className="row pt-3">
            <div className="col-sm-4 text-center">
              <img
                src={pettingFarm}
                style={{
                  width: "350px",
                  height: "350px",
                  objectFit: "cover",
                }}
                alt=""
              />
              <p>PETTING FARM</p>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={disconight}
                style={{
                  width: "350px",
                  height: "350px",
                  objectFit: "cover",
                }}
                alt=""
              />
              <p>DISCO NIGHT</p>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={gardernvisit}
                style={{
                  width: "350px",
                  height: "350px",
                  objectFit: "cover",
                }}
                alt=""
              />
              <p>GARDEN VISIT</p>
            </div>
          </div>
          {/* row 2 */}
          <div className="row">
            <div className="col-sm-4 text-center">
              <img
                src={ghoststory}
                style={{
                  width: "350px",
                  height: "350px",
                  objectFit: "cover",
                }}
                alt=""
              />
              <p>GHOST STORY</p>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={movienight}
                style={{
                  width: "350px",
                  height: "350px",
                  objectFit: "cover",
                }}
                alt=""
              />
              <p>MOVIE NIGHT</p>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={watergames}
                style={{
                  width: "350px",
                  height: "350px",
                  objectFit: "cover",
                }}
                alt=""
              />
              <p>WATER GAME</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
