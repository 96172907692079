// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container{
    margin-top: 20px;
}
.btn-container{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 8px;
    padding: 10px;
}
.btn-div{
    width: 100%;
    padding: 8px;
    text-align: left;
    border-radius: 8px;
    cursor: pointer;
}
.btn-div>p{
   margin-bottom: 0;
}
.btn-div:hover{
    box-shadow: none;
}
.active-class{
    background-color: #172b4d;
    color:#fff;
}
.desc-container{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 8px;
    padding: 30px;
    margin-left: 40px;
}


`, "",{"version":3,"sources":["webpack://./src/components/LearnMore.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,yFAAyF;IACzF,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;AACA;GACG,gBAAgB;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,UAAU;AACd;AACA;IACI,yFAAyF;IACzF,kBAAkB;IAClB,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".main-container{\n    margin-top: 20px;\n}\n.btn-container{\n    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;\n    border-radius: 8px;\n    padding: 10px;\n}\n.btn-div{\n    width: 100%;\n    padding: 8px;\n    text-align: left;\n    border-radius: 8px;\n    cursor: pointer;\n}\n.btn-div>p{\n   margin-bottom: 0;\n}\n.btn-div:hover{\n    box-shadow: none;\n}\n.active-class{\n    background-color: #172b4d;\n    color:#fff;\n}\n.desc-container{\n    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;\n    border-radius: 8px;\n    padding: 30px;\n    margin-left: 40px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
