import { useState } from 'react';
import "./LearnMore.css";
import { Button } from "reactstrap";
import { Link } from 'react-router-dom';

export default function LearnMore() {
    const [id, setId] = useState('1');
  
    return (
        <div className="container mt-5"> 
            <div className="row main-container">
                <div className="col-lg-4 col-md-6 btn-container">
                    <div className={id === "1" ? "btn-div active-class" : "btn-div"} onClick={() => setId("1")}>
                        <p><small>Step 1</small></p>
                        <i className='ni ni-single-02'></i>  &nbsp;&nbsp;Add Your Rider/Camper Details
                    </div>
                    <div className={id === "2" ? "btn-div active-class" : "btn-div"} onClick={() => setId("2")}>
                        <p><small className="text-left">Step 2</small></p>
                        <i className='ni ni-trophy'></i> &nbsp;&nbsp;Choose the Perfect Camp
                    </div>
                    <div className={id === "3" ? "btn-div active-class" : "btn-div"} onClick={() => setId("3")}>
                        <p><small>Step 3</small></p>
                        <i className='ni ni-check-bold'></i> &nbsp;&nbsp;Secure Your Spot
                    </div>
                    <div className={id === "4" ? "btn-div active-class" : "btn-div"} onClick={() => setId("4")}>
                        <p><small>Step 4</small></p>
                        <i className='ni ni-user-run'></i> &nbsp;&nbsp;Adventure Awaits!
                    </div>
                </div>
                <div className="col-lg-8 col-md-6 desc-container">
                    <div id="1" className={id === "1" ? "d-block" : "d-none"}>
                        <div>
                            It's time to tell us a bit about your riders/campers. Fill in their details to personalize their camp experience.
                        </div>
                        <Link to="/parent/add-child"><Button className='btn-primary text-white mt-3' >Add a Rider/Camper</Button></Link>
                    </div>
                    <div id="2" className={id === "2" ? "d-block" : "d-none"}> 
                        <div>Explore our amazing selection of camps designed to spark imagination and create unforgettable memories. Pick the ones that make your child's eyes light up with joy!</div>
                        <Button className='btn-primary text-white mt-3' >Choose Camp</Button>
                    </div>
                    <div id="3" className={id === "3" ? "d-block" : "d-none"}>Hooray! You're almost there. Simply select the camps you want, review your choices, and secure your child's spot with a click.</div>
                    <div id="4" className={id === "4" ? "d-block" : "d-none"}>Get ready for an incredible adventure together. We can't wait to see your child's smile as they embark on a summer full of friends, activities, and discovery!</div>
                </div>
            </div>
        </div>
    );
}
