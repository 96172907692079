
// reactstrap components
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useAuthContext } from "hooks/useAuthContext";
import { usePost } from "hooks/usePost";

const AdminLogin = () => {
  const [email,setEmail]= useState('');
  const [showForm,setShowForm]=useState(true);
  const [password,setPassword]=useState('');
  const navigate=useNavigate();
  const{dispatch}=useAuthContext();
  const {postData,resData,postError}=usePost();


  const handleSignIn = async (e) => {
    e.preventDefault();
  
    // Assuming email and password are stored in state variables
    if (!email && !password) {
        NotificationManager.error('Please fill required fields!', '', 3000);
        return 
    }
      const loginData = {
        email: email,
        password: password
      };
      postData(process.env.REACT_APP_DJANGO_SERVER_URL + '/api/loginAdmin/', loginData, "post");
  };
  
  useEffect(() => {
    if (resData && resData.status===200) {
        localStorage.setItem('user', JSON.stringify({
          role: resData.role,
          username: resData.username,
          email: resData.email
        }));
        dispatch({ type: 'LOGIN', payload: { role: resData.role, username: resData.username, email: resData.email } });
        navigate('/admin/index');
    }
    if(postError){
      NotificationManager.error('Incorrect Credentials!', '', 3000);
      return
    }

  }, [resData,postError]); // Assuming resData is declared as a state variable
    


  return (
    <>
    <NotificationContainer/>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h2>Sign In to Admin's Dashboard</h2>
            </div>
            {showForm && <form  onSubmit={handleSignIn}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                  />
                </InputGroup>
                <InputGroup className="input-group-alternative mt-3">
                  <Input
                    placeholder="Enter your password"
                    type="password"
                    value={password}
                    onChange={e=>setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign In
                </Button>
              </div>
            </form>}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col className="text-center" xs="12">
            <Link
              className="text-light"
              to="/auth/register"
            >
              {/* <small>Create new account</small> */}
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default AdminLogin;
