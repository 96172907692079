// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply the animation to the image */
  .img-pulse {
    animation: pulse 1.5s infinite; /* Adjust the animation duration as needed */
  }`, "",{"version":3,"sources":["webpack://./src/pages/Parents/success/OrderSuccess.css"],"names":[],"mappings":"AAAA;IACI;MACE,mBAAmB;IACrB;IACA;MACE,qBAAqB;IACvB;IACA;MACE,mBAAmB;IACrB;EACF;;EAEA,qCAAqC;EACrC;IACE,8BAA8B,EAAE,4CAA4C;EAC9E","sourcesContent":["@keyframes pulse {\n    0% {\n      transform: scale(1);\n    }\n    50% {\n      transform: scale(1.1);\n    }\n    100% {\n      transform: scale(1);\n    }\n  }\n  \n  /* Apply the animation to the image */\n  .img-pulse {\n    animation: pulse 1.5s infinite; /* Adjust the animation duration as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
