
import { usePost } from "hooks/usePost";
import { useState,Fragment, useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Form,FormGroup,Input,Label} from "reactstrap";

export default function Indemnity({isChecked,toggleCheckbox,indemnityModal,indemnitytoggle,handleOrder,isCouponVerified,couponCode,cartTotal}) {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const navigate = useNavigate();
  

  const togglePaymentModal = () => setIsPaymentModalOpen(!isPaymentModalOpen);

  const handleCheckboxChange = () => {
    toggleCheckbox();
  };

  const handleNavigate = () =>{
    const orderId = couponCode;
    const queryParams = 'iscoupon=true';
    const url = `/parent/order-success/${orderId}?${queryParams}`;
    // return console.log(url)
    navigate(url);
  }
  
  

  return (
    <div>
       {/* Modal */}
      <Modal
        isOpen={indemnityModal}
        toggle={indemnitytoggle}
        className="fullscreen-modal"
      >
        <ModalHeader toggle={indemnitytoggle}>
          JAPALOUPPE INDEMNITY POLICY
        </ModalHeader>
        <ModalBody>
          <h3>1. Indemnity and Release from Liability</h3>
          <p>
            The Client either in his or her personal capacity as a rider or as
            the legal guardian of rider, agrees and acknowledges to indemnify
            and hold Japalouppe Equestrian Center Pvt. Ltd. (hereinafter
            referred to as “JEC” which term includes affiliates, officers,
            employees, directors and authorized representatives) harmless from
            any claim or action, including legal fees arising out of the same
            related to or arising out of the services that JEC provides,
            Client’s use of the services or JEC premises, use of the site, any
            connection to the site, any violation of terms and conditions of
            this site and agreements, or Client’s or Client’s ward’s violation
            of any other rights or terms and conditions of other users’ of this
            site and the JEC services and premises.{" "}
          </p>
          <h3>2. Indemnity- Horse Riding and Related Farm Activities</h3>
          <p>
            The Client agrees and acknowledges that horse riding and the other
            related activities (trekking, swimming, games etc.) to the camps or
            services that JEC including but not limited to the handling of
            animals and being in proximity to all the farm animals is an
            activity with inherent dangers and involves risks that may cause
            serious or grievous injury due to the nature and behavior of animals
            regardless of their training, previous behavior or temperament.
            Client therefore knowingly and voluntarily assumes these risks upon
            undertaking this activity or upon entering the premises and agrees
            to indemnify, release and discharge JEC from any liability for any
            injury or damage caused or any medical expenses related thereto.
            Client agrees and acknowledges that JEC provides an environment
            where the farm animals and riders interact freely and therefore in
            full knowledge of the same consents to attending or allowing his or
            her ward to attend the camps/training modules or enter the premises.
          </p>
          <h3>3. Safety Codes and Adherence</h3>
          <p>
            A. JEC undertakes to adhere to the highest safety standards within the
            country and for this reason provides safety helmets and adheres to a
            strict dress code for riding regarding footwear etc., and the Client
            agrees and acknowledges that if the Client chooses to refrain or
            ignore the same, the Client does so at his or her own risk and
            releases JEC from any liability for the same.
            <br/>
            B. JEC reserves the right to refuse admission in JEC’s sole discretion if
            JEC deems that the rider’s health will not allow for the rider to undertake horse-riding or
            farm activities safely.
            <br/>
            C. JEC also has dedicated tie-ups with the local medical hospitals for provision of treatment and medical
            attention if necessary. In the event of an emergency and
            unavailability of the guardians or other authorized medical
            emergency contacts, Client authorizes JEC and or the hospital to
            take what steps may be necessary as may be reasonably ascertained to
            preserve and safeguard the health and well being of the rider.
          </p>
          <h3>4. Medical </h3>
          <p>
            A. It is the Client’s sole responsibility to inform JEC of any
            ailments, allergies medications etc. that the Client or his/her ward
            suffer from or require. JEC is not responsible for the Client’s
            failure to inform JEC of the same. JEC reserves the right to refuse
            admission in JEC’s sole discretion if JEC deems that the rider’s
            health will not allow for the rider to undertake horse-riding or
            farm activities safely. 
            <br/>
            B. JEC also has dedicated tie-ups with the local medical hospitals for provision of treatment and medical
            attention if necessary. In the event of an emergency and
            unavailability of the guardians or other authorized medical
            emergency contacts, Client authorizes JEC and or the hospital to
            take what steps may be necessary as may be reasonably ascertained to
            preserve and safeguard the health and well being of the rider.
          </p>
          <h3>
            5. Admission into and removal from premises during training
            modules/camps
          </h3>
          <p>
            A. During the tenure of the camp, guardians/friends or other related
            personnel of riders are not allowed to enter the premises unless it
            is with the permission and authorization of JEC, which permission
            may be granted or refused at JEC’s sole discretion.
            <br/>
            B. JEC reserves
            the right to accept or deny admission in the camps or access to the
            premises at its sole discretion. JEC reserves the right to send a
            rider home or to evict them from the premises if it is deemed that
            such rider has a detrimental or negative influence and must be
            removed for the preservation of the wellbeing of the other riders
            and participants at camp.
            <br/>
            C. Kindly note that in the event the rider
            leaves the camp or training module before completion, irrespective
            of the reason, no refund will be offered. 
            <br/>
            D. Certification is
            provided only upon completion of the entire training module and all
            its requirements and no exception shall be made for the same.
          </p>
          <h3>6. Personal Belongings </h3>
          <p>
            Clients and/or their wards are solely responsible for the safety of
            their personal belongings, money and valuables and JEC takes no
            responsibility for the same. Minor children are discouraged from
            carrying money or valuables and to do so, is entirely at the
            Client’s risk and responsibility.
          </p>
          <Form>
              <FormGroup check inline>
                  <Input type="checkbox" onChange={handleCheckboxChange} checked={isChecked} style={{transform: 'scale(1.2)', border: '2px solid #000'}}/>
                  <Label check>{" "}
                    <h3 className="pt-2"> I have read and accept the Japalouppe indemnity policy.</h3>
                  </Label>
              </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" disabled={!isChecked} onClick={handleOrder}>
            Proceed to Pay
          </Button>{" "} */}
          { !isCouponVerified  && <Button color="primary" disabled={!isChecked} onClick={handleOrder}>
            Proceed to payment
          </Button>}
          { isCouponVerified && Number(cartTotal)===0 && <Button color="primary" disabled={!isChecked} onClick={handleNavigate}>
            Redeem Coupon
          </Button>}
          { isCouponVerified && Number(cartTotal)>0 && <Button color="primary" disabled={!isChecked} onClick={handleOrder}>
            Avail Discount and Pay
          </Button>}
          {" "}
          <Button color="secondary" onClick={indemnitytoggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
