import { Link, NavLink } from "react-router-dom";
import "./AdminNav.css";
import logo from "../../assets/img/brand/Japalouppe_Logo_navy.png";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
const SpectatorNavbar = (props) => {

  return (
    <>
    <div className="about-jap px-4" style={{"padding":"14px 0px 14px 0px"}}>
      <div className="row">
        <div className="col-sm-4">
            <h5 className="text-white mb-0 fw-regular">
              <span className="text-white"><i class="fa-solid fa-location-dot" ></i></span> &nbsp;
              NH 48, NEAR SOMATNE TOLL, TALEGAON DABHADE
            </h5>            
        </div>
        <div className="col-sm-2">
          <a href="tel:+918956281070"><h5 className="text-white mb-0">+91 8956281070</h5></a>
        </div>
        <div className="col-sm-6 text-end">
            <a href="https://www.instagram.com/japalouppe/" rel="noreferrer" target="_blank" className="text-white"><i class="fa-brands fa-instagram"></i></a>
        </div>
      </div>
    </div>
      <Navbar className="navbar-horizontal navbar-light" expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              src={logo}
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={logo}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0 text-align-right">
              <li className="nav-item">
                <NavLink
                  className="nav-link text-uppercase"
                  to="/spectator/camp-life"
                >
                  Camp Life
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-uppercase" to="/spectator/about-japalouppe">
                  About Japalouppe
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-uppercase" to="/spectator/faq">
                  FAQ's
                </NavLink>
              </li>
            </ul>
            </Nav>
            <ul className=" ms-auto navbar-nav align-items-end">
            <li className="nav-item">
              <NavLink className="nav-link text-uppercase" to="/auth/register">
                Register
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-uppercase" to="/auth/login">
                Login
              </NavLink>
            </li>
            </ul>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default SpectatorNavbar;
