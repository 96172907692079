import Loader from 'components/Loader';
import { useFetch } from 'hooks/useFetch'
import React from 'react'
import { Table, Media , Card , CardHeader } from 'reactstrap';

export default function ViewRRPOrders() {
    const {data, isPending, Error} = useFetch(process.env.REACT_APP_NODE_SERVER_URL+"/api/all-rrp-orders");
  return (
    <div className='container pt-9'>
        {isPending && <Loader/>}
        { <Card className="shadow mt-2">
                <CardHeader className="border-0">
                    <div className="row">
                        <div className="col-sm-6">
                          <h3 className="mb-0">All RRP Orders</h3>
                        </div>
                        <div className="col-sm-5 text-right">
                          <h3 className="mb-0">Total Revenue: ₹{data && data.total_revenue}</h3>
                        </div>
                        <div className="col-sm-1  text-right">
                          <a href="/"><button class="btn btn-primary btn-sm">Export</button></a>
                        </div>                    
                      </div>
                </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Rider Name</th>
                    <th scope="col">Parent Name</th>
                    <th scope="col">Guests</th>
                    <th scope="col">Parent Phone Number</th>
                    <th scope="col">Payment Amount</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data && data.orders.map((each)=>(<tr key={each.rrp_order_id}>
                    <th scope="row">
                      <Media className="align-items-center">
                        <Media>
                          <span className="mb-0 text-sm text-center">
                           {each.parent_first_name} {each.parent_last_name}
                          </span>
                        </Media>
                      </Media>
                    </th>

                    <td>{each.parent_first_name} {each.parent_last_name}</td>
                    <td className='text-center'>{each.guest_count}</td>
                    <td className='text-center'> {each.parent_phone_number}</td>
                    <td className='text-center'>
                    {each.payment_amount}
                    </td>
                    <td>{new Date(each.checkin_date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric',})}</td>
                    <td>{new Date(each.checkout_date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric',})}</td>
                  
                  </tr>))}
                  {/* {memberData && memberData.length===0 && <tr className="text-center">
                    <div className="text-center py-3">
                      <h3> No Records Found.</h3>
                    </div>
                  </tr>} */}
                </tbody>
              </Table>
              </Card>}

    </div>
  )
}
