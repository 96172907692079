import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  Label,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useNavigate, Link } from "react-router-dom";
import { usePost } from "hooks/usePost";
import { useAuthContext } from "hooks/useAuthContext";
import codes from 'country-calling-code';

const Register = () => {
  // State variables to store input values
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [showForm,setShowForm] = useState(true);
  const [otp,setOtp]=useState('');
  const navigate=useNavigate();
  const {resData}=usePost();
  const {dispatch}=useAuthContext();
  const [sendingOtp, setSendingOtp] = useState(false)
  const {postData:otpData,resData:otpVerifiedRes,postError:otpError}=usePost();
  const [modalOpen, setModalOpen] = useState(false);
  const [countryCode,setCountryCode] = useState('');
  
  const handleCheckboxChange = (event) => {
    if(event.target.checked){
      setModalOpen(true);
    }
    else{
      setModalOpen(false);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First Name is required";
    }

    if (!lastName.trim()) {
      errors.lastName = "Last Name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!contactNumber.trim()) {
      errors.contactNumber = "Contact Number is required";
    }

    return errors;
  };
  // Handle form submission
  const handleSubmit = async(e) => {
    e.preventDefault();
    setShowForm(true);
    const validationErrors = validateForm();
    if(countryCode === ""){
      NotificationManager.error('Please select country code' , '', 3000);
      return;
    }
    if (Object.keys(validationErrors).length === 0) {
      console.log("Form Data:");
      console.log("First Name:", firstName);
      console.log("Last Name:", lastName);
      console.log("Email:", email);
      console.log("Contact Number:", contactNumber);
      // postData("http://127.0.0.1:8000/api/otp/",{phone :"+91"+String(contactNumber)},'POST')
      try{
        console.log("+"+countryCode+String(contactNumber))
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {phone_number :"+"+countryCode+String(contactNumber), first_name:firstName,last_name:lastName}
        ),
      };
      setSendingOtp(true)
      const res = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/otp/', requestOptions);
      console.log(res)
      if(!res.ok){
        throw new Error('Unable to register');
      }
      const data = await res.json();
      if(data.status !==200){
        throw new Error(data.Error);
      }
      NotificationManager.success('OTP sent successfully!' , '', 3000);
      setShowForm(false);
    }catch(err){
      NotificationManager.error(err.message , '', 3000);
    }
    
    }
  };
  useEffect(()=>{
    if(resData && resData.status===200){
      setSendingOtp(false)
      NotificationManager.success('OTP sent successfully!' , '', 3000);
      setShowForm(false);
    }else  if(resData && resData.status!==200){
      setSendingOtp(false)
      NotificationManager.error('Something went wrong!' , '', 3000);
    }
  },[resData])

  const handleOtpVerification =(e)=>{
    e.preventDefault();
      otpData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/verify-otp/",{phone_number :"+91"+String(contactNumber),otp:otp},'POST') 
  }
  useEffect(() => {
    if(otpVerifiedRes && otpVerifiedRes.status===200){
      handleRegistration();
    }
    else if(otpError && otpError.status===400){
      NotificationManager.error(otpError.Error , '', 3000);
    }
  }, [otpVerifiedRes, otpError])

const handleRegistration=async()=>{
  try{
  const payload={
    username: email,
    email: email,
    first_name: firstName,
    last_name: lastName,
    phone_number: "+"+countryCode+contactNumber
    }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // You can include any request body data here if needed
    body: JSON.stringify(
      payload
    ),
  };
  const res = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/register/', requestOptions);

  if (!res.ok) {
    throw new Error('Unable to register');
  }

  const data = await res.json(); // Note the use of 'await' here
  console.log(data,'data');
  if (data.status===400) {
    throw new Error(data.Error);
  }
  if(data.status===200){
    NotificationManager.success('Registration successfully!', '', 3000);
    localStorage.setItem('user',JSON.stringify(data));
    dispatch({type:'LOGIN',payload:data});
    navigate('/parent/index');
  }
  
}catch(error){
    NotificationManager.error(error.message , '', 3000);
  }

}
  return (
    <>
    <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Note</ModalHeader>
          <ModalBody>
            <b>New campers:</b> Register and book directly.
            <br/><br/>
            <b>Returning campers:</b> Let us update your profile first, then you may proceed to book your camp.
            <br/><br/>
            📝To register your profile and for tech support, contact Suraj: <a href="tel:+919860652967">+91 9860652967</a> [ Thurs-Tues, 10am-6pm]
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
      </Modal>
    <NotificationContainer/>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5 text-center">
            <div className="text-center text-muted mb-4">
              <h2>Sign Up</h2>
            </div>
            {showForm && <Form role="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
              </div>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              
              <FormGroup>
                {/* <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="WhatsApp Number"
                    type="number"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </InputGroup> */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <Input type="select" onChange={(e)=>setCountryCode(e.target.value)} style={{ background: "#ececec" }}>
                      <option value="">Select Code</option>
                      {codes && codes.map((each) => (
                        <option key={each.isoCode3} value={each.countryCodes[0]}>
                          {each.isoCode3} {" "} +({each.countryCodes[0]})
                        </option>
                      ))}
                    </Input>
                  </div>
                <Input
                placeholder="WhatsApp Number"
                type="text"
                value={contactNumber}
                onChange={e => setContactNumber(e.target.value)}
              />
            </div>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" onChange={handleCheckboxChange} style={{ border: '2px solid black' }} /> Are you a previous camper?
                </Label>
              </FormGroup>
              <div className="text-muted font-italic">
                <small>You will receive OTP on your WhatsApp number</small>
              </div>
              <div className="text-center">
                {!sendingOtp && <Button className="mt-4" color="primary" type="submit">
                  Send OTP
                </Button>}
                {sendingOtp && <Button disabled className="mt-4" color="primary" type="submit" >
                  Sending...
                </Button>}
              </div>
            </Form>}
            {!showForm && <Form onSubmit={handleOtpVerification}>
              <div className="row">
                  <div className="col-sm-12">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Enter OTP"
                          type="text"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
              </div>
              <div className="text-center">
                    <Button className="mt-4" color="primary" type="submit" >
                      Verfiy OTP
                    </Button>
                </div>
            </Form>}
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col className="text-center" xs="12">
            <Link
              className="text-light"
              to="/auth/login"
            >
              <small>Already Have an Account? Login</small>
            </Link>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Register;
