import React from 'react'
import boysDorm from "../../../assets/img/spectators/Boys-Dorm.jpg"
import girlsDorm from "../../../assets/img/spectators/Girls-Dorm.jpg"
export default function BunkLife() {
  return (
    <div>
        {/* Bunk Life */}
        <div className="container text-center py-5">
          <div className="row">
            <h2 className='about__h2'>the bunk life</h2>
            <p>
              We have separate Girls & Boys dormitory that house over 45 kids
              combined. Our bunks are filed with rustic charm and plenty of
              space. Many campers who have now grown-up have made their best
              friends in Japalouppe’s dorms. The camp volunteers stay in the
              dorm and oversee the well-being of the campers. We have a large
              detached girls and boys toilet block that have clean modern
              bathrooms, personal showers, private toilets
            </p>
          </div>
          <div className="row mt-4">
            <div className="col-sm-6 text-center">
              <img
                src={boysDorm}
                style={{
                  width:"100%",
                }}
                alt="boys-dorm"
              />
              <p className='mt-3'>BOYS DORM</p>
            </div>
            <div className="col-sm-6 text-center">
              <img
                src={girlsDorm}
                style={{
                  width:"100%",
                }}
                alt="girls-dorm"
              />
              <p className='mt-3'>GIRLS DORM</p>
            </div>
          </div>
        </div>
      </div>
  )
}
