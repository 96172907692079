import React, { useState } from 'react';
import img from "../../../assets/img/spectators/png-06.png";
import img2 from "../../../assets/img/spectators/png-09.png";
import img3 from "../../../assets/img/spectators/png-10.png";
import img4 from "../../../assets/img/spectators/png-11.png";
import img5 from "../../../assets/img/spectators/png-12.png";
import img6 from "../../../assets/img/spectators/png-13.png";

import img7 from "../../../assets/img/spectators/japalouppe_media_1.png";
import img8 from "../../../assets/img/spectators/japalouppe_media_2.png";
import img9 from "../../../assets/img/spectators/japalouppe_media_3.png";
import img10 from "../../../assets/img/spectators/japalouppe_media_4.png";
import img11 from "../../../assets/img/spectators/japalouppe_media_5.png";
import img12 from "../../../assets/img/spectators/japalouppe_media_21.jpg";
import img13 from "../../../assets/img/spectators/japalouppe_media_22.jpg";
import img14 from "../../../assets/img/spectators/japalouppe_media_8.png";
import img15 from "../../../assets/img/spectators/japalouppe_media_9.png";
import img16 from "../../../assets/img/spectators/japalouppe_media_20.jpg";
import img17 from "../../../assets/img/spectators/japalouppe_media_11.png";
import img18 from "../../../assets/img/spectators/japalouppe_media_19.jpg";
import "../Tickets.css"
import {
  Button,
} from "reactstrap";
export default function Gallery() {
  const [showMoreImages, setShowMoreImages] = useState(false);
  const [loaded, setLoaded] = useState(false);


  const toggleShowMoreImages = () => {
    setShowMoreImages(!showMoreImages);
    setTimeout(() => {
      setLoaded(true);
    }, 700); // Adjust the timeout as needed
  };

  return (
    <div className="photo-gallery">
      <div className="container text-center">
        <div className="intro">
          <h1 className="text-center pt-4 about__h2">sneak peak into camp life</h1>
        </div>
        <div className="row photos">
          <div className="col-sm-6 col-md-4 col-lg-4 item">
            <img className="img-fluid" src={img} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 item">
            <img className="img-fluid" src={img2} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 item">
            <img className="img-fluid" src={img3} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 item">
            <img className="img-fluid" src={img5} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 item">
            <img className="img-fluid" src={img6} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 item">
            <img className="img-fluid" src={img4} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
          </div>
        </div>
        {!showMoreImages && <Button color='primary' className='my-3 px-4' onClick={toggleShowMoreImages}> SHOW MORE</Button>}
        {showMoreImages && (
          <div className={`row photos ${loaded ? 'fade-in loaded' : 'fade-in'}`}>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img7} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img8} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img9} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img10} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img11} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img12} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img13} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img14} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img15} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img16} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img17} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 item">
              <img className="img-fluid" src={img18} style={{ height: "300px", objectFit: 'cover' }} alt="gallery" />
            </div>
          </div>
        )}
      </div>
      <div className="text-center"> 
        {showMoreImages && <Button color='primary' className='my-3 px-4 text-center' onClick={toggleShowMoreImages}> SHOW LESS</Button>}
      </div>  
    </div>
  );
}
