import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import ParentLayout from 'layouts/ParentLayout'
import { useAuthContext } from 'hooks/useAuthContext';
import SpectatorLayout from 'layouts/Spectator';
import InstructorLayout from 'layouts/InstructorLayout';
export default function App() {
    const{user,authIsReady}=useAuthContext();
  return (
    <div>
        {authIsReady && <BrowserRouter>
            <Routes>
              {!user && <Route path="/spectator/*" element={<SpectatorLayout />} />}
              {!user && <Route path="/auth/*" element={<AuthLayout />} />}
              {!user && <Route path="*" element={<Navigate to="/spectator/index" replace />} />}
              {user && user.role==="admin" && <Route path="/admin/*" element={<AdminLayout />} />}
              {user && user.role==="admin" && <Route path="*" element={<Navigate to="/admin/index" replace />} />}
              {user &&  user.role==="parent" && <Route path="/parent/*" element={<ParentLayout/>} />}
              {user && user.role==="parent" && <Route path="*" element={<Navigate to="/parent/index" replace />} />}
              {user && user.role==="instructor" && <Route path="/instructor/*" element={<InstructorLayout />} />}
              {user && user.role==="instructor" && <Route path="*" element={<Navigate to="/instructor/index" replace />} />}
            </Routes>
        </BrowserRouter>}
    </div>
  )
}
