import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    CustomInput,
    Table,
    CardFooter,
    Label,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import OrderTable from 'pages/OrderTable';

export default function EnrolledRiders() {
    const { courseId } = useParams();
    const { data: course } = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getCourse/" + courseId);
    const {data:levels}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getMasterLevelView/");
    const [isEditing,setIsEditing]=useState(false);
    const [rows, setRows] = useState([]);
    const [newRow, setNewRow] = useState({ level_name: '', price: 0 , boys_seats: 0 , girls_seats: 0 });
    const [isTableEditing,setIsTableEditing]=useState(false);
    const [courseDetails,setCourseDetails]=useState(null);
    const {postData:updateCourse,resData:courseUpdatedResponse}=usePost();
    const [courseSeatTable,setCourseSeatTable]=useState(null);
    const {postData,resData}=usePost();
    const [girlsTotal,setGirlsTotal]=useState(0);
    const [boysTotal,setBoysTotal]=useState(0);
    const [newRowBoysTotal,setNewRowBoysTotal]=useState(0);
    const [newRowGirlsTotal,setNewRowGirlsTotal]=useState(0);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [isTable,setIsTable]=useState('orders')

    useEffect(()=>{
        if(course){
            setCourseDetails(course.course_details);
            setCourseSeatTable(course.course_level_seats);
        }

    },[course])
    
    useEffect(()=>{ 
        if(course){
        course.course_level_seats.forEach((each)=>{
            console.log(each)
            setBoysTotal(prev=>prev+Number(each.boys_seats))
            setGirlsTotal(prev=>prev+Number(each.girls_seats))
        })
        }

    },[course])

    const addNewRow = () => {
        if(boysTotal+newRowBoysTotal=== courseDetails.boys_capacity && girlsTotal+newRowGirlsTotal===courseDetails.girls_capacity ){
            NotificationManager.error('Allocated seats are already equal to the maximum capacity', '', 3000);
            return;
        }
        setRows([...rows, newRow]);
        setNewRow({ level_name: '', price: 0 , boys_seats: 0 , girls_seats: 0});
    };

    const removeRow = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const handleEditing=()=>{
        setIsEditing(!isEditing);
    }

    const handleTableEditing=()=>{
        setIsTableEditing(!isTableEditing);
    }

    const saveCourseDetails=()=>{
        console.log(courseDetails)
        updateCourse(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/updateCourse/"+courseId,{...courseDetails,"list_order": 1,"archive": 0},"PUT") 
    }

    useEffect(()=>{
        if(courseUpdatedResponse && courseUpdatedResponse.status===200){
            NotificationManager.success('Course updated Successfully!', '', 3000);
            setIsEditing(!isEditing);
        }
    },[courseUpdatedResponse])

    const handleInputChange = (index, fieldName, value) => {
        // Make a copy of the courseSeatTable array to avoid mutating state directly
        const updatedCourseSeatTable = [...courseSeatTable];
      
        // Update the value in the copied array
        updatedCourseSeatTable[index][fieldName] = value;
      
        // Update the state with the new array
       
        let localBoysSeats=0
        let localGirlsSeats=0;
        updatedCourseSeatTable.forEach((each)=>{
            localBoysSeats=localBoysSeats+Number(each.boys_seats);
            localGirlsSeats=localGirlsSeats+Number(each.girls_seats);
        }) 
        if(localBoysSeats+newRowBoysTotal>courseDetails.boys_capacity){
            NotificationManager.error('Boys Maximum Capacity Exceeded', '', 3000);
            return;
        }else if(localGirlsSeats+newRowGirlsTotal>courseDetails.girls_capacity){
            NotificationManager.error('Girls Maximum Capacity Exceeded', '', 3000);
            return;
        }
        console.log(updatedCourseSeatTable)
        setBoysTotal(localBoysSeats);
        setGirlsTotal(localGirlsSeats);
        setCourseSeatTable(updatedCourseSeatTable);   
      };

    const saveSeatTableData=(e)=>{
    e.preventDefault();
    console.log(courseSeatTable);
    if(boysTotal+newRowBoysTotal<courseDetails.boys_capacity){
        NotificationManager.error('Boys Seats are less than its Maximum Capacity', '', 3000);
            return;
    }
    else if(girlsTotal+newRowGirlsTotal<courseDetails.girls_capacity){
        NotificationManager.error('Girls Seats are less than its Maximum Capacity', '', 3000);
            return;
    }
    let arr=courseSeatTable.map((each)=>{
        return{
            id:each.id,
            boys_seats:each.boys_seats,
            girls_seats:each.girls_seats,
            level:each.level,
            price:each.price,
            course:each.course
        }
    })
    let additionSeatsArr=rows.map((each)=>{
        return{
            boys_seats:Number(each.boys_seats),
            girls_seats:Number(each.girls_seats),
            level:Number(each.level_name),
            price:Number(each.price),
            course:Number(courseId)
        }
    })
    let mergedArr=[...arr,...additionSeatsArr];

    postData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/updateCourseLevelSeats/"+courseId,mergedArr,"PUT")
    }
    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success('Table updated Successfully!', '', 3000);
            setIsTableEditing(false);
            window.location.reload();
        }
    },[resData])

    const handleCourseSeatTable=(e,currentRow,index, field)=>{
        // Clone the rows array to avoid mutating the state directly
        const updatedRows = [...rows];
        console.log(field)
        // Check if the field is 'level_id' (for the <select> element)
        if (field === 'level_id') {
            updatedRows[index] = {
                ...currentRow,
                level_name: e.target.value,
            };
        } else {
            // Update the currentRow object based on the input field's name
            updatedRows[index] = {
                ...currentRow,
                [e.target.name]: e.target.value,
            };
        }
        let localBoysSeats=0;
        let localGirlsSeats=0;
        // Update the state with the new rows array
        console.log(updatedRows)
        updatedRows.forEach((each)=>{
            localBoysSeats=localBoysSeats+Number(each.boys_seats);
            localGirlsSeats=localGirlsSeats+Number(each.girls_seats);
        })
        if(localBoysSeats+boysTotal>courseDetails.boys_capacity){
            NotificationManager.error('Boys Maximum Capacity Exceeded', '', 3000);
            return;
        }
        if(localGirlsSeats+girlsTotal>courseDetails.girls_capacity){
            NotificationManager.error('Girls Maximum Capacity Exceeded', '', 3000);
            return;
        }
        setNewRowGirlsTotal(localGirlsSeats);
        setNewRowBoysTotal(localBoysSeats);
        setRows(updatedRows);
    }
    const handlePopUPOpen=(id)=>{
        setItemToDelete(id);
        setIsDeleteModalOpen(true);
    }
    const deleteSeat=async()=>{
      
        try {
            const response = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/deleteCourseLevelSeat/'+itemToDelete, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json', 
              },
            });
      
            if (response.ok) {
                NotificationManager.success('Deleted Successfully!', '', 3000);
                setIsDeleteModalOpen(false);
            } else {
              const errorData = await response.json();
              NotificationManager.error('Unable to delete'+errorData, '', 3000);
            }
          } catch (error) {
            console.error('Error:', error);
            console.log('An error occurred while making the request.');
            NotificationManager.error('Unable to delete'+error, '', 3000);
          }
          let localCourseState=courseSeatTable.filter((each)=>{
            return each.id !==itemToDelete;
        })
        let localBoysTotal=0;
        let localGirlsTotal=0;
        localCourseState.forEach((each)=>{
            localBoysTotal=localBoysTotal+each.boys_seats;
            localGirlsTotal=localGirlsTotal+each.girls_seats;
        })
        setBoysTotal(localBoysTotal);
        setGirlsTotal(localGirlsTotal);
        setCourseSeatTable(courseSeatTable.filter((each)=>{
            return each.id !==itemToDelete;
        }));
    }
return (
<>
    <NotificationContainer/>
    {/* Modal */}
    <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
        <ModalHeader toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>Confirm Delete</ModalHeader>
        <ModalBody>
            Are you sure you want to delete this item?
        </ModalBody>
        <ModalFooter>
            <Button color="#D10000" style={{'background':'#D10000', 'color':'#fff'}} onClick={deleteSeat}>Yes</Button>
            <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>No</Button>
        </ModalFooter>
    </Modal>
    {course && courseDetails && (
    <div>
    <div
    className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    style={{
    minHeight: "450px",
    backgroundImage:
    "url(" + require("../../assets/img/theme/counter-bg.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    }}
    >
    {/* Mask */}
    <span className="mask  opacity-8" />
    {/* Header container */}
    <Container className="d-flex align-items-center" fluid>
        <Row>
            <Col lg="12" md="10">
            <h1 className="display-2 text-white">{courseDetails.course_name}</h1>
            <p className="text-white mt-0 mb-5">
                {courseDetails.description}
            </p>
            </Col>
        </Row>
    </Container>
    </div>
    {/* Page content */}
    <Container className="mt--7" fluid>
    
    <Row>
        <div className="col-sm-12 mt-5"> 
        <div className='p-3'>
            <div className=" p-3">
                    <Button color={isTable==="orders" ? 'primary' : 'secondary'} onClick={()=>setIsTable('orders')}>Orders</Button>
                </div>
            {isTable==="orders" && <OrderTable courseId={courseId} courseName={courseDetails.course_name} />}
            </div>
        </div>
    </Row>
        
    </Container>
    </div>
    )}
</>
)
}
