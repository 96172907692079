// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card{
    margin-right: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: #F8F9FE;
}.card>img{
    height:100px;
    object-fit: contain;
}
.card-icon{
 background-color: rgb(9, 195, 9);
}
.skillbadges>.slick-slider>.slick-next:before,.skillbadges>.slick-slider>.slick-prev:before{
    color:#09173A !important;
}

.color-slide img{
    filter: grayscale(0%);
  }
  
  /* Styles for ride slides */
  .bw-slide img {
    filter: grayscale(100%);
  }
`, "",{"version":3,"sources":["webpack://./src/pages/Parents/components/SkillBadge.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yFAAyF;IACzF,yBAAyB;AAC7B,CAAC;IACG,YAAY;IACZ,mBAAmB;AACvB;AACA;CACC,gCAAgC;AACjC;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;EACvB;;EAEA,2BAA2B;EAC3B;IACE,uBAAuB;EACzB","sourcesContent":[".card{\n    margin-right: 20px;\n    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;\n    background-color: #F8F9FE;\n}.card>img{\n    height:100px;\n    object-fit: contain;\n}\n.card-icon{\n background-color: rgb(9, 195, 9);\n}\n.skillbadges>.slick-slider>.slick-next:before,.skillbadges>.slick-slider>.slick-prev:before{\n    color:#09173A !important;\n}\n\n.color-slide img{\n    filter: grayscale(0%);\n  }\n  \n  /* Styles for ride slides */\n  .bw-slide img {\n    filter: grayscale(100%);\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
