import React from 'react';
import './CampTicket.css';
import { Link } from 'react-router-dom';

const CampTicket = ({ title, startDate,endDate, days, ages, price, seatsFilled, totalSeats, link }) => {
    const numberOfDivs = 31;
  return (
    <div className="camp-ticket">
         {Array.from({ length: numberOfDivs }).map((_, index) => (
            <div className="circle-container" key={index}></div>
        ))}
        <div className="camp-ticket-container">
            <div className="camp-ticket__header">{title}</div>
            <div className="camp-ticket__date"><h3>{startDate} - {endDate}</h3></div>
            <div className="camp-ticket__details">
                <div className="camp-ticket__detail-item">
                <span>DAYS - </span>
                <span>{days}</span>
                </div>
                <div className="camp-ticket__detail-item">
                <span>AGES - </span>
                <span>{ages}</span>
                </div>
            </div>
            <div className="camp-ticket__price"><h3>INR {price}</h3></div>
            <div className="camp-ticket__seats">
                {seatsFilled}/{totalSeats} SEATS FILLED
            </div>
            <Link to={link}><button className="camp-ticket__button">BOOK</button></Link>
        </div>
    </div>
  );
};

export default CampTicket;
