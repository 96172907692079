import React from "react";
import { Row, Container, Col } from "reactstrap";
import "./CampLife.css";
// import TeamSilder from "./components/TeamSilder";
import CampModule from "./components/CampModule";
import TypicalDay from "./components/TypicalDay";
import BunkLife from "./components/BunkLife";
import WellnessAndSafety from "./components/WellnessAndSafety";
import OtherActivities from "./components/OtherActivities";
export default function CampLife() {
  return (
    <div>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/png-04.png") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        {/* Mask */}
        <span className="mask opacity-4 bg-gradient-default-1" />
        {/* Header container */}
        <div className="text-center container">
          <Row className="text-center">
            <Col lg="12" md="12">
              <h1
                className="text-white about__h1"
                style={{ alignItems: "center" }}
              >
                discover the love <br />
                of riding and horses
              </h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="my-5 text-center container">
        We pride ourselves in providing trained school horses, qualified instructors, top-notch facilities and well-rounded camp 
        experience where kids can immerse themselves in the world of horses. Our residential horse-riding camps host up to 45 
        students between the ages of 9 to 17 years, and are held during the Summer, Diwali and Christmas holidays. All levels are 
        welcome, from kids who have never been on a horse to those that have been riding their entire lives.
      </div>
     <CampModule/>
      {/* typical day */}
      <TypicalDay/>
      {/* Bunk Life */}
      <BunkLife/>
      {/* Wellness and safety */}
      <WellnessAndSafety/>
      {/* Team */}
      {/* <div className="py-5 team-container text-center">
        <h1 className="text-white">meet the camp team</h1>
        <TeamSilder />
      </div> */}
      
      {/* food & nutrition */}
      <div className="my-5 text-center container">
        <h2 className='about__h2'>food & nutrition</h2>
        To help keep our campers happy, healthy, and energized throughout the
        day, our in-house kitchen prepares three nutritious and delicious meals
        daily. Under the watchful eye of our resident camp-mom and voluteers,
        all meals are served buffet-style and plates are full. We encourage our
        campers to try new things and ensure that even the pickiest eaters dont
        go hungry! To keep up with active campers, we offer cheat snacks in the
        evening. Our kitchen staff is well equipped to accomdate children with
        specific dietary needs.
        {/* <div className="mt-3">
          <Button color="primary">VIEW SAMPLE MENU</Button>
        </div> */}
      </div>
      {/* Other camp activities */}
      <OtherActivities/>
    </div>
  );
}
