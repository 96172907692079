import React, { useEffect, useState } from 'react';
import LevelSlider from './components/LevelSlider';
import SkillBadges from './components/SkillBadges';
import MemberAvtars from './components/MemberAvtars';
import UpcomingEvents from './components/UpcomingEvents';
export default function AllMembers({members}) {
const [selectedMemberId,setSelectedMemberId]=useState();
const handleSelectedMemberId=(id)=>{
  setSelectedMemberId(id)
}
useEffect(() => {
  if (Array.isArray(members) && members.length > 0) {
    setSelectedMemberId(members[0].member_id);
  }
}, [members]);
  return (
    <div className="py-0">
        <MemberAvtars members={members} handleSelectedMemberId={handleSelectedMemberId} selectedId={selectedMemberId}/>
        <SkillBadges selectedId={selectedMemberId} />
        <LevelSlider selectedId={selectedMemberId}/>
        <UpcomingEvents selectedId={selectedMemberId}/>
    </div>
  );
}
