
// reactstrap components
import { useFetch } from "hooks/useFetch";
import { useState, useEffect } from "react";
import { usePost } from 'hooks/usePost';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
    Card,
    CardHeader,
    CardFooter,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Input,
    FormGroup,
    CustomInput,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
  } from "reactstrap";
  import './viewStudents.css'
  import TagsInput from 'react-tagsinput';
  import 'react-tagsinput/react-tagsinput.css'; 
  import { Link } from "react-router-dom";
  import * as XLSX from 'xlsx';
  const ViewAllParents = () => {
    const [genderFilter, setGenderFilter] = useState("");
    const [ageFromFilter, setAgeFromFilter] = useState('');
    const [ageToFilter, setAgeToFilter] = useState('');
    const [schoolFilter, setSchoolFilter] = useState([]);
    const [cityFilter, setCityFilter] = useState('');
    const [levelFilter, setLevelFilter] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [isFilterModalOpen, setisFilterModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {postData:archiveRider, postError,resData:resRider} = usePost();

    // const [tags, setTags] = useState([]);
   
    const handleTagsChange = (schoolFilter) => {
      setSchoolFilter(schoolFilter);
    };
    const handleLevelChange = (levelFilter) => {
      setLevelFilter(levelFilter);
    };
    const handleReset=()=>{
      setGenderFilter('');
      setAgeFromFilter('');
      setAgeToFilter('');
      setSchoolFilter([]);
      setCityFilter('');
      setLevelFilter([]);
      setNameFilter('');
    }
    const { data: memberData } = useFetch(
      process.env.REACT_APP_NODE_SERVER_URL+`/api/all_members/?page=${page}&limit=${limit}` +
      `&gender=${genderFilter}&ageFrom=${ageFromFilter}&ageTo=${ageToFilter}` +
      `&schools=${schoolFilter}&cities=${cityFilter}&level=${levelFilter}&name=${nameFilter}`
    );

    const { data: allMembers } = useFetch(
      process.env.REACT_APP_NODE_SERVER_URL+`/api/export_all_members/`
    );

    const exportToExcel = () => {
      const filename = "riders.xlsx";
      const wsData = [["Rider Name", "Gender", "Age", "Parent Name", "Parent Phone Number", "Email", "Level", "School"]];
      let totalRevenue = 0;
      allMembers && allMembers.forEach(each => {
          wsData.push([`${each.first_name} ${each.last_name}`,
          each.gender,
          each.age,
          `${each.parent_first_name} ${each.parent_last_name}`,
          each.parent_phone_number,
          each.parent_email,
          each.level_name || 'N/A',
          each.school_name
        ]);
      });
      wsData.push(["", "", "", "", "", "", "",""]);

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `Riders`);
      XLSX.writeFile(wb, filename);
  };
  console.log(allMembers, ' hiee')

  const handleArchiveRider = (e,memeber_id) => {
      e.preventDefault();
      if (window.confirm("Are you sure you want to delete this Rider?")) {
        archiveRider(process.env.REACT_APP_DJANGO_SERVER_URL + "/api/deleteRider/" + memeber_id, {}, "DELETE");
      }

  }

  useEffect(()=>{
    if(resRider && resRider.status===200 ){
      console.log(resRider.status, ' rest')
      NotificationManager.success('Rider deleted Successfully!', '', 3000);
        window.location.reload();
    }
    if(postError){
      NotificationManager.error('Could not delete rider!'+postError, '', 3000);
  }
  },[resRider,postError]);
    
    return (
      <>
        <NotificationContainer/>
        {/* Page content */}
        <Container className="pt-8" fluid>
        <Modal isOpen={isFilterModalOpen} toggle={() => setisFilterModalOpen(!isFilterModalOpen)}>
        <ModalHeader toggle={() => setisFilterModalOpen(!isFilterModalOpen)}>Filters</ModalHeader>
        <ModalBody>
          <div className="row">
          <div className="col sm-12">
            <FormGroup>
                    <Input
                    type="text"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={nameFilter}
                    onChange={(e)=>setNameFilter(e.target.value)}
                    className="filter-form-control"
                    />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6"> 
              <FormGroup>
                    <Input
                    type="number"
                    id="fromAge"
                    placeholder="Fom Age"
                    name="fromAge"
                    min='0'
                    value={ageFromFilter}
                    onChange={(e)=>setAgeFromFilter(e.target.value)}
                    className="filter-form-control"
                    />
              </FormGroup>
            </div>
            <div className="col-sm-6">
            <FormGroup>
                    <Input
                    type="number"
                    id="toAge"
                    placeholder="To Age"
                    name="toAge"
                    min='0'
                    value={ageToFilter}
                    onChange={(e)=>setAgeToFilter(e.target.value)}
                    className="filter-form-control"
                    />
              </FormGroup>
            </div>
            <div className="col-sm-12">
            <FormGroup>
              <CustomInput
                type="select"
                id="relation"
                name="relation"
                value={genderFilter}
                onChange={(e)=>setGenderFilter(e.target.value)}
                className="filter-form-control"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </CustomInput>
            </FormGroup>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-sm-12">
            <TagsInput value={levelFilter} onChange={handleLevelChange} inputProps={{placeholder: "Add Levels"}} />
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-sm-12">
            <TagsInput value={schoolFilter} onChange={handleTagsChange} inputProps={{placeholder: "Add School"}} />
            </div>
          </div>
          <Button onClick={handleReset}>Reset</Button>
          <Button color='primary' onClick={()=>setisFilterModalOpen(false)}>Submit</Button>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
    </Modal>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
              <div className="row">
                  <div className="col-6">
                    <h3 className="mb-0">All Riders</h3>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                   <i onClick={()=>setisFilterModalOpen(true)} className="ni ni-align-center"></i> &nbsp;&nbsp;&nbsp;
                   <button onClick={exportToExcel} class="btn btn-primary btn-sm">Export</button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Rider Name</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th scope="col">Parent Name</th>
                    <th scope="col">Parent Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Level</th>
                    <th>School</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memberData && memberData.map((each)=>(<tr key={each.member_id}>
                    <th scope="row">
                      <Media className="align-items-center">
                        <Media>
                          <Link to={`/admin/rider-details/${each.member_id}`}>
                          <span className="mb-0 text-sm">
                            {each.first_name} {each.last_name}
                          </span>
                          </Link>
                        </Media>
                      </Media>
                    </th>
                    <td>{each.age}</td>
                    <td>{each.gender}</td>
                    <td>{each.parent_first_name} {each.parent_last_name}</td>
                    <td>{each.parent_phone_number}</td>
                    <td>
                    {each.parent_email}
                    </td>
                    {each.level_name ? (<td>{each.level_name}</td>) :  (<td>N/A</td>) }
                    <td>{each.school_name}</td>
                    <td className='text-center'><button onClick={e=> handleArchiveRider(e, each.member_id)} class="btn btn-danger btn-sm">Delete</button></td>
                  </tr>))}
                  {memberData && memberData.length===0 && <tr className="text-center">
                    <div className="text-center py-3">
                      <h3> No Records Found.</h3>
                    </div>
                  </tr>}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem >
                      <PaginationLink
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(page - 1);
                        }}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                         onClick={(e) => {
                          e.preventDefault();
                          setPage(page + 1);
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        </Container>
      </>
    );
  };
  
  export default ViewAllParents;
  