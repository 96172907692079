// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base size for h1 */
.about__h1 {
    font-size: 3rem; /* Default for larger screens */
}
.home__h1{
    font-size: 3.5rem;
}

/* Adjust for tablets */
@media (max-width: 768px) {
    .about__h1 {
        font-size: 2rem;
    }
    .home__h1{
        font-size: 2.5rem;
    }
}

/* Adjust for mobile devices */
@media (max-width: 480px) {
    .about__h1 {
        font-size: 1.7rem;
    }
    .home__h1{
        font-size: 2rem;
    }
}

/* Base size for h2 */
.about__h2 {
    font-size: 2rem; /* Default for larger screens */
}

/* Adjust for tablets */
@media (max-width: 768px) {
    .about__h2 {
        font-size: 1.75rem;
    }
}

/* Adjust for mobile devices */
@media (max-width: 480px) {
    .about__h2 {
        font-size: 1.5rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/spectator/About.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,eAAe,EAAE,+BAA+B;AACpD;AACA;IACI,iBAAiB;AACrB;;AAEA,uBAAuB;AACvB;IACI;QACI,eAAe;IACnB;IACA;QACI,iBAAiB;IACrB;AACJ;;AAEA,8BAA8B;AAC9B;IACI;QACI,iBAAiB;IACrB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA,qBAAqB;AACrB;IACI,eAAe,EAAE,+BAA+B;AACpD;;AAEA,uBAAuB;AACvB;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA,8BAA8B;AAC9B;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["/* Base size for h1 */\n.about__h1 {\n    font-size: 3rem; /* Default for larger screens */\n}\n.home__h1{\n    font-size: 3.5rem;\n}\n\n/* Adjust for tablets */\n@media (max-width: 768px) {\n    .about__h1 {\n        font-size: 2rem;\n    }\n    .home__h1{\n        font-size: 2.5rem;\n    }\n}\n\n/* Adjust for mobile devices */\n@media (max-width: 480px) {\n    .about__h1 {\n        font-size: 1.7rem;\n    }\n    .home__h1{\n        font-size: 2rem;\n    }\n}\n\n/* Base size for h2 */\n.about__h2 {\n    font-size: 2rem; /* Default for larger screens */\n}\n\n/* Adjust for tablets */\n@media (max-width: 768px) {\n    .about__h2 {\n        font-size: 1.75rem;\n    }\n}\n\n/* Adjust for mobile devices */\n@media (max-width: 480px) {\n    .about__h2 {\n        font-size: 1.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
