import React, { useEffect, useState } from 'react'
import success from "../../../assets/img/theme/success.png"
import "./OrderSuccess.css"
import {
    Button,
    Spinner
} from "reactstrap"
import { useFetch } from 'hooks/useFetch';
import { useAuthContext } from 'hooks/useAuthContext';
import { useParams,useLocation  } from 'react-router-dom';
import { usePost } from 'hooks/usePost';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function OrderPlacedSuccessfully() {
    const { user } = useAuthContext();
    const {orderId}=useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isCoupon = queryParams.get('iscoupon');
    const { data: cartData } = useFetch(process.env.REACT_APP_NODE_SERVER_URL+"/api/cart/" + user.user_id);
    const {data:orderDetails, isPending:orderPending} = useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/getAnOrder/'+orderId);
    const {postData:sendMail, resData:mailRes} = usePost()
    const [isLoading,setIsLoading]=useState(true);
    const { postData:postOrder, resData } = usePost();
    const {postData:redeemData, resData: redeemRes, postError:redeemErr, isPending:redeemPending}=usePost();
    const {postData:sendEmailData, resData: emailRest, postError:emailErr, isPending:emailPen}=usePost();
    const [cartTotal,setCartTotal] = useState(0)
    const [showStatus, setShowStatus] = useState(false);

    console.log(isCoupon)
    const handleOrder=()=>{
        if(!isCoupon && orderDetails.order_status==="PAID"){
          setShowStatus(true);
        } 
    
  }
  const handleCouponPayment = () =>{
    const payload = cartData.map((each)=>{
        return {
            parent: each.parent_id,
            course: each.course_id,
            member: each.member_id,
            for_level: each.next_level_id,
            gender: each.child_data.gender,
            course_price: each.course_price,
            payment_id: orderId
          }
    })
    postOrder(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/coupon_hook/",payload,"POST");  
  }




  useEffect(()=>{
    if(resData && resData.status===200){
      if(isCoupon){
          redeemData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/updateGiftCardRedeemed/",{
            gift_card:orderId
          }, "PUT") // updating the coupon status to reedem after the order placed
      }
      else{
        // handleSendMail();
      }
    }
  },[resData])



  useEffect(()=>{
    if( orderDetails){
        handleOrder();
    }
    if(cartData && isCoupon === "true"){
        handleCouponPayment();
    }
  },[cartData,orderDetails])



  useEffect(() => {
    if(redeemRes &&  redeemRes.status ===200){
      NotificationManager.success('Order Placed Successfully!', '', 3000);
      setShowStatus(true);
      sendEmailData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/sendEmailForCouponRedeem/",{
        parent_id:user.user_id
      }, "POST")
    }
    if(redeemErr){
      NotificationManager.error(redeemErr, '', 3000);
    }

  }, [redeemRes, redeemErr])

  return (
    <div className='py-8 vh-90'>
          {(orderPending || redeemPending ) &&  (<div className='text-center'><Spinner color="primary" style={{
              height: '3rem',
              width: '3rem'
            }}>
              
            </Spinner>
            <div className="text-center mt-3">
              <h2>Payment processing, please do not refresh!</h2>
            </div>        
        </div>)}
        {(!orderPending || !redeemPending ) && <div className="container pt-8">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <img src={success} alt="" width="120" className="img-pulse"/>
                </div>
                <div className="col-sm-12 text-center mt-5">
                    <h1>Thank you,Order Placed Successfully!</h1>
                    <Button color="primary" href="/parent/index">Explore</Button>
                </div>
            </div>
        </div>}
    </div>
  )
}
