import React from 'react'
import img1 from "../../../assets/img/spectators/wellness1.jpg"
import img2 from "../../../assets/img/spectators/wellness2.jpg";
import img3 from "../../../assets/img/spectators/wellness3.jpg";
export default function WellnessAndSafety() {
  return (
    <div className="py-5 wellness-container">
        <div className="container text-center">
          <h1 className="mb-4 about__h2">wellness and safety</h1>
          {/* First card */}
          <div class="card mb-5 rounded-0 shadow-none">
            <div class="row g-0 rounded-0">
              <div className="col-md-6 rounded-0">
                <img
                  src={img1}
                  class="img-fluid rounded-start rounded-0"
                  alt="..."
                />
              </div>
              <div class="col-md-6  d-flex align-items-center rounded-0">
                <div className="card-body">
                  <p className="card-text text-left">
                    Campers physical and mental health is important for a
                    successful camp experience. Resident camp-mom and voluteers
                    are responsible for direct supervision, care and guidance
                    for all our young campers. To further help us clear the
                    minds of our campers and have them focus on making memories
                    and building friendships and restrict access to cell phones
                    to just 1 hour a day, post lunch.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* second card */}
          <div class="card mb-5 rounded-0 shadow-none">
            <div class="row g-0 rounded-0">
              <div class="col-md-6  d-flex align-items-center rounded-0">
                <div className="card-body">
                  <p className="card-text text-left">
                    During every riding lesson and other activities all
                    precautionary safety measures are in place and followed
                    strictly. Our horses are selected on the basis of their
                    temperament and are further trained to be ‘rider friendly’.
                    All riders are given horses suited to their level only and
                    wearing riding helmets is compulsory. In addition our
                    instructors have undergone a first aid training course
                  </p>
                </div>
              </div>
              <div className="col-md-6 rounded-0">
                <img
                  src={img2}
                  class="img-fluid rounded-start rounded-0"
                  alt="..."
                />
              </div>
            </div>
          </div>
          {/* Third card */}
          <div class="card mb-3 rounded-0 shadow-none">
            <div class="row g-0 rounded-0">
              <div className="col-md-6 rounded-0">
                <img
                  src={img3}
                  class="img-fluid rounded-start rounded-0"
                  alt="..."
                />
              </div>
              <div class="col-md-6  d-flex align-items-center rounded-0">
                <div className="card-body">
                  <p className="card-text text-left">
                    In addition, our camp mom looks after campers' health. She
                    is responsible for any first aid, the organisation and
                    distribution of medicines, liaising with local doctors and
                    hospitals as necessary. The Sparsh Hospital is within a 5
                    min driving distance. In the rare event of illness or
                    accident, parents are called at the first opportunity and
                    updated with full information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
