import React from 'react'
import CampModules from './CampModulesList'

export default function CampModule() {
  return (
     <div className="camp-module-section py-5">
        <div className="container text-center">
          <h2 className='about__h2'>the camp module</h2>
          <p>
            Japalouppe strongly believes that when developing the equestrians of
            tomorrow, safe horsemanship and care of the animal are just as
            important to teach as mounted skills.
          </p>
          <CampModules />
        </div>
      </div>

  )
}
