import React, { useEffect, useState } from 'react'
import success from "../../../assets/img/theme/success.png"
import "./OrderSuccess.css"
import {
    Button
} from "reactstrap"
import { useFetch } from 'hooks/useFetch';
import { useAuthContext } from 'hooks/useAuthContext';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { usePost } from 'hooks/usePost';
export default function RRPOrderSuccess() {
    const { user } = useAuthContext();
    const {orderId,cartId}=useParams();
    const {data:orderDetails}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/getAnOrder/'+orderId);
    const {data:cartData}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/fetch-rrp-cart/'+cartId);
    const {postData:sendMail, resData:emailRes } = usePost();
    
    const [isLoading,setIsLoading]=useState(true);
    const { postData:postOrder, resData } = usePost();
    const [showStatus, setShowStatus] = useState(false);

    const handleOrder=()=>{
        if(orderDetails.order_status==="PAID"){
                setShowStatus(true);
            }  
    }

    useEffect(() => {
        if(orderDetails ){
            handleOrder()
        }
    }, [orderDetails])
    
    
return (
    <div>
        {!showStatus && (<div><Loader/>
            <div className="text-center">
                Please do not refersh the page.
            </div>        
        </div>)}
        {showStatus && <div className="container pt-8">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <img src={success} alt="" width="120" className="img-pulse"/>
                </div>
                <div className="col-sm-12 text-center mt-5">
                    <h1>Thank you,Order Placed Successfully!</h1>
                    <Button color="primary" href="/parent/index">Explore</Button>
                </div>
            </div>
        </div>}
    </div>
  )
}
