// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .static-timeline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  
  .timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .step-active {
    width: 30px;
    height: 30px;
    background-color: #1cc733;
    margin-bottom: 5px;
  }
  .step-inactive {
    width: 30px;
    height: 30px;
    background-color: #c3c3c3;
    margin-bottom: 5px;
  }
  
  
  .level-connector {
    width: 20px;
    height: 2px;
    background-color: #4b4b4b;
  }
   */`, "",{"version":3,"sources":["webpack://./src/pages/Parents/AddChild.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IAgCI","sourcesContent":["/* .static-timeline {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 20px;\n  }\n  \n  .timeline-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .step-active {\n    width: 30px;\n    height: 30px;\n    background-color: #1cc733;\n    margin-bottom: 5px;\n  }\n  .step-inactive {\n    width: 30px;\n    height: 30px;\n    background-color: #c3c3c3;\n    margin-bottom: 5px;\n  }\n  \n  \n  .level-connector {\n    width: 20px;\n    height: 2px;\n    background-color: #4b4b4b;\n  }\n   */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
