// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .events-body {
    position: relative;
    z-index: 2; /* Ensure the card content is above the overlay */
  }
  .card{
    border-radius: 12px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Parents/components/UpcomingEvents.css"],"names":[],"mappings":";EACE;IACE,kBAAkB;IAClB,UAAU,EAAE,iDAAiD;EAC/D;EACA;IACE,mBAAmB;EACrB","sourcesContent":["\n  .events-body {\n    position: relative;\n    z-index: 2; /* Ensure the card content is above the overlay */\n  }\n  .card{\n    border-radius: 12px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
