import React, { useState,useEffect } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Row,
  Col,
  Card,
} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useAuthContext } from 'hooks/useAuthContext';
import "./AddChild.css"

export default function AddChild() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [school, setSchool] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [rider_contact, setRider_contact] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [passport, setPassport] = useState('');
  const [allergies, setAllergies] = useState('');
  const [city, setCity] = useState('');
  const [parent2Name,setParent2Name]=useState('');
  const [parent2Email,setParent2Email]=useState('');
  const [parent2Contact,setParent2Contact]=useState('');
  const [emergencyContactName,setEmergencyContactName]=useState('');
  const [emergencyContactNumber,setEmergencyContactNumber]=useState('');
  const [mealSpecifications, setMealSpecifications] = useState('');
  const [specialInstructions, setSpecialInstructions] = useState('N/A');
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [step,setStep]=useState(1);
  const [relation,setRelation]=useState('');
  const [allergyBool,setAllgeryBool]=useState('no');
  const [medicationBool,setMedicationBool]=useState('no');
  const [medications,setMedications]=useState('');
  const [isNotIndian, setIsNotIndian] = useState(false)
  const [ridersWeight, setRidersWeight] = useState('');
  const {user}=useAuthContext();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'relation':
      setRelation(value);
      break;
      case 'lastName':
        setLastName(value);
        break;
      case 'school':
        setSchool(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'age':
        setAge(value);
        break;
      case 'rider_contact':
        setRider_contact(value);
        break;
      case 'isNotIndian':
        setIsNotIndian(event.target.checked);
          break;
      case 'aadhar':
        setAadhar(value);
        break;
      case 'passport':
          setPassport(value);
          break;
      case 'city':
      setCity(value);
      break;
      case 'allergies':
        setAllergies(value);
        break;
      case 'mealSpecifications':
        setMealSpecifications(value);
        break;
      case 'specialInstructions':
        setSpecialInstructions(value);
        break;
      case 'uploadedPhoto':
        setUploadedPhoto(event.target.files[0])
        break;
      case 'ridersWeight':
        setRidersWeight(value)
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validation checks (omitting for brevity)
  
    const formData = new FormData();
  
    formData.append('parent', user.user_id);
    formData.append('parent2_name', parent2Name);
    formData.append('parent2_email', parent2Email);
    formData.append('parent2_contact', parent2Contact);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('relation', Number(relation));
    formData.append('school_name', school);
    formData.append('gender', gender);
    formData.append('aadhar_no', aadhar);
    formData.append('passport_no', passport);
    formData.append('allergies', 'N/A');
    formData.append('meal_specifications', 'Veg');
    formData.append('riders_weight', ridersWeight);
    formData.append('special_instructions', specialInstructions);
    if (uploadedPhoto && ['image/jpeg','image/jpg', 'image/png', 'image/webp'].includes(uploadedPhoto.type)) {
      formData.append('photo_url', uploadedPhoto);
    } else {
      NotificationManager.error('Please upload a JPEG, PNG, or WebP image.', '', 3000);
      return;
    }
    if(aadhar ==='' && isNotIndian===false){
      NotificationManager.error('Please enter aadhar card number.', '', 3000);
      return;
    }
    if(passport ==='' && isNotIndian===true){
      NotificationManager.error('Please enter passport number.', '', 3000);
      return;
    }
    if(ridersWeight ===''){
      NotificationManager.error('Please mention riders weight.', '', 3000);
      return;
    }
    if(ridersWeight>=80){
      NotificationManager.error('Riders weight should be below 80Kgs.', '', 3000);
      return;
    }
    if(relation!=="2" && rider_contact===""){
      NotificationManager.error('Please give riders contact.', '', 3000);
      return;
    }
    formData.append('riders_contact', Number(rider_contact));
    formData.append('photo_url', uploadedPhoto); // Assuming uploadedPhoto is the File object
    formData.append('city', city);
    formData.append('emergency_contact_name', emergencyContactName);
    formData.append('emergency_contact_number', emergencyContactNumber);
    formData.append('created_by', user.first_name);
    formData.append('updated_by', user.first_name);
    formData.append('age', age);
    
    try {
      const res = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL + "/api/addMember/", {
        method: "POST",
        body: formData,
      });
  
      if (!res.ok) {
        throw new Error('Unable To Fetch the response!')
      }
  
      const data = await res.json();
      if (data.status !== 200) {
        if (data.Error) {
          throw new Error(data.Error);
        } else {
          throw new Error("Oops! something went wrong.")
        }
      }
  
      console.log(data, ' here is the data');
      NotificationManager.success('Member Added Successfully!', '', 3000);
      setInterval(() => {
        window.location.href = "/parent/index";
      }, 1000);
    } catch (err) {
      NotificationManager.error(err.message, '', 3000);
      return;
    }
  }

  useEffect(() => {
    if(relation){
      setParent2Contact('');
      setParent2Email('');
      setParent2Name('');
      setSchool('');
    }

    
  }, [relation]);
  
  
  return (
    <div className="container py-7">
      <NotificationContainer/>
      <div className="row">

     <div className="col-sm-1"></div>
     <div className="col-sm-10 p-4">
      <Card className='p-5 bg-white'>
      <Form onSubmit={handleSubmit}>
        <h4 className="text-muted mb-4">Add a Rider/Camper</h4>
        <div className="static-timeline d-flex justify-content-between mb-2">
          <div className="timeline-item ">
            <div className="avatar step-active avatar-sm rounded-circle cursor-pointer mr-2" style={{cursor:'pointer'}} onClick={()=>setStep(1)}>1</div>
            Rider/Camper Details
          </div>
          <div className="timeline-item">
            <div 
            className={step===2 || step===3 ? "avatar avatar-sm rounded-circle step-active cursor-pointer mr-2" :"avatar avatar-sm rounded-circle step-inactive cursor-pointer mr-2"} 
            style={{cursor:'pointer'}}  
            onClick={()=>setStep(2)}>2</div>
            Contact Details
          </div>
          <div className="timeline-item ">
            <div className={step===3 ? "avatar avatar-sm rounded-circle step-active cursor-pointer mr-2" :"avatar avatar-sm rounded-circle step-inactive cursor-pointer mr-2"} style={{cursor:'pointer'}} onClick={()=>setStep(3)}>3</div>
            Questionnaire
          </div>
      </div>
      { step===1 && (<div>
      <Row> 
          <Col sm="4">
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="relation">Relation</Label>
              <CustomInput
                type="select"
                id="relation"
                name="relation"
                value={relation}
                onChange={handleInputChange}
                className="form-control-alternative"
              >
                <option value="">Select Relationship</option>
                <option value="1">Myself</option>
                <option value="2">Child</option>
                <option value="3">Spouse</option>
                <option value="4">Adult</option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className='mb-0 mt-0'>
              <Label for="isIndian" className='mb-0 '>Citizenship</Label>
              <div>
                <small>Is Rider not a indian citizen? - Please check &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </small>
                <Input
                  type="checkbox"
                  id="isIndian"
                  name="isIndian"
                  checked={isNotIndian}
                  onChange={(e)=>setIsNotIndian(e.target.checked)}
                  className="form-control-alternative bg-dark p-2" 
                />
              </div>
              
            </FormGroup>
          </Col>
            { !isNotIndian && <Col sm="6">
            <FormGroup>
              <Label for="aadhar">Aadhar Number</Label>
              <Input
                type="text"
                id="aadhar"
                name="aadhar"
                value={aadhar}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>}
          { isNotIndian && <Col sm="6">
            <FormGroup>
              <Label for="passport">Passport Number</Label>
              <Input
                type="text"
                id="passport"
                name="passport"
                value={passport}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>}
        </Row>
        <Row>
          { (relation==="2" || relation==="1") && 
          <Col sm="6">
            <FormGroup>
              <Label for="school">School</Label>
              <Input
                type="text"
                id="school"
                name="school"
                value={school}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>}
          <Col sm={(relation === "2" || relation === "1") ? "6" : "4"}>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                id="city"
                name="city"
                value={city}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="gender">Gender</Label>
              <CustomInput
                type="select"
                id="gender"
                name="gender"
                value={gender}
                onChange={handleInputChange}
                className="form-control-alternative"
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="age">Age</Label>
              <Input
                type="text"
                id="age"
                name="age"
                value={age}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="rider_contact">Rider Contact</Label>
              <Input
                type="text"
                id="rider_contact"
                name="rider_contact"
                value={rider_contact}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>
          <FormGroup>
          <Label for="uploadedPhoto">Upload Photo</Label>
          <Input
            type="file"
            id="uploadedPhoto"
            name="uploadedPhoto"
            onChange={handleInputChange}
          />
        </FormGroup>
        </Row>
        <Button onClick={()=>setStep(2)} color="primary">Next</Button>
        </div>)}
        {step===2 &&(
          <div>
            { relation==="2" && 
            <Row>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="parent2name">Parent 2 Name</Label>
                    <Input
                      type="text"
                      id="parent2name"
                      name="parent2name"
                      value={parent2Name}
                      onChange={(e)=>setParent2Name(e.target.value)}
                      className="form-control-alternative"
                    />
                </FormGroup>          
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="parent2email">Parent 2 Email</Label>
                    <Input
                      type="text"
                      id="parent2email"
                      name="parent2email"
                      value={parent2Email}
                      onChange={(e)=>setParent2Email(e.target.value)}
                      className="form-control-alternative"
                    />
                </FormGroup>          
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="parent2contact">Parent 2 Contact</Label>
                    <Input
                      type="text"
                      id="parent2contact"
                      name="parent2contact"
                      value={parent2Contact}
                      onChange={(e)=>setParent2Contact(e.target.value)}
                      className="form-control-alternative"
                    />
                </FormGroup>          
              </div>
            </Row>}
            <Row>
              <div className="col-sm-6">
                <FormGroup>
                  <Label for="emergencycontactname">Emergency Contact Name</Label>
                    <Input
                      type="text"
                      id="emergencycontactname"
                      name="emergencycontactname"
                      value={emergencyContactName}
                      onChange={(e)=>setEmergencyContactName(e.target.value)}
                      className="form-control-alternative"
                    />
                </FormGroup>          
              </div>
                <div className="col-lg-6">
                <FormGroup>
                  <Label for="emergencycontactnumber">Emergency Contact Number</Label>
                    <Input
                      type="text"
                      id="emergencycontactnumber"
                      name="emergencycontactnumber"
                      value={emergencyContactNumber}
                      onChange={(e)=>setEmergencyContactNumber(e.target.value)}
                      className="form-control-alternative"
                    />
                </FormGroup>       
                </div>
              </Row>
              <div>
              <Button onClick={()=>setStep(1)} color="primary">Previous</Button>
              <Button onClick={()=>setStep(3)} color="primary">Next</Button>
              </div>
          </div>
        )}
       { step===3 && (<div>
        <Row>
        <Col sm="6">
            <FormGroup>
              <Label for="gender">Does the rider have any allergies?</Label>
              <CustomInput
                type="select"
                id="allergies"
                name="allergies"
                value={allergyBool}
                onChange={(e)=>setAllgeryBool(e.target.value)}
                className="form-control-alternative"
              >
                <option value="">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </CustomInput>
            </FormGroup>
        </Col>
          {allergyBool==="yes" && <Col sm="6">
            <FormGroup>
              <Label for="allergies">Please Specify Allergies</Label>
              <Input
                type="text"
                id="allergies"
                name="allergies"
                value={allergies}
                onChange={handleInputChange}
                className="form-control-alternative"
              />
            </FormGroup>
          </Col>}
        </Row>
        <Row>
          <Col sm="6">
            <FormGroup>
              <Label for="medications">Is the rider on any medication?</Label>
              <CustomInput
                    type="select"
                    id="medication"
                    name="medication"
                    value={medicationBool}
                    onChange={(e)=>setMedicationBool(e.target.value)}
                    className="form-control-alternative"
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </CustomInput>
            </FormGroup>
          </Col>
          <Col sm="6">
              {medicationBool === 'yes' && 
              <FormGroup>
                <Label for="medications">Please Specify Medications</Label>
                <Input
                  type="text"
                  id="medications"
                  name="medications"
                  value={medications}
                  onChange={(e)=>setMedications(e.target.value)}
                  className="form-control-alternative"
                />
              </FormGroup>}
          </Col>
        </Row>
        <Col className='px-0' sm="12">
            <FormGroup>
                <Label for="emergencycontactnumber">Rider's weight <small>(weigtht should be below 80Kgs)</small></Label>
                  <Input
                    type="text"
                    id="ridersweight"
                    name="ridersweight"
                    placeholder='enter weight in Kgs'
                    value={ridersWeight}
                    onChange={(e)=>setRidersWeight(e.target.value)}
                    className="form-control-alternative"
                  />
            </FormGroup> 
        </Col>
        <Button onClick={()=>setStep(2)} color="primary">Previous</Button>
        <Button type="submit" color="primary">
         Submit
       </Button>
        </div>
        )}
      </Form>
      </Card>
      </div>
      <div className="col-sm-1"></div>
      </div>
    </div>
  );
}
