// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%; /* Ensure the slider item takes up the full height */
  }
  
  .slider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Parents/ParentProfile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY,EAAE,oDAAoD;EACpE;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".slider-item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    height: 100%; /* Ensure the slider item takes up the full height */\n  }\n  \n  .slider-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
