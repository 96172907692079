import React from 'react'
import Slider from 'react-slick';
import './Slider.css'; // You'll need to create a CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useFetch } from 'hooks/useFetch';
export default function LevelSlider({selectedId}) {
  const {data} = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getMember/'+selectedId);
  const {data:levels} = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getMasterLevelView/");
    const totalLevels = 10; // Total number of levels
    const levelsPerPage = 3; // Number of levels to display per page
  
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: levelsPerPage,
      slidesToScroll: levelsPerPage,
    };
  
    const isLevelCompleted = (level) => {
      if(data){
        return level < data.memberDetails.current_level_id; 
      }
    };
  return (
    <div  className="container pt-3">
    <h2>Levels</h2>
    <div className="level-slider-container text-center  position-relative">
    <Slider {...settings}>
        {levels && levels.map((level) => (
          <div key={level.level_id}>
            <div>
              {isLevelCompleted(level.level_id) ? (
                <i className="ni ni-check-bold avatar avatar-sm rounded-circle trophy"></i>
              ) : (
                  <div className='avatar avatar-sm rounded-circle lock'> <FontAwesomeIcon icon={faLock} className="locked-icon" /></div>
              )}
            </div>
            {level.level_name}
            {level.level_id < totalLevels && ( /* Add this condition to prevent a line after the last level */
              <div className="level-connector"></div>
            )}
          </div>
        ))}
      </Slider>
  </div>
  </div>
  )
}
