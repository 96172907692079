// reactstrap components
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   Container,
   Row,
   Col,
   UncontrolledTooltip
   } from "reactstrap";
   // core components
   import { Link, useParams } from "react-router-dom";
   import { useFetch } from "hooks/useFetch";
   import Loader from "components/Loader";
   import { NotificationContainer, NotificationManager } from 'react-notifications';
   import localImage from "../assets/img/theme/counter-bg.jpg"
import { usePost } from "hooks/usePost";
   const RiderDetails = () => {
   const {riderId}=useParams();
   const {data:memberData,isPending}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getMember/"+riderId);
   const {data:masterLevel,isPending:levelPending}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getMasterLevelView/");
   const {postData, resData, postError, isPending:updatePending}=usePost();
   const {data:riderHistory, isPending:historyPending}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/riderCourseHistory/"+riderId);

   const {data:badgeData,isPending:badgePending}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getAllBadges/");

   const [firstName, setFirstName] = useState(null);
   const [lastName, setLastName] = useState(null);
   const [schoolName, setSchoolName] = useState(null);
   const [city, setCity] = useState(null);
   const [level, setLevel] = useState(null);
   const [badge, setBadge] = useState(null);
   const [selectGender, setSelectGender] = useState(null);
   const [age, setAge] = useState(null);
   const navigate = useNavigate();
   const [isEdit,setIsEdit]=useState(false);
   const [aadharCard, setAadharCard] = useState(null)
   const [passportNumber, setPassportNumber] = useState(null)

   const handleUpdateRider = async (e) =>{
      e.preventDefault();
      if(firstName===""){
         NotificationManager.error('Please update first name / dont leave empty', '', 1500);
      }
      if(lastName===""){
         NotificationManager.error('Please update last name / dont leave empty', '', 1500);
      }
      if(schoolName===""){
         NotificationManager.error('Please update school name / dont leave empty', '', 1500);
      }
      if(city===""){
         NotificationManager.error('Please update city / dont leave empty', '', 1500);
      }
      if(age===""){
         NotificationManager.error('Please update age / dont leave empty', '', 1500);
      }
      const formData = new FormData();
      formData.append("first_name", firstName !== null ? firstName : memberData.memberDetails.first_name);
      formData.append("last_name", lastName !== null ? lastName : memberData.memberDetails.last_name);
      formData.append("school_name", schoolName !== null ? schoolName : memberData.memberDetails.school_name);
      formData.append("city", city !== null ? city : memberData.memberDetails.city);
      formData.append("age", age !== null ? age : memberData.memberDetails.age);
      formData.append("level", level !== null ? level : memberData.memberDetails.current_level_id);
      formData.append("riding_badge", badge !== null ? Number(badge) : Number(memberData.memberDetails.riding_badge));
      formData.append("member_id", memberData.memberDetails.member_id);
      formData.append("parent", memberData.memberDetails.parent);

      formData.append("gender", selectGender !== null ? selectGender : memberData.memberDetails.gender);
      formData.append("meal_specifications", memberData.memberDetails.meal_specifications);
      formData.append("allergies", memberData.memberDetails.allergies);
      formData.append("special_instructions", memberData.memberDetails.special_instructions);
      formData.append("relation", memberData.memberDetails.relation);
      formData.append("aadhar_no", aadharCard !== null ? aadharCard : memberData.memberDetails.aadhar_no);
      formData.append("passport_no", passportNumber !== null ? passportNumber : memberData.memberDetails.passport_no);

      try {
         const res = await fetch(`${process.env.REACT_APP_DJANGO_SERVER_URL}/api/updateMember/${memberData.memberDetails.member_id}`, {
            method: "PUT",
            body: formData,
         });
      
         if (!res.ok) {
            throw new Error('Unable To Fetch the response!')
         }
      
         const data = await res.json();
         if (data.status !== 200) {
            if (data.Error) {
               throw new Error(data.Error);
            } else {
               throw new Error("Oops! something went wrong.")
            }
         }
      
         console.log(data, ' here is the data');
         NotificationManager.success('Member Updated Successfully!', '', 3000);
         setInterval(() => window.location.href = '/admin/view-all-students', 1111);     
      }
      catch (err) {
         NotificationManager.error(err.message, '', 3000);
         return;
      }

   }
   const genders = [
      { id: 1, name: 'male' },
      { id: 2, name: 'female' }
   ];
   return (
   <>
   {isPending && 
   <Loader/>
   }
   {memberData && 
   <div>
      <NotificationContainer/>
      <div
      className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style={{
      minHeight: "400px",
      backgroundImage:
      "url(" + require("../assets/img/theme/profile-cover.jpg") + ")",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      }}
      >
      {/* Mask */}
      <span className="mask bg-gradient-default opacity-8" />
      {/* Header container */}
      <Container className="d-flex align-items-center" fluid>
         <Row>
            <Col lg="12" md="10">
            <h1 className="display-2 text-white">{memberData.memberDetails.first_name} {memberData.memberDetails.last_name}</h1>
            {!isEdit && <Button
               color="info"
               href="#pablo"
               onClick={() => setIsEdit(true)}
            >
            Edit profile
            </Button>}
            {isEdit && <Button
               color="info"
               href="#pablo"
               onClick={() => setIsEdit(true)}
               disabled = {isEdit}
            >
            Edit profile
            </Button>}
            </Col>
         </Row>
      </Container>
   </div>
   {/* Page content */}
   <Container className="mt--7" fluid>
      <Row>
         <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
         <Card className="card-profile shadow">
            <Row className="justify-content-center">
               <Col className="order-lg-2" lg="3">
               <div className="card-profile-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img
                  alt="profile-img"
                  className="rounded-circle profile"
                  style={{width:"110px !important",'height':'110px !important'}}
                  src={process.env.REACT_APP_DJANGO_SERVER_URL+"/api"+memberData.memberDetails.photo_url}
                  />
                  </a>
               </div>
               </Col>
            </Row>
            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
               <div className="d-flex justify-content-between">
                  {/* <Button
                     className="mr-4"
                     color="info"
                     href="#pablo"
                     onClick={(e) => e.preventDefault()}
                  size="sm"
                  >
                  Connect
                  </Button>
                  <Button
                     className="float-right"
                     color="default"
                     href="#pablo"
                     onClick={(e) => e.preventDefault()}
                  size="sm"
                  >
                  Message
                  </Button> */}
               </div>
            </CardHeader>
            <CardBody className="pt-0 pt-md-4">
               <div className="text-center mt-5">
                  <h3>
                     {memberData.memberDetails.first_name}  {memberData.memberDetails.last_name}
                     <span className="font-weight-light">, {memberData.memberDetails.age}</span>
                  </h3>
                  <div className="h5 font-weight-300">
                     <i className="ni location_pin mr-2" />
                     {memberData.memberDetails.city}
                  </div>
                  <div className="h3 mt-4">
                     <i className="ni business_briefcase-24 mr-2" />
                     {memberData.memberDetails.school_name}
                  </div>
                  <hr className="my-4" />
                  <h4>
                     Allergies: {(memberData.memberDetails.allergies!=="" || memberData.memberDetails.allergies!==null)?memberData.memberDetails.allergies:'N/A'}
                  </h4>
                  {/* <h4>
                     Meal Specifications: {memberData.memberDetails.meal_specifications}
                  </h4>
                  <h4>
                     Special Instructions: {memberData.memberDetails.special_instructions}
                  </h4> */}
                  <hr />
                  <h6>
                  Relation with account holder: {
                     memberData.memberDetails.relation === 1
                        ? "Self"
                        : memberData.memberDetails.relation === 2
                        ? "Rider/Child"
                        : memberData.memberDetails.relation === 3
                        ? "Spouse"
                        : "Adult"
                  }
                  </h6>
                  {/* <h4>Other Related Members</h4>
                  <div className="avatar-group">
                     {memberData && memberData.siblingMemberInfo && memberData.siblingMemberInfo.map((each)=>( <React.Fragment key={each.member_id}>
                     <a
                        className="avatar avatar-lg"
                        href={`/admin/rider-details/${each.member_id}`}
                        id={`tooltip${each.member_id}`}
                        >
                     <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../assets/img/theme/team-2-800x800.jpg")}
                        />
                     </a>
                     <UncontrolledTooltip
                        delay={0}
                        target={`tooltip${each.member_id}`}
                        >
                        {each.first_name}  {each.last_name}
                     </UncontrolledTooltip>
                     </React.Fragment>))}
                  </div> */}
               </div>
            </CardBody>
         </Card>
         </Col>
         <Col className="order-xl-1" xl="8">
         <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
               <Row className="align-items-center">
                  <Col xs="8">
                  <h3 className="mb-0">Rider Profile</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                  {/* <Button
                     color="primary"
                     href="#pablo"
                     onClick={(e) => e.preventDefault()}
                  size="sm"
                  >
                  Settings
                  </Button> */}
                  </Col>
               </Row>
            </CardHeader>
            <CardBody>
               <Form onSubmit={handleUpdateRider} encType="multipart/form-data">
                  <h6 className="heading-small text-muted mb-4">
                     Member information
                  </h6>
                  <div className="pl-lg-4">
                     <Row>
                        <Col lg="4">
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              >
                           First name
                           </label>
                           <Input
                              className="form-control-alternative"
                              defaultValue={memberData.memberDetails.first_name}
                              onChange={(e)=> setFirstName(e.target.value)}
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              readOnly={!isEdit}
                              />
                        </FormGroup>
                        </Col>
                        <Col lg="4">
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                              >
                           Last name
                           </label>
                           <Input
                              className="form-control-alternative"
                              defaultValue={memberData.memberDetails.last_name}
                              readOnly={!isEdit}
                              onChange={(e)=> setLastName(e.target.value)}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              />
                        </FormGroup>
                        </Col>
                           <Col lg="4">
                                 <FormGroup>
                                    <label
                                          className="form-control-label"
                                          htmlFor="gender"
                                          >
                                    Gender
                                    </label>
                                    <Input
                                          className="form-control-alternative"
                                          defaultValue={memberData.memberDetails.gender}
                                          onChange={(e)=> setSelectGender(e.target.value)}
                                          readOnly={!isEdit}
                                          id="gender"
                                          type="select"
                                          name = "selectGender"
                                          >
                                          <option value="">Select an gender option </option>
                                          {  genders && 
                                             genders.map((genders)=>
                                             <option key={genders.id} value={genders.name}>{genders.name}</option>
                                          )}
                                    </Input>
                                 </FormGroup>
                              </Col>
                     </Row>
                     <Row>
                        <Col lg="6">
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="input-school-name"
                              >
                           School Name
                           </label>
                           <Input
                              className="form-control-alternative"
                              defaultValue={memberData.memberDetails.school_name}
                              readOnly={!isEdit}
                              onChange={(e)=> setSchoolName(e.target.value)}
                              id="input-school-name"
                              type="text"
                              />
                        </FormGroup>
                        </Col>
                        <Col lg="6">
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="city"
                              >
                           City
                           </label>
                           <Input
                              className="form-control-alternative"
                              defaultValue={memberData.memberDetails.city}
                              onChange={(e)=> setCity(e.target.value)}
                              readOnly={!isEdit}
                              id="city"
                              type="text"
                              />
                        </FormGroup>
                        </Col>
                     </Row>
                     <Row>
                        <Col lg="6">
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="Level"
                              >
                           Level
                           </label>
                              <Input
                                 className="form-control-alternative"
                                 defaultValue={memberData.memberDetails.current_level_id}
                                 onChange={(e)=> setLevel(e.target.value)}
                                 readOnly={!isEdit}
                                 id="Level"
                                 type="select"
                                 name = "selectLevel"
                                 >
                                 <option value="">Select an option </option>
                                 {  masterLevel && 
                                    masterLevel.map((level)=>
                                    <option key={level.level_id} value={level.level_id}>{level.level_name}</option>
                                 )}
                              </Input>
                        </FormGroup>
                        </Col>
                        <Col lg="6">
                           {memberData.memberDetails.aadhar_no!=='' && memberData.memberDetails.passport_no==='' && <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="aadhar-number"
                                 >
                              Aadhar number
                              </label>
                              <Input
                                 className="form-control-alternative"
                                 defaultValue={memberData.memberDetails.aadhar_no!==''?memberData.memberDetails.aadhar_no:'N/A'}
                                 onChange={(e)=> setAadharCard(e.target.value)}
                                 readOnly={!isEdit}
                                 id="aadhar-number"
                                 type="text"
                                 />
                           </FormGroup>}
                           {memberData.memberDetails.passport_no!=='' && memberData.memberDetails.aadhar_no==='' && <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="passport-number"
                                 >
                              Passport number
                              </label>
                              <Input
                                 className="form-control-alternative"
                                 defaultValue={memberData.memberDetails.passport_no!==''?memberData.memberDetails.passport_no:'N/A'}
                                 onChange={(e)=> setPassportNumber(e.target.value)}
                                 readOnly={!isEdit}
                                 id="passport-number"
                                 type="text"
                                 />
                           </FormGroup>}
                        </Col>
                     </Row>
                     <Row>
                        <Col lg="6">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="rider-age"
                                 >
                              Rider's Age
                              </label>
                              <Input
                                 className="form-control-alternative"
                                 defaultValue={memberData.memberDetails.age!==''?memberData.memberDetails.age:'Age not specified'}
                                 onChange={(e)=> setAge(e.target.value)}
                                 readOnly={!isEdit}
                                 id="rider-age"
                                 type="text"
                                 />
                           </FormGroup>
                        </Col>
                        <Col lg="6">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="rider-age"
                                 >
                              Rider's Badge
                              </label>
                              <Input
                                 className="form-control-alternative"
                                 defaultValue={memberData.memberDetails.riding_badge}
                                 onChange={(e)=> setBadge(e.target.value)}
                                 readOnly={!isEdit}
                                 id="badge"
                                 type="select"
                                 name = "selectBadge"
                                 >
                                 <option value="">Select an option </option>
                                 {  badgeData && 
                                    badgeData.Message.map((badge)=>
                                    <option key={badge.badge_id} value={badge.badge_id}>{badge.badge_name}</option>
                                 )}
                              </Input>
                           </FormGroup>
                        </Col>
                     </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  {/* <h6 className="heading-small text-muted mb-4">
                     Contact information
                  </h6> */}
                  {/* <div className="pl-lg-4">
                     <Row>
                        <Col md="6">
                        {memberData.parentInfo &&
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="parent-first-name"
                              >
                           Parent first name
                           </label>
                           <Input
                              className="form-control-alternative"
                              value={memberData.parentInfo.first_name}
                              readOnly
                              id="parent-first-name"
                              placeholder="Home Address"
                              type="text"
                              />
                        </FormGroup>
                        }
                        </Col>
                        <Col md="6">
                        {memberData.parentInfo &&
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="parent-last-name"
                              >
                           Parent last name
                           </label>
                           <Input
                              className="form-control-alternative"
                              value={memberData.parentInfo.last_name}
                              readOnly
                              id="parent-last-name"
                              placeholder="Home Address"
                              type="text"
                              />
                        </FormGroup>
                        }
                        </Col>
                     </Row>
                     <Row>
                        <Col lg="4">
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="input-city"
                              >
                           City
                           </label>
                           <Input
                              className="form-control-alternative"
                              value={memberData.memberDetails.city}
                              readOnly
                              id="input-city"
                              placeholder="City"
                              type="text"
                              />
                        </FormGroup>
                        </Col>
                        <Col lg="4">
                        {memberData.parentInfo && 
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="email"
                              >
                           Email
                           </label>
                           <Input
                              className="form-control-alternative"
                              value={memberData.parentInfo.email}
                              readOnly
                              id="input-email"
                              placeholder="Email"
                              type="email"
                              />
                        </FormGroup>
                        }
                        </Col>
                        <Col lg="4">
                        {memberData.parentInfo &&
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="input-phone"
                              >
                           Phone Number
                           </label>
                           <Input
                              className="form-control-alternative"
                              value={memberData.parentInfo.phone_number}
                              readOnly
                              id="input-phone"
                              placeholder="Phone Number"
                              type="text"
                              />
                        </FormGroup>
                        }
                        </Col>
                     </Row>
                  </div> */}
                  <Button disabled={!isEdit} type="Submit" color="primary">
                     Save 
                  </Button>
               </Form>
            </CardBody>
         </Card>
         </Col>
      </Row>
      <div>
         <h3 className="mt-5">Course History</h3>
      </div>
         <div className="row mt-4 m-auto ">
            {riderHistory && riderHistory.map((each) => (
            <div className="col-sm-3 mb-3" key={each.course}>
               <div className="card" style={{ "width": "18rem" }}>
               <img src={localImage} className="card-img-top w-100" style={{ "height": "150px", "objectFit": "cover" }} alt="..." />
               <div className="card-body">
                  <div className="row">
                     <div className="col-sm-8">
                        <h3 className="card-text">{each.course_name}</h3>
                     </div>
                     <div className="col-sm-4">
                        <h3 className="card-text">₹ {each.course_price}</h3>
                     </div>
                  </div>
                  <div className="row my-3">
                     <div className="col-sm-6">
                        <div>
                           <p> <small>Order Date</small></p>
                           <p className='mt--3'><i className='ni ni-calendar-grid-58'></i><span style={{fontWeight:"500",fontSize:"15px"}}>&nbsp;&nbsp;&nbsp;{new Date(each.created_at).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}</span></p>
                        </div>
                     </div>
                  </div>
                  <Link to={`/admin/course-detail/${each.course}`} className="d-flex justify-content-center">
                  <Button color="primary" className="w-100 primary text-white">View Details</Button>
                  </Link>
               </div>
            </div>
         </div>))} 
      </div>
   </Container>
   </div>}
   </>
   );
   };
   export default RiderDetails;