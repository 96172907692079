import React from 'react'
import image1 from '../../../assets/img/theme/2024-website-79-1024x497.png'
import image2 from '../../../assets/img/theme/2024-website-80-1024x497.png'
export default function TypicalDay() {
  return (
<div className="typical-day-section py-5">
        <div className="container text-center">
          <h1 className="text-white about__h2">typical day at camp</h1>
          <p className="text-white">
            While no two days at camp are ever the same, we do stick to a pretty
            regular schedule! Days begin at 7am and end by 10:30pm. The hours
            in-between are broken up into a familiar rhythm of horse riding,
            meals, periods of rest and play
          </p>
          <div className="row">
            <div className="col-sm-6">
              {/* <div class="paper">
                <div class="paper-content">
                  <textarea
                    disabled
                    autoFocus
                    rows="4"
                    cols="50"
                    value={
                      "7 AM rise and shine \r\n8-10 AM riding [ batch wise ] \r\n10-11 AM practical learning session \r\n11-1 PM fun and games \r\n1-2 PM lunch \r\n2-4 PM rest time"
                    }
                  ></textarea>
                </div> */}
                <img src={image1} alt="" style={{width:'100%'}}/>
              </div>
              <div className="col-sm-6">
              {/* <div class="paper">
                <div class="paper-content">
                  <textarea
                    disabled
                    autoFocus
                    rows="4"
                    cols="50"
                    value={
                      "7 AM rise and shine \r\n8-10 AM riding [ batch wise ] \r\n10-11 AM practical learning session \r\n11-1 PM fun and games \r\n1-2 PM lunch \r\n2-4 PM rest time"
                    }
                  ></textarea>
                </div> */}
                <img src={image2} alt="" style={{width:'100%'}}/>
              </div>
            </div>
          </div>
      </div>
  )
}
