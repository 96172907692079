import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import routes from "routes.js";
import InstructorSidebar from "components/Sidebar/InstructorSidebar";

export default function InstructorLayout(props) {
    const mainContent = React.useRef(null);
    const location = useLocation();
  
    React.useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }, [location]);
  
    const getRoutes = (routes) => {
      return routes.map((prop, key) => {
        if (prop.layout === "/instructor") {
          return (
            <Route path={prop.path} element={prop.component} key={key} exact />
          );
        } else {
          return null;
        }
      });
    };
  
    const getBrandText = (path) => {
      for (let i = 0; i < routes.length; i++) {
        if (
          props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
          -1
        ) {
          return routes[i].name;
        }
      }
      return "Brand";
    };
  return (
    <div>
          <InstructorSidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/instructor/",
          imgSrc: require(".././assets/img/brand/Japalouppe_Logo_navy.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
        />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/instructor/index" replace />} />
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </div>
  )
}
