import { useAuthContext } from 'hooks/useAuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './ParentProfile.css'
import React from 'react'
import {
    Row,
    Container,
    Col
  } from "reactstrap";
import { Link } from 'react-router-dom';
export default function ParentHeader() {
  const {user} = useAuthContext();
  return (
    <div>
         <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
            minHeight: "400px",
            backgroundImage:
                "url(" + require("../../assets/img/theme/counter-bg.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            }}
        >
        {/* Mask */}
        <span className="mask opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="text-white d-flex" style={{"fontSize":"46px",alignItems: "center"}}>Welcome to Japalouppe, {user.first_name}!
              
              <Link style={{ right: "-100px"}} className="position-absolute" to="/parent/profile"><span className=" text-white" style={{ right: "-100px", fontSize: "25px" , cursor:"pointer" }}> <FontAwesomeIcon style={{fontSize:"25px"}} icon={faPenToSquare} /></span></Link>
              </h1>
              <p className="text-white ">
                Let's get ready for an exciting journey filled with fun and learning for your little ones!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
