import Loader from 'components/Loader';
import React, { useState, useEffect } from 'react';
import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Table, Media , Card , CardHeader, Button } from 'reactstrap';
import * as XLSX from 'xlsx';

export default function OrderTable({courseId,courseName}) {
    const {data:orderData, isPending}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/get_orders/'+courseId);
    useEffect(()=>{
      if(orderData){
        console.log(orderData,"hieeee")
      }
    },[orderData])
    const {postData:archiveOrder, postError,resData:resOrder} = usePost();
    const exportToExcel = () => {
      const filename = "orders.xlsx";
      const wsData = [["Child Name", "Gender", "Age", 'Weight',"Level", "Parent 1", "Mobile 1", "Mobile 2", "Email", "Allergies/Medications/Spl","Paid"]];
      let totalRevenue = 0;
      orderData && orderData.orders.forEach(order => {
        const price = parseFloat(order.course_price) || 0;
        totalRevenue += price;
          wsData.push([`${order.member_first_name} ${order.member_last_name}`,
          order.member_gender,
          order.member_age,
          order.member_riders_weight,
          order.level_name || 'N/A',
          `${order.parent_first_name} ${order.parent_last_name}`,
          order.parent_phone_number,
          order.member_parent2_contact,
          order.parent_email,
          order.member_allergies,
          "₹"+(order.course_price)
        ]);
      });
      wsData.push(["", "", "", "", "", "", "", "", "", "", ""]);
      wsData.push(["Total Revenue", "", "", "", "", "", "", "", "", "", "₹" + totalRevenue.toFixed(2)]);

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `Orders(${courseName})`);
      XLSX.writeFile(wb, filename);
  };

  const handleArchiveOrder = (e, order_id) =>{
      e.preventDefault();
      if (window.confirm("Are you sure you want to delete this order " +order_id+"?")) {
        archiveOrder(process.env.REACT_APP_DJANGO_SERVER_URL + "/api/deleteRiderOrder/" + order_id, {}, "DELETE");
      }

  }

  useEffect(()=>{
    if(resOrder && resOrder.status===200 ){
      NotificationManager.success('Order deleted Successfully!', '', 3000);
        window.location.reload();
    }
    if(postError){
        NotificationManager.error('Could not delete order!', '', 3000);
    }
},[resOrder])
  return (
    <div>
          <NotificationContainer/>
        {isPending && <Loader/>}
         { <Card className="shadow mt-2">
                <CardHeader className="border-0">
                    <div className="row">
                      <div className="col-sm-2">
                        <h3 className="mb-0">All Orders</h3>
                      </div>
                      <div className="col-sm-9 text-right">
                        <h3 className="mb-0"><small style={{fontSize:"14px"}}> Total Boys: {orderData && orderData.total_male_count}, {" "}
                        Total Girls: {orderData && orderData.total_female_count},{" "}</small>
                        Total Revenue: ₹{orderData && parseInt(orderData.total_revenue, 10).toString()}</h3>
                      </div>
                      <div className="col-sm-1  text-right">
                        <button onClick={exportToExcel} class="btn btn-primary btn-sm">Export</button>
                      </div>                    
                    </div>
                </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Rider Name</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th scope="col">Parent Name</th>
                    <th scope="col">Parent Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Level</th>
                    <th>School</th>
                    <th>Paid</th>
                    <th scope="col">Delete Order</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData && orderData.orders.map((each)=>(<tr key={each.order_id}>
                    <th scope="row">
                      <Media className="align-items-center">
                        <Media>
                          <span className="mb-0 text-sm">
                           {each.member_first_name} {each.member_last_name}
                          </span>
                        </Media>
                      </Media>
                    </th>
                    <td>{each.member_gender}</td>
                    <td>{each.member_age}</td>
                    <td>{each.parent_first_name} {each.parent_last_name}</td>
                    <td>{each.parent_phone_number}</td>
                    <td>
                    {each.parent_email}
                    </td>
                    {each.level_name ? (<td>{each.level_name}</td>) :  (<td>N/A</td>) }
                    <td>{each.member_school_name}</td>
                    <td>₹{each.course_price}</td>
                    <td className='text-center'><Button onClick={(e)=> handleArchiveOrder(e,each.order_id)} className='p-0 shadow-none '><i class="fa-solid fa-trash text-danger"></i></Button></td>
                  
                  </tr>))}
                  {/* {memberData && memberData.length===0 && <tr className="text-center">
                    <div className="text-center py-3">
                      <h3> No Records Found.</h3>
                    </div>
                  </tr>} */}
                </tbody>
              </Table>
              </Card>
}    </div>
  )
}
