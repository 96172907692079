// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-badge {
    position: absolute;
    top: 0;
    left: 27px; 
    background-color: #dc3545; 
    color: #fff; 
    padding: 5px 10px;
    border-radius: 100%; 
  }

  .navbar-light .navbar-nav .nav-link{
    color: #2d3248 !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/Navbars/AdminNav.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".custom-badge {\n    position: absolute;\n    top: 0;\n    left: 27px; \n    background-color: #dc3545; \n    color: #fff; \n    padding: 5px 10px;\n    border-radius: 100%; \n  }\n\n  .navbar-light .navbar-nav .nav-link{\n    color: #2d3248 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
