import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react"
import DatePicker from "react-multi-date-picker"

import {
    Button
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
export default function BlackoutDates() {
    const [values, setValues] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const {postData,resData,isPending,postError} = usePost();
    const disabledDates = ['2024-02-15', '2024-02-17', '2024-02-22', '2024-02-29'];

    const {data:blackOutDates}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/get-blackoutDates/');

    const handleSubmit = () =>{
        const blackoutDates=values.map((each)=>{
           return(each.year+'-'+each.month+'-'+each.day)
        })
        // const payload = {
        //     "blackoutDates" : blackoutDates
        // }
        // console.log(payload)
        // return 
        postData(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/add-blackout-dates/',blackoutDates,'POST')
    }
    useEffect(()=>{
        if(resData && resData.status === 200){
            NotificationManager.success("Dates were successfully blacked out!", 'Success', 3000);
            setValues([]);
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[resData,postError])
    if(blackOutDates){
        const formattedBlackOutDates = blackOutDates['blackoutDates']
        .map(dateObj => new Date(dateObj.blackout_date).toISOString().split('T')[0])
        .join(', ');
    }

    // console.log(formattedBlackOutDates, ' heyy')
    return (
        <div className='container pt-7'>
            <NotificationContainer/>
            <div><label>Select Date(s)</label></div>
            <DatePicker 
                multiple
                value={values} 
                onChange={setValues}
                className="form-control-alternative"
                style={{padding:"20px"}}
            />
            {!isPending && <Button className="ml-4" onClick={handleSubmit}>Submit</Button>}
            {isPending && <Button className="ml-4" disabled>Submitting...</Button>}
            {/* <div>
                <Flatpickr
                    value={dateRange}
                    onChange={setDateRange}
                    minDate={new Date()}
                    options={{
                        disable: disabledDates.map(date => new Date(date)),
                        dateFormat: "Y-m-d",
                        mode: "range"
                    }}
                />
            </div> */}
        </div>
  )
}
