
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  Spinner
} from "reactstrap";


import Header from "components/Headers/Header.js";
import { useFetch } from "hooks/useFetch";
import { Link } from "react-router-dom";

const Index = (props) => {
  const {data,isPending}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/courseBookingDetails');
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="my-2" fluid>
        <Row className="mt-5">
        {isPending && <div className="text-center">
          <Spinner
          color="primary"
          style={{
            height: '3rem',
            width: '3rem'
          }}
          >
          </Spinner>
        </div>}
        {data && data.lenghth===0 &&<p>No course available!</p>}
          {data && data.map((each)=>(<Col key={each.course_id} className="mb-5 mb-xl-0 mt-5" xl="6">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{each.course_name} 
                    </h3>
                    <h4>{new Date(each.course_from_date).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })}
                    {' '} to {' '}
                    {new Date(each.course_to_date).toLocaleDateString(undefined, { month: "short", day: "numeric",  year: "numeric" })}</h4>
                  </div>
                  <div className="col text-right">
                    <Link to={`/admin/course-detail/${each.course_id}`}>
                      <Button
                        color="primary"
                        size="sm"
                      >
                        View Details
                      </Button>
                    </Link>
                    
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                  <th scope="col">Level</th>
                    <th scope="col">Boys</th>
                    <th scope="col">Waitlist</th>
                    <th scope="col">Girls</th>
                    <th scope="col">Waitlist</th>
                  </tr>
                </thead>
                <tbody>
                  {each.levelSeats.map((each)=>(<tr key={each.id}>
                    <th scope="row">{each.level_name}</th>
                    <td>{each.boys_order_count} / {each.boys_seats}</td>
                    <td>{each.boys_waitlist_count}</td>
                    <td>{each.girls_order_count} / 10</td>
                    <td>{each.girls_waitlist_count}</td>
                  </tr>))}
                </tbody>
              </Table>
            </Card>
          </Col>))}
        </Row>
      </Container>
    </>
  );
};

export default Index;
