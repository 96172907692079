import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Table, Card, CardHeader } from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Papa from 'papaparse';

function convertToCSV(data) {
    const csv = Papa.unparse(data);
    return csv;
  }
export default function PromoteCourses() {
  const [formData, setFormData] = useState({
    level: [],
    campStartDate: '',
    campEndDate: '',
    ageFrom: '',
    ageTo: '',
    city: '',
    school: '',
  });
  const [showTable,setShowTable]=useState(false);
  const tableData = [
    {
      Name: 'Ram',
      Age: 15,
      Level: 'L1',
      City: 'Pune',
      School: 'New English',
    },
    {
      Name: 'Sham',
      Age: 17,
      Level: 'N1',
      City: 'Pune',
      School: 'New English',
    },
    {
      Name: 'Baburao',
      Age: 19,
      Level: 'B1',
      City: 'Pune',
      School: 'New English',
    },
    {
      Name: 'Anuradha',
      Age: 16,
      Level: 'B2',
      City: 'Pune',
      School: 'New English',
    },
  ];
  const handleDownloadCSV = () => {
    const csv = convertToCSV(tableData);

    // Create a Blob object with the CSV data
    const blob = new Blob([csv], { type: 'text/csv' });

    // Create a download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'table_data.csv';

    // Trigger a click event to start the download
    link.click();
  };
  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    const isChecked = e.target.checked;
    if (isChecked) {
      setFormData((prevData) => ({
        ...prevData,
        level: [...prevData.level, value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        level: prevData.level.filter((level) => level !== value),
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowTable(false);
   // Create an array to store the names of empty fields
    const emptyFields = [];

    // Validation: Check if any field is empty
    if (formData.level.length === 0) {
        emptyFields.push('Select Level');
    }
    if (formData.campStartDate === '') {
        emptyFields.push('Camp Attended From');
    }
    if (formData.campEndDate === '') {
        emptyFields.push('Camp Attended To');
    }
    if (formData.ageFrom === '') {
        emptyFields.push('Age From');
    }
    if (formData.ageTo === '') {
        emptyFields.push('Age To');
    }
    if (formData.city === '') {
        emptyFields.push('City');
    }
    if (formData.school === '') {
        emptyFields.push('School');
    }

    if (emptyFields.length > 0) {
        const errorMessage = `Please fill in the following required fields: ${emptyFields.join(', ')}`;
        NotificationManager.error(errorMessage, '', 3000);
        return;
    }
    console.log(formData);
    setShowTable(true)
    // You can perform further actions with the form data here
  };

  return (
    <div className="container pt-7">
        <NotificationContainer/>
        <Form onSubmit={handleSubmit}>
            <h6 className="heading-small text-muted mb-4">Promote summer camp 1</h6>
            <Row form>
            <Col md={12}>
                <FormGroup>
                <Label for="level">Select Level(s)</Label>
                <div>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="B1"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('B1')}
                        />{' '}
                        B1
                    </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="B2"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('B2')}
                        />{' '}
                        B2
                    </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="B3"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('B3')}
                        />{' '}
                        B3
                    </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="B4"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('B4')}
                        />{' '}
                        B4
                    </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="N1"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('N1')}
                        />{' '}
                        N1
                    </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="N2"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('N2')}
                        />{' '}
                        N2
                    </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="N3"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('N3')}
                        />{' '}
                        N3
                    </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Label check>
                        <Input
                        type="checkbox"
                        name="level"
                        value="N4"
                        onChange={handleCheckboxChange}
                        checked={formData.level.includes('N4')}
                        />{' '}
                        N4
                    </Label>
                    </FormGroup>
                </div>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label for="campStartDate">Camp Attended From</Label>
                <Input
                    type="date"
                    id="campStartDate"
                    name="campStartDate"
                    className="form-control-alternative"
                    onChange={handleInputChange}
                    value={formData.campStartDate}
                />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label for="campEndDate">Camp Attended To</Label>
                <Input
                    type="date"
                    id="campEndDate"
                    name="campEndDate"
                    className="form-control-alternative"
                    onChange={handleInputChange}
                    value={formData.campEndDate}
                />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label for="ageFrom">Age From</Label>
                <Input
                    type="number"
                    id="ageFrom"
                    name="ageFrom"
                    className="form-control-alternative"
                    onChange={handleInputChange}
                    value={formData.ageFrom}
                />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label for="ageTo">Age To</Label>
                <Input
                    type="number"
                    id="ageTo"
                    name="ageTo"
                    className="form-control-alternative"
                    onChange={handleInputChange}
                    value={formData.ageTo}
                />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label for="city">City</Label>
                <Input
                    type="text"
                    id="city"
                    name="city"
                    className="form-control-alternative"
                    onChange={handleInputChange}
                    value={formData.city}
                />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label for="school">School</Label>
                <Input
                    type="text"
                    id="school"
                    name="school"
                    className="form-control-alternative"
                    onChange={handleInputChange}
                    value={formData.school}
                />
                </FormGroup>
            </Col>
            <Col md={12}>
                <FormGroup>
                <Button color="primary" type="submit" className="mt-3">
                    Search
                </Button>
                </FormGroup>
            </Col>
            </Row>
        </Form>

        {/* Result Table */}
        {showTable && <Row className="mt-4">
            <Col className="mb-5 mb-xl-0" xl="6">
                <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                    <div className="col">
                        <h3 className="mb-0">Search Results </h3>
                    </div>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Age</th>
                        <th scope="col">Level</th>
                        <th scope="col">City</th>
                        <th scope="col">School</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Ram</th>
                        <td>15</td>
                        <td>L1</td>
                        <td>Pune</td>
                        <td>New English</td>
                    </tr>
                    <tr>
                        <th scope="row">Sham</th>
                        <td>17</td>
                        <td>N1</td>
                        <td>Pune</td>
                        <td>New English</td>
                    </tr>
                    <tr>
                        <th scope="row">Baburao</th>
                        <td>19</td>
                        <td>B1</td>
                        <td>Pune</td>
                        <td>New English</td>
                    </tr>
                    <tr>
                        <th scope="row">Anuradha</th>
                        <td>16</td>
                        <td>B2</td>
                        <td>Pune</td>
                        <td>New English</td>
                    </tr>
                    </tbody>
                </Table>
                </Card>
            </Col>
        </Row>}
       {showTable && <Button onClick={handleDownloadCSV} color="primary" type="submit" className="mt-4">
                    Download CSV
        </Button>}
    </div>
  );
}
