import React, { useState } from 'react'
import img from '../../assets/img/theme/rrp-bg.jpg';
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { differenceInDays, format,getDay, getDate } from 'date-fns';
import {
    Button,
    Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter,Table,Row,Col,Form, Input
} from "reactstrap";
import { usePost } from 'hooks/usePost';
import { useEffect } from 'react';
import { useAuthContext } from 'hooks/useAuthContext';
import { useFetch } from 'hooks/useFetch';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { cashfree } from "cashfree/Util";

import DatePicker from "react-multi-date-picker"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

export default function RRP() {
    const [checkIn,setCheckIn] = useState('');
    const [checkOut,setCheckOut] = useState('');
    const [guest, setGuest] = useState('');
    const [timings, setTimings] = useState('');
    const {postData,resData,isPending,postError} = usePost();
    const [isavailable, setIsAvailable] = useState(null);
    const { user } = useAuthContext();
    const {data:rrpPriceData,error:rrpPriceError}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+"/api/get-rrp-prices");
    const {data:fetchCoupons} = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getAllCoupon");
    const {data:blackoutDates} = useFetch(process.env.REACT_APP_NODE_SERVER_URL+"/api/get-blackoutDates");
    const [totalPrice,setTotalPrice]=useState(0);
    const [isRRPModalOpen, setIsRRPModalOpen] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState(null);
    const [pushMembers, setPushMembers] = useState([]);
    const [rrpDaysCount, setRrpDaysCount] = useState();
    const [couponCode, setCouponCode] = useState('');
    const [percentDiscount, setPercentDiscount] = useState(0);
    const [grandTotal,setGrandTotal] = useState(0);
    const [allCoupons, setAllCoupons] = useState('');
    const [selectedCoupon, setSelectedCoupon] = useState('');
    const [totalCost, setTotalCost] = useState(null);
    const [dateRange, setDateRange] = useState([]);
    const {postData:addToRRPCart,resData:cartResData,isPending:cartIsPending}=usePost();
    const{data:memberData,isPending:membersPending,error:memberError} = useFetch(process.env.REACT_APP_NODE_SERVER_URL+"/api/get-member/"+user.user_id);
    const [cartId,setCartId]=useState(null);
    const [allBlackOutDates, setAllBlackOutDates] = useState(null);
        
    useEffect(() => {
        if(memberData){
            const mappedMembers = memberData.map((each) => {
                return { member: each.first_name + ' ' + each.last_name, code: each.member_id };
            });
        
            const selfObject = { member: 'Myself', code: user.user_id };
            mappedMembers.unshift(selfObject);
            setPushMembers(mappedMembers);
        }
    }, [memberData])
    

    const today = new Date();
    // Get the next two days
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);

    useEffect(() => {
        if(blackoutDates && blackoutDates.status===200){
            let savedBlackOutDates = blackoutDates.blackoutDates.map(date => new Date(date.blackout_date));
            setAllBlackOutDates([...savedBlackOutDates,today,nextDay])
        }
    }, [blackoutDates])

    useEffect(()=>{
        if(fetchCoupons && fetchCoupons.status===200){
            setAllCoupons(fetchCoupons);
        }
    },[fetchCoupons])

        
    useEffect(() => {
        if((resData && resData.status ===200) && ( rrpPriceData && rrpPriceData.status===200) ){
            setIsAvailable(resData.availability)
            setIsRRPModalOpen(true)
            calculatePrice();
        }
        if(postError || rrpPriceError){
            if(postError){
                NotificationManager.error(postError, 'Error', 1800)
                return ;
            }
            if(rrpPriceError){
                NotificationManager.error(rrpPriceError, 'Error', 1800)
                return ;
            }
        }
    }, [resData, rrpPriceData])

    const handleSubmitCoupon=(e)=>{
        e.preventDefault()
        const foundCoupon = fetchCoupons.filter((coupon) => Number(couponCode) === coupon.coupon_id)
        setSelectedCoupon(foundCoupon[0])
    }
    useEffect(() => {
        if(rrpPriceData && (selectedCoupon || couponCode ==='')){
            calculatePrice();
        }
    }, [selectedCoupon, rrpPriceData])

    const calculatePrice = () => {
        if (rrpPriceData && selectedMembers){
            const daysCount = differenceInDays(checkOut,checkIn)
            const { weekday_price, weekend_price, wednesday_price } = rrpPriceData.priceData;// Get the daily prices from rrpPriceData
            let totalPrice = 0;  // setting up variable for total price
            let withoutCouponPrice = 0;
            for (let i = 0; i < daysCount; i++) {
                const currentDate = new Date(checkIn);
                currentDate.setDate(dateRange[0].getDate() + i);
                const currentDay = currentDate.getDay();

                // setting up the price below based the selected DAYS
                if (currentDay === 3) {
                    // Wednesday
                    totalPrice += wednesday_price;
                } else if (currentDay === 0 || currentDay === 6) {
                    // Sunday or Saturday (weekend)
                    totalPrice += weekend_price;
                } else {
                    // Weekday (Monday to Friday)
                    totalPrice += weekday_price;
                }
            }
            totalPrice = withoutCouponPrice = totalPrice * selectedMembers.length

            // adding the discount based on the days count
            if(daysCount>=5 && daysCount<=8){
                setPercentDiscount(10)
                totalPrice = totalPrice - ((totalPrice * 10)/ 100)
            }else if(daysCount>=9){
                setPercentDiscount(15)
                totalPrice = totalPrice - ((totalPrice * 15)/ 100)
            }else{
                setPercentDiscount(0)
            }
            setTotalCost(totalPrice);
            // adding GST to grand if there is no any coupon to apply
            totalPrice = totalPrice + ((totalPrice * 18)/ 100)
            setGrandTotal(Math.round(totalPrice))

            // applying coupons if any and deducting the cost 
            if(selectedCoupon && selectedCoupon.is_percent_discount){
                totalPrice = totalCost - (totalCost*selectedCoupon.coupon_discount/100)
                totalPrice = totalPrice + ((totalPrice*18)/100)
                setGrandTotal(Math.round(totalPrice))
            }else if(selectedCoupon && !selectedCoupon.is_percent_discount){
                totalPrice = totalCost - selectedCoupon.coupon_discount
                totalPrice = totalPrice + ((totalPrice*18)/100)
                setGrandTotal(Math.round(totalPrice))
            }else if( couponCode ==='' ){
                totalPrice = withoutCouponPrice + ((withoutCouponPrice*18)/100)
                setGrandTotal(Math.round(totalPrice))
            }
        }
        // 0 sunday, 1 monday, 2 tuesday, 3 wednesday,  4 thursday, 5 friday, 6 saturday

    };
    
    const handleCheckAvailibility = ()=>{
        console.log('here are the selected dates ',dateRange)

        if (dateRange.length === 2) {
            // Calculate and get middle dates
            const startDate = format(dateRange[0], 'yyyy-MM-dd'); //changing the dateformat with respect to the payload
            const endDate = format(dateRange[1], 'yyyy-MM-dd'); //changing the dateformat with respect to the payload
            setCheckIn(startDate);
            setCheckOut(endDate);

            // cheking if the dates selected are available for the rrp or not.
            const payload = {
                "check_in":startDate,
                "check_out": endDate
            }
            postData(process.env.REACT_APP_NODE_SERVER_URL+'/api/check_rrp_availability',payload,'POST')
        }
        // return;

    }

    const handleAddtoCart=()=>{
        const payload={
            "parent_id": user.user_id,
            "guests_array": selectedMembers,
            "guest_count": selectedMembers.length,
            "checkin_date": checkIn,
            "checkout_date": checkOut,
            "timings":timings,
            "total_price": Number(grandTotal),
            "archive": false,
            "created_at": new Date(),
            "updated_at": new Date()
          }
          console.log(payload);
        //   return;
          
        addToRRPCart(process.env.REACT_APP_NODE_SERVER_URL+"/api/insert-rrp-cart",payload,"POST")
    }
    useEffect(()=>{
        if(cartResData && cartResData.status===200){
            setCartId(cartResData.cartId);
        }
    },[cartResData])
    useEffect(() => {
    if(cartId !==null){
        handleBookRRP();
    }
    }, [cartId])
    const handleBookRRP=async()=>{
        let payload={
            "customer_details": {
                "customer_id": String(user.user_id),
                "customer_phone": user.phone_number
            },
            "order_tags": {
                "booking_type": "rrp_booking",
              },
            "order_amount": Number(grandTotal),
            "order_currency": "INR"
        }
    try{
      const res =await fetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/createAnOrder',{
        method: 'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body: JSON.stringify(payload),
      })
    if(!res.ok){
      throw new Error('Something went wrong!')
    }
      const data=await res.json();
    //   createAPaymentLink(data);
    handlePayment(data.payment_session_id,data.order_id)
   
    
    }catch(err){
      console.log(err.message)
    }
      }
      const handlePayment = (sessionId,orderId) => {
        let checkoutOptions = {
          paymentSessionId: sessionId,
          returnUrl: process.env.REACT_APP_SERVER_URL+'/parent/rrp-order-success/'+orderId+'/'+cartId, 
        }
        cashfree.checkout(checkoutOptions).then(function(result){
          if(result.error){
            alert(result.error.message)
          }
          if(result.redirect){
            console.log("Redirection")
          }
        });
      }
    const createAPaymentLink=async(data)=>{
        console.log("hi",cartId)
        // console.log('http://localhost:3000/parent/rrp-order-success/'+data.order_id+'/'+cartId);
        let payload={
        "customer_details": {
            "customer_phone": user.phone_number,
            "customer_email": user.email,
            "customer_name": user.first_name + ' '+user.last_name
        },
        "link_notify": {
            "send_sms": false,
            "send_email": false
        },
        "link_meta": {
        "notify_url": 'http://localhost:3000/parent/rrp-order-success/'+data.order_id,
        "return_url": 'http://localhost:3000/parent/rrp-order-success/'+data.order_id+'/'+cartId
        },
        "link_id": data.order_id,
        "link_amount": Number(grandTotal),
        "link_currency": "INR",
        "link_purpose": "Payment for RRP",
        "link_partial_payments": false
        }
        try{
        const res =await fetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/createPaymentLink',{
        method: 'POST',
            headers:{
                'content-type':'application/json; charset=UTF-8',
            },
            body: JSON.stringify(payload),
        })
        if(res.ok){
        const data=await res.json();
        window.location.href=data.link_url // redirects to the payment link url of payu
        
        }else{
        console.error('Request failed with status:', res.status);
        const errorText = await res.text();
        console.error('Error response:', errorText);
        }
        
        
        }catch(err){
            console.log(err.message)
        }
    
    }

    const handleSelectChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
        setSelectedTeams(selectedOptions);
    };


    useEffect(() => {
      console.log('here are the timings ',timings)
    }, [timings])
    

return (
    <div className='container-fluid pt-7 px-0 mx-0'>
            <NotificationContainer/>
            <Modal isOpen={isRRPModalOpen} toggle={() => setIsRRPModalOpen(!isRRPModalOpen)}>
                <ModalHeader toggle={() => setIsRRPModalOpen(!isRRPModalOpen)}>Book RRP</ModalHeader>
                <ModalBody>
                    {isavailable!==null && !isavailable &&
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className='justify-center text-danger text-center'>Apologies, RRP is not available on the selected dates!</h3>
                            </div>
                        </div>
                    }

                    { isavailable && isavailable===true &&  <>
                            <Table  className="align-items-center table-flush" responsive>
                                <thead><h3>RRP Purchase Details</h3></thead>
                                <th>Check In Date</th>
                                <th>Check Out Date</th>
                                <th>Guests</th>
                                <th>Total</th>
                                <tbody>
                                    <tr>
                                        <td>{format(dateRange[0], 'dd-MM-yyyy')}</td>
                                        <td>{format(dateRange[1], 'dd-MM-yyyy')}</td>
                                        <td>{selectedMembers.length}</td>
                                        {totalCost &&  <td>₹{totalCost}</td>}
                                    </tr>
                                </tbody>
                            </Table>
                            <Table className="align-items-center table-flush" responsive>
                                <thead><h3>Grand Total Breakdown</h3></thead>
                                <tbody>
                                    { totalCost && 
                                        <tr>
                                            <td className=''><strong>Total Cost</strong></td>
                                            <td className=''>
                                                ₹{totalCost}
                                            </td>
                                        </tr>
                                    }
                                    { selectedCoupon && 
                                        <tr>
                                            <td className='text-success'><strong>Coupon Discount</strong></td>
                                            <td className='text-success'>
                                                { selectedCoupon!==null && (selectedCoupon.is_percent_discount===true?selectedCoupon.coupon_discount+'%':'₹'+selectedCoupon.coupon_discount)}
                                            </td>
                                        </tr>
                                    }
                                    {/* { percentDiscount!==0 && 
                                        <tr>
                                            <td className='text-success'><strong>RRP Discount</strong></td>
                                            <td className='text-success'>
                                                {percentDiscount===10?'10%':'15%'}
                                            </td>
                                        </tr>
                                    } */}
                                    <tr>
                                        <td><strong>GST</strong></td>
                                        <td>18%</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Grand Total</strong></td>
                                        <td><strong>{grandTotal}</strong></td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                            
                                {/* <Form onSubmit= {handleSubmitCoupon}>
                                    <Row className='align-items-center '>
                                        <Col lg="4">
                                            <div className='form-control border-0 text-dark'>Apply Coupon</div>
                                        </Col>
                                        <Col lg='5'>
                                            { fetchCoupons && 
                                                <Input 
                                                    className="form-control-alternative shadow-none border" 
                                                    type="select" 
                                                    name="select" 
                                                    id="exampleSelect"
                                                    value={couponCode.coupon_id}
                                                    onChange={(e)=> setCouponCode(e.target.value)}
                                                    >
                                                    <option key='' value=''>Select Coupon</option>
                                                    {fetchCoupons.map((coupon)=>(
                                                        <option className='' key={coupon.coupon_id} value={coupon.coupon_id}>{coupon.coupon_code}</option>
                                                    ))}
                                                </Input>    
                                            }                                
                                        </Col>
                                        <Col lg="3">
                                            <button className='btn btn-primary btn-sm'>Apply</button>
                                        </Col>
                                    </Row>
                                </Form> */}

                        </>
                    }

                </ModalBody>
                <ModalFooter>
                    {isavailable && !cartIsPending && <Button color="success" className='' onClick={handleAddtoCart}>Total Payable: ₹{grandTotal}</Button>}
                    {isavailable && cartIsPending && <Button color="success" disabled ><Spinner size="sm"></Spinner>Total Payable: ₹{grandTotal}</Button>}
                </ModalFooter>
            </Modal>
        <style>
            {`  .brown{
               background-color:#F2D3A4;
                }
                .dark-brown{
                    background-color:#9D8362;
                }
                .text-brown,a:hover{
                    color:#F2D3A4;
                }
                .text-dark{
                    color:#000;
                }
                .fw-bold{
                    font-weight:500;
                }
                .bg-img{
                    background-image: url(${img});
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            `}
        </style>
        <div className='px-9 pt-5  bg-img mb-3 pb-3'>
            <div className="row">
                <div className="col-sm-12 py-4 px-2 brown">
                    <h1 className='text-center text-dark'>RESIDENTIAL RIDING PACKAGE</h1>
                    <p className='text-center text-dark fw-bold'>A cost effective package for those horse crazy <br /> humans for whom jap is their second home.</p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-6 pl-0">
                    <div className='py-4 px-2 brown'>
                        <h2 className='text-dark text-center'>WEEKDAYS</h2>
                        <h1 className='text-center text-dark'>RS.3500</h1>
                        <p className='text-center text-dark fw-bold'>per day, per rider <br /> + taxes as applicable</p>
                    </div>
                </div>
                <div className="col-sm-6 pr-0">
                    <div className='py-4 px-2 brown'>
                        <h2 className='text-dark text-center'>WEEKENDS</h2>
                        <h1 className='text-center text-dark'>RS.4000</h1>
                        <p className='text-center text-dark fw-bold'>per day, per rider <br /> + taxes as applicable</p>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-12 px-0 brown">
                    <div className="py-4 px-2">
                        <h3 className='text-center text-dark'>PACKAGE, PER DAY INCLUDES</h3>
                        <p className='text-center text-dark fw-bold'>2 Riding Lessons + 5 Veg Meals + Dormitory Accomodation</p>
                    </div>
                </div>
                <div className="col-sm-12 dark-brown mt-3">
                    <div className='text-center mt-3'>
                        <h1 className=' text-white'>STAY LONGER, SAVE MORE</h1>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-6 px-1 ">
                    <div className="py-4 px-2 text-center bg-white">
                        <h1 className='border-dark border-bottom border-3'>10% OFF</h1>
                        <h3>STAY 5-8 DAYS</h3>
                    </div>
                </div>
                <div className="col-sm-6 px-1">
                    <div className="py-4 px-2 text-center bg-white">
                        <h1 className='border-dark border-bottom border-3'>15% OFF</h1>
                        <h3>STAY 9+ DAYS</h3>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-12">
                    <h3 className='text-center text-white'>Effective From 01 July 2022</h3>    
                </div>
            </div>
            <div className="row mt-3 dark-brown px-3 py-3 mb-3">
                { blackoutDates && <div className="col-sm-4">
                    <h3 className='text-white'>Select Dates:</h3>
                        {allBlackOutDates && <Flatpickr
                            className='form-control shadow-none'
                            name="selectDates" 
                            id="selectDates"
                            value={dateRange}
                            onChange={setDateRange}
                            options={{
                                minDate:"today",
                                disable: allBlackOutDates,
                                dateFormat: "d-m-Y",
                                mode: "range"
                            }}
                        />}
                </div>}
                <div className="col-sm-2">
                    <h3 className='text-white'>Timings:</h3>
                    <select required className='form-control shadow-none' name="timings" id="timings" onChange={(e)=>setTimings(e.target.value)} >
                        <option value="">Select Time</option>
                        <option value="9am-9am">9AM to 9AM</option>
                        <option value="3pm-3pm">3PM to 3PM</option>
                    </select>                
                </div>
                <div className="col-sm-4">
                    <h3 className='text-white'>Select Guests:</h3>
                    <div className="card flex justify-content-center ">
                        <MultiSelect value={selectedMembers} onChange={(e) => setSelectedMembers(e.value)} options={pushMembers} optionLabel="member"
                            placeholder="Select Members" maxSelectedLabels={3} className="w-full md:w-20rem p-0 shadow-none" />
                    </div>
                </div>
                <div className="col-sm-2">
                    <h3 className='text-brown'>&nbsp;</h3>
                    {!isPending && <Button color="primary" className='brown' onClick={handleCheckAvailibility}>Check</Button>}
                    {isPending && <Button color="primary" disabled > <Spinner size="sm"> Loading... </Spinner> <span> {' '}Loading </span> </Button>}
                </div>
            </div>
            {/* {isavailable!==null && !isavailable && <div className="row">
                <div className="col-sm-12">
                    <h2 className='justify-center text-danger text-center'>Apologies, RRP is not available on the selected dates!</h2>
                </div>
            </div>} */}
        </div>
        <div className='px-3'>
            <div className='row brown px-9 py-5'>
                <div className="col-sm-12">
                    <h3>CHOOSE YOUR PREFERED CHECK-IN TIME</h3>
                    <div>
                        <span className='text-dark'><strong>1. Check-in 9am- Checkout 9am</strong></span>
                        <p className='fw-bold text-dark'>Breakfast + Lunch Evening Snacks Dinner + Breakfast</p>
                    </div>
                    <div>
                        <span className='text-dark'><strong>2. Check-in 3pm - Checkout 3pm</strong></span>
                        <p className='fw-bold text-dark'>Evening Snacks + Dinner + Breakfasts Lunch + Evening snacks</p>
                    </div>
                    <div>
                        <h3 className='mb-0'>HOLISTIC TRAINING</h3>
                        <p className='fw-bold text-dark'>Riders will also be assisting in tacking up their horses, grooming, grazing, feeding and other staale duties.</p>
                    </div>
                    <div>
                        <h3 className='mb-0'>PLEASE NOTE:</h3>
                        <p className='fw-bold text-dark'>This package is not valid on Wednesdays (day off) and during camp season. However. If the rider wishes to 
                            stay on premises or a wednesday, a base cost of Rs.1700 must be borne for stay and meals.</p>
                    </div>
                </div>
            </div>
            <div className="row dark-brown px-2 py-4">
                <div className="col-sm-12 text-center">
                    <h3 className='text-brown mb-0'>FOR MORE BOOKING AND ENQUIRIES</h3>
                    <h3 className='text-brown'>WHATSAPP MELISSA <a className='text-brown' rel='noreferrer' target='_blank' href="https://wa.me/+919158004104">+91 9158004104</a></h3>
                </div>
            </div>
        </div>
    </div>
  )
}