
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import CreateCourse from "pages/CreateCourse";
import PromoteCourses from "pages/PromoteCourses";
import ViewAllCourses from "pages/ViewAllCourses";
import CourseDetail from "pages/CourseDetail";
import ParentIndex from "pages/Parents/ParentIndex";
import AddChild from "pages/Parents/AddChild";
import AllMembers from "pages/Parents/AllMembers";
import CourseDetails from "pages/Parents/CourseDetails";
import Cart from "pages/Parents/Cart";
import ParentProfile from "pages/Parents/ParentProfile";

import OrderPlacedSuccessfully from "pages/Parents/success/OrderPlacedSuccessfully";
import ViewAllParents from "pages/ViewAllParents";
import AdminLogin from "pages/AdminLogin";
import RiderDetails from "pages/RiderDetails";
import RRP from "pages/Parents/RRP";
import RRPOrderSuccess from "pages/Parents/success/RRPOrderSuccess";
import ViewRRPOrders from "pages/ViewRRPOrders";
import FetchOrders from "pages/Parents/FetchOrders";
import CreateCoupons from "pages/CreateCoupons";
import ViewAllCoupons from "pages/ViewAllCoupons";
import CouponDetails from "pages/CouponDetails";
import BlackoutDates from "pages/BlackoutDates";
import SpectatorIndex from "pages/spectator/SpectatorIndex";
import CampLife from "pages/spectator/CampLife";
import InstructorIndex from "pages/studentInstructor/Index";
import InstructorLogin from "pages/studentInstructor/InstructorLogin";
import EnrolledRiders from "pages/studentInstructor/EnrolledRiders";

import About from "pages/spectator/About";
import RefundAndCancellationPolicy from "pages/spectator/RefundAndCancellationPolicy";
import TermsAndConditionPrivacyPolicy from "pages/spectator/TermsAndConditionPrivacyPolicy";
import ContactUs from "pages/spectator/ContactUs";
import ManageRiders from "pages/Parents/ManageRiders";
import EditRider from "pages/Parents/EditRider";
import NewLogin from "views/examples/Login-reigster"
import Faq from "pages/spectator/Faq";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },
  {
    path: "/create-course",
    name: "Create a Course",
    icon: "ni ni-tv-2 text-primary",
    component: <CreateCourse/>,
    layout: "/admin",
  },
  {
    path: "/promote-courses",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <PromoteCourses/>,
    layout: "/admin",
  },
  {
    path: "/create-coupons",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <CreateCoupons/>,
    layout: "/admin",
  },
  {
    path: "/view-all-courses",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <ViewAllCourses/>,
    layout: "/admin",
  },
  {
    path: "/view-all-coupons",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <ViewAllCoupons/>,
    layout: "/admin",
  },
  {
    path: "/coupon-detail/:couponId",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <CouponDetails/>,
    layout: "/admin",
  },
  {
    path: "/course-detail/:courseId",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <CourseDetail/>,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: <Maps />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
  },
  {
    path: "/rrp-orders",
    name: "RRP Orders",
    icon: "ni ni-bullet-list-67 text-red",
    component: <ViewRRPOrders />,
    layout: "/admin",
  },
  {
    path: "/view-all-students",
    name: "View All Student",
    icon: "ni ni-bullet-list-67 text-red",
    component: <ViewAllParents/>,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Create a Course",
    icon: "ni ni-tv-2 text-primary",
    component: <ParentIndex/>,
    layout: "/parent",
  },
  
  {
    path: "/profile",
    name: "Create a Course",
    icon: "ni ni-tv-2 text-primary",
    component: <ParentProfile/>,
    layout: "/parent",
  },
  {
    path: "/residential-riding-package",
    name: "Residential Riding Package",
    icon: "ni ni-tv-2 text-primary",
    component: <RRP/>,
    layout: "/parent",
  }, 
  
  {
    path: "/rider-details/:riderId",
    name: "Create a Course",
    icon: "ni ni-tv-2 text-primary",
    component: <RiderDetails/>,
    layout: "/admin",
  },
  {
    path: "/blackout-dates",
    name: "Create a Course",
    icon: "ni ni-tv-2 text-primary",
    component: <BlackoutDates/>,
    layout: "/admin",
  },
  {
    path: "/order-success/:orderId",
    name: "Order Success",
    icon: "ni ni-tv-2 text-primary",
    component: <OrderPlacedSuccessfully/>,
    layout: "/parent",
  },
  {
    path: "/rrp-order-success/:orderId/:cartId",
    name: "RRP Order Success",
    icon: "ni ni-tv-2 text-primary",
    component: <RRPOrderSuccess/>,
    layout: "/parent",
  },
  {
    path: "/manage-riders",
    name: "View Riders",
    icon: "ni ni-tv-2 text-primary",
    component: <ManageRiders/>,
    layout: "/parent",
  },
  {
    path: "/edit-rider/:riderId",
    name: "View Riders",
    icon: "ni ni-tv-2 text-primary",
    component: <EditRider/>,
    layout: "/parent",
  },
  {
    path: "/add-child",
    name: "Add a Child",
    icon: "ni ni-tv-2 text-primary",
    component: <AddChild/>,
    layout: "/parent",
  },
  {
    path: "/all-members",
    name: "All Members",
    icon: "ni ni-tv-2 text-primary",
    component: <AllMembers/>,
    layout: "/parent",
  },
  {
    path: "/course-details/:courseId/:memberId",
    name: "Course Details",
    icon: "ni ni-tv-2 text-primary",
    component: <CourseDetails/>,
    layout: "/parent",
  },
  {
    path: "/cart",
    name: "Cart",
    icon: "ni ni-tv-2 text-primary",
    component: <Cart/>,
    layout: "/parent",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-tv-2 text-primary",
    component: <FetchOrders/>,
    layout: "/parent",
  },
  {
    path: "/orders/#rrp-orders",
    name: "RRP Orders",
    icon: "ni ni-tv-2 text-primary",
    component: <FetchOrders/>,
    layout: "/parent",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <NewLogin />,
    layout: "/auth",
  },
  {
    path: "/admin",
    name: "Login admin",
    icon: "ni ni-tv-2 text-primary",
    component: <AdminLogin/>,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <NewLogin />,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Index",
    icon: "ni ni-circle-08 text-pink",
    component: <SpectatorIndex />,
    layout: "/spectator",
  },
  {
    path: "/camp-life",
    name: "Camp Life",
    icon: "ni ni-circle-08 text-pink",
    component: <CampLife />,
    layout: "/spectator",
  },
  {
    path: "/index",
    name: "Student Instructor",
    icon: "ni ni-circle-08 text-pink",
    component: <InstructorIndex />,
    layout: "/instructor",
  },
  {
    path: "/instructor-login",
    name: "Student Instructor",
    icon: "ni ni-circle-08 text-pink",
    component: <InstructorLogin />,
    layout: "/auth",
  },
  {
    path: "/enrolled-riders/:courseId",
    name: "Student Instructor",
    icon: "ni ni-circle-08 text-pink",
    component: <EnrolledRiders />,
    layout: "/instructor",
    path: "/about-japalouppe",
    name: "About",
    icon: "ni ni-circle-08 text-pink",
    component: <About />,
    layout: "/spectator",
  },
  {
    path: "/refund-and-cancellation-policy",
    name: "Rfund and Cancellation Policy",
    icon: "ni ni-circle-08 text-pink",
    component: <RefundAndCancellationPolicy />,
    layout: "/spectator",
  },
  {
    path: "/terms-and-condition-and-privacy-policy",
    name: "Terms and Conditions",
    icon: "ni ni-circle-08 text-pink",
    component: <TermsAndConditionPrivacyPolicy />,
    layout: "/spectator",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    icon: "ni ni-circle-08 text-pink",
    component: <ContactUs />,
    layout: "/spectator",
  },
  {
    path: "/refund-and-cancellation-policy",
    name: "Rfund and Cancellation Policy",
    icon: "ni ni-circle-08 text-pink",
    component: <RefundAndCancellationPolicy />,
    layout: "/admin",
  },
  {
    path: "/terms-and-condition-and-privacy-policy",
    name: "Terms and Conditions",
    icon: "ni ni-circle-08 text-pink",
    component: <TermsAndConditionPrivacyPolicy />,
    layout: "/admin",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    icon: "ni ni-circle-08 text-pink",
    component: <ContactUs />,
    layout: "/admin",
  },
  {
    path: "/refund-and-cancellation-policy",
    name: "Rfund and Cancellation Policy",
    icon: "ni ni-circle-08 text-pink",
    component: <RefundAndCancellationPolicy />,
    layout: "/parent",
  },
  {
    path: "/terms-and-condition-and-privacy-policy",
    name: "Terms and Conditions",
    icon: "ni ni-circle-08 text-pink",
    component: <TermsAndConditionPrivacyPolicy />,
    layout: "/parent",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    icon: "ni ni-circle-08 text-pink",
    component: <ContactUs />,
    layout: "/parent",
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "ni ni-circle-08 text-pink",
    component: <Faq />,
    layout: "/spectator",
  },
];
export default routes;
