import { useAuthContext } from "hooks/useAuthContext";
import { Link, useNavigate } from "react-router-dom";
import './AdminNav.css'
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { useEffect, useState } from "react";

const AdminNavbar = (props) => {
  // const{data}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+"/api/cart/"+user.user_id);
  const [data,setData]=useState(null);
  const [cartCount,setCartCount]=useState(0);
  const [currentPath,setCurrentPath]=useState('');
  const navigate=useNavigate();
  const {user,dispatch}=useAuthContext();
  
  const handleLogout=()=>{
    localStorage.removeItem('user');
    dispatch({type:'LOGOUT'});
    navigate('/spectator/')
  }
  const fetchCartData=async()=>{
    const res=await fetch(process.env.REACT_APP_NODE_SERVER_URL+"/api/cart/"+user.user_id);
    if(res.ok){
      const data=await res.json();
      setData(data)
    }
  };

  useEffect(() => {
  if(user){
    fetchCartData();
  }
  }, [user])

  useEffect(()=>{
    if(data){
      setCartCount(data.length)
    }
  },[data])
  
  // useEffect(()=>{
  //   setCurrentPath(window.location.pathname) 
  //   console.log( currentPath);
  // },[])
  return (
    <>
      <Navbar className="navbar-top navbar-dark rrpClass" expand="md" id="navbar-main" style={currentPath==="/parent/residential-riding-package"?{"backgroundColor":"#F2D3A4"}:{"backgroundColor":"#09173a"}}>
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form>
          {user && user.role ==="parent"  && <Link to="/parent/cart">
            <button type="button" className="btn position-relative px-0">
            <i className="ni ni-cart px-3 text-white" style={{"fontSize":"23px"}}></i>
              <span className="position-absolute custom-badge text-white top-0 ms-auto badge rounded-pill bg-danger" style={{left:"27px !important"}}>
                {cartCount}
            </span>
          </button>
          </Link>}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                {user && user.role ==="admin" && <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/team-4-800x800.jpg")}
                    />
                  </span>
                     <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      Rohan More
                    </span>
                  </Media>
                </Media>}
                {user && user.role ==="parent" && 
                <div>
                <Media className="align-items-center text-capitalize">
                  <span className="avatar avatar-sm rounded-circle">
                    <i className="ni ni-single-02"style={{"fontSize":"18px"}}></i>
                  </span>
                     <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user.first_name}
                    </span>
                  </Media>
                </Media>
                </div>
                }
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;