import React from "react";
import campModuleImg1 from "../../../assets/img/spectators/png-10.png";
import campModuleImg2 from "../../../assets/img/spectators/png-09.png";
import campModuleImg3 from "../../../assets/img/spectators/png-15.png";
import campModuleImg4 from "../../../assets/img/spectators/png-12.png";
import campModuleImg5 from "../../../assets/img/spectators/png-08.png";
import campModuleImg6 from "../../../assets/img/spectators/png-11.png";
export default function CampModules() {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-4 mb-5">
        <div
          className="card"
          style={{ borderRadius: "2px", boxShadow: "none" }}
        >
          <img
            src={campModuleImg1}
            style={{
              width: "100% !important",
              objectFit: "cover",
              height: "200px",
            }}
            className="card-img-top w-100"
            alt="..."
          />
          <div className="card-body text-center">
            <h2 className="card-title">orientation talks</h2>
            <p className="card-text">
              We begin with the camp with parent orientation by Lorraine More,
              followed by the campers orientation and riding demonstration by
              the camp team.<br/><br/>
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-4 mb-5">
        <div
          className="card"
          style={{ borderRadius: "2px", boxShadow: "none" }}
        >
          <img
            src={campModuleImg2}
            style={{
              width: "100% !important",
              objectFit: "cover",
              height: "200px",
            }}
            className="card-img-top w-100"
            alt="..."
          />
          <div className="card-body text-center">
            <h2 className="card-title">riding lesson</h2>
            <p className="card-text">
            Each Lessons is 45 mins long and 
            are scheduled in morning and 
            evening. Campers are divided in 
            groups as per their skill levels and 
            are trained separately in groups.
            </p>
          </div>
        </div>
      </div>

      <div className="col-sm-4 mb-5">
        <div
          className="card"
          style={{   borderRadius: "2px", boxShadow: "none" }}
        >
          <img
            src={campModuleImg3}
            style={{
              width: "100% !important",
              objectFit: "cover",
              height: "200px",
            }}
            className="card-img-top w-100"
            alt="..."
          />
          <div className="card-body text-center">
            <h2 className="card-title">learning sessions</h2>
            <p className="card-text">
                Every evening there are 
                presentations and demos about 
                different topics about like horse 
                breeds, markings, colours, 
                grooming and horse behaviour.
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-4 mb-5">
        <div
          className="card"
          style={{  borderRadius: "2px", boxShadow: "none" }}
        >
          <img
            src={campModuleImg4}
            style={{
              width: "100% !important",
              objectFit: "cover",
              height: "200px",
            }}
            className="card-img-top w-100"
            alt="..."
          />
          <div className="card-body text-center">
            <h2 className="card-title">feedback sessions</h2>
            <p className="card-text">
                Everyday, after their riding lesson, 
                the instructors & the children 
                huddle-up, where the instructors 
                answer all riding related questions 
                patiently. This makes the learning 
                curve easier.
                <br/><br/>
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-4 mb-5">
        <div
          className="card"
          style={{  borderRadius: "2px", boxShadow: "none" }}
        >
          <img
            src={campModuleImg5}
            style={{
              width: "100% !important",
              objectFit: "cover",
              height: "200px",
            }}
            className="card-img-top w-100"
            alt="..."
          />
          <div className="card-body text-center">
            <h2 className="card-title">games & activities</h2>
            <p className="card-text">
                Campers have the opportunity to 
                enjoy other great camp activities 
                team building games, sports, 
                talent shows and interacting with 
                the farm animals among a few.
                <br/><br/>
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-4 mb-5">
        <div
          className="card"
          style={{ borderRadius: "2px", boxShadow: "none" }}
        >
          <img
            src={campModuleImg6}
            style={{
              width: "100% !important",
              objectFit: "cover",
              height: "200px",
            }}
            className="card-img-top w-100"
            alt="..."
          />
          <div className="card-body text-center">
            <h2 className="card-title">riding display</h2>
            <p className="card-text">
                At the end of each camp the 
                children display the skills they 
                have learnt during their stay. The 
                6 day camps are certified amd 
                every camper is awarded a 
                certificate as per their skill level.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
