import LearnMore from 'components/LearnMore'
import React from 'react'
import ParentHeader from './ParentHeader'
import AllMembers from './AllMembers'
import { useFetch } from 'hooks/useFetch';
import { useAuthContext } from 'hooks/useAuthContext'
export default function ParentIndex() {
  const {user}=useAuthContext()
  console.log(user.user_id)
  const {data}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getMembers/'+user.user_id);
  return (
    <div>
      <ParentHeader/>
      {data && data.member.length>0 && <AllMembers members={data.member}/>}
      {data && data.member.length===0 &&<LearnMore/>}
    </div>
  )
}
