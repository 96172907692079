import React from 'react'

export default function RefundAndCancellationPolicy() {
  return (
    <div className='container pt-8'>
        <h1 className='text-center'>REFUND AND CANCELLATION  POLICY</h1>
        <div className="text-center">
            <hr/>
        </div>
        <div className='mt-5'>
            <h2>CANCELLATION  POLICY :</h2>
            <ol>
                <li>Camp seats once booked cannot be transferred to another camp.</li>
                <li>To cancel a camp booking send an official email from your registered email ID to <a href="mailto:accounts@japalouppe.net">accounts@japalouppe.net</a> & <a href="mailto:camps@japalouppe.net">camps@japalouppe.net</a> with details of your child and the camp he/she was enrolled in.</li>
                <li>Japalouppe will cancel the camp seat of your child and you will receive an email requesting your bank details.</li>
                <li>Once we receive the bank details, the refund will be processed in 72 hours as per the REFUND POLICY mentioned below.</li>
            </ol>
        </div>
        <div className='mt-5'>
            <h2>REFUND POLICY:</h2>
            <ol>
                <li>All Cancellations done before 21 days of the camp/event chosen by the user will attract a 10% cancellation fee.</li>
                <li>25% cancellation fee will be charged for cancellations done on and after the 21st day but before the 14th day of the Camp/Event date.</li>
                <li>50% cancellation fee will be charged for cancellations done on and after the 14th day but before the 5th day of the Camp/Event date.</li>
                <li>NO REFUND will be issued for cancellations done on or after the 5th day before the Camp/Event commences.</li>
                <li>NO REFUND for No-Shows for any reasons.</li>
            </ol>
        </div>
        <div className="mt-5">
            <p><em>Note: The mode of refund will be decided by Japalouppe which will be accepted by you.</em></p>
        </div>
    </div>
  )
}
