import React from 'react'

export default function Testimonials() {
  return (
<div id="carouselExampleControls" style={{zIndex:"100"}} className="carousel slide text-center carousel-dark" data-mdb-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8 py-4">
        <i className="fas fa-quote-right fa-lg" color="primary" style={{fontSize:"45px",color:"#a88053",marginBottom:"2.5rem"}}></i>
          <h4 style={{fontSize:"1.2rem"}}>
          Nitya has been having a brilliant time at Jap. This is her 4th camp and the excitement levels pre 
          Jap and post Jap camp are similar except for the accompanying sadness when it is over. 
          Jap has almost become a second home and parents are not worried about their children being at 
          Jap since they know they will be more than fine. Kudos to Lorainne ma'am and Rohan and family 
          for Jap and the instructors, camp moms, volunteers for providing the right conditions for children to 
          learn horse riding and falling in love with animals, all within the safety and comfort of a second home.
          </h4>
          <p className='testimonials-text'>Pratibha Savant</p>
          <p className='testimonials-text'>[ 21-26 March camp ]</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8 py-4">
        <i className="fas fa-quote-right fa-lg" color="primary" style={{fontSize:"45px",color:"#a88053",marginBottom:"2.5rem"}}></i>
          <h4 style={{fontSize:"1.2rem"}}>
          Rhea too had an amazing time at Jap. It was her very first time away from home and was not exactly an animal lover in 
          fact even afraid to go near. But post the camp she has started to adore them and she just can't stop narrating stories about jap. 
          She is eagerly awaiting the next one! Thank you team for such a fun learning experience!
          </h4>
          <p className='testimonials-text'>Nehal Maniar</p>
          <p className='testimonials-text'>[ 21-26 March camp ]</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8 py-4">
        <i className="fas fa-quote-right fa-lg" color="primary" style={{fontSize:"45px",color:"#a88053",marginBottom:"2.5rem"}}></i>
          <h4 style={{fontSize:"1.2rem"}}>
          Thank you so much for a wonderful camp experience. Anaiah has come home so exhilarated and confident. 
          Sending her off to a camp for the first time was a hard process for us but the manner in which you handled the kids filled us with a lot of confidence & trust. 
          Anaiah is already making plans to visit again, this time for a 6 day camp!
          Special shout out to the volunteers for being role models to the kids. 
          Anaiah was inspired by you'll and hopes that she too can one day volunteer at the camps as well. Many thanks to the entire team.

          </h4>
          <p className='testimonials-text'>Sabiha Charania</p>
          <p className='testimonials-text'>[ 7-9 April camp ]</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8 py-4">
        <i className="fas fa-quote-right fa-lg" color="primary" style={{fontSize:"45px",color:"#a88053",marginBottom:"2.5rem"}}></i>
          <h4 style={{fontSize:"1.2rem"}}>
          Kavyaa's experience at the camp was absolutely awesome. I will not deny that she was a bit sceptical being away from home but Jap became a home for her for the 6 days that she was there. 
          She made new friends there and found huge comfort in the environment that's there at Jap! I highly applaud you guys for running the camps so well, 
          for taking such good care of the kids, for making it much more than only horse riding. 
          Kavyaa can't wait to come back for more rides at Jap and continue on her journey of horse riding further
          </h4>
          <p className='testimonials-text'>Freny Nagda</p>
          <p className='testimonials-text'>[ 18-23 April camp ]</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8 py-4">
        <i className="fas fa-quote-right fa-lg" color="primary" style={{fontSize:"45px",color:"#a88053",marginBottom:"2.5rem"}}></i>
          <h4 style={{fontSize:"1.2rem"}}>
          A big thanks to team JAP. My boy Anshul is filled with moments and memories. You have managed to imbibe things into him and Jap is part of him. 
          Thank you to one and all who contributed to his achievement of rising to the trot. 
          Rocking! He is already planning for the next one.

          </h4>
          <p className='testimonials-text'>Shraddha Devrukhkar</p>
          <p className='testimonials-text'>[ 2-7 May camp ]</p>
        </div>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
    data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
    data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  )
}
