import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, {useEffect, useState} from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
           
import {
    Row,
    Col,
    Card,
    CardHeader,
    Container,
    Table,
    Pagination,
    Badge,
    PaginationItem,
    PaginationLink,
  } from 'reactstrap';

export default function ViewAllCoupons() {
    const {data:couponList}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getAllCoupon/');
    const {postData:updateCoupon,resData:updateCouponRes}=usePost();

    const handleDeleteCoupon = (coupon_id) =>{
      const isConfirmed = window.confirm("Are you sure you want to delete this coupon?");
      if (isConfirmed){
        updateCoupon(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/deleteCoupon/"+coupon_id,{'coupon_id':coupon_id},"DELETE") 
      }
    }
    useEffect(() => {
      
      if(updateCouponRes && updateCouponRes.status===200){
        NotificationManager.success('Course deleted successfully!', '', 1000);
        window.location.reload();
      }
    
    }, [updateCouponRes])
    
  return (

    <div className="container pt-8 pb-4">
        <Container className="mt-2" fluid>
        <NotificationContainer/>
        <Row>
          <div className="col">
            <Card className="shadow">
                <CardHeader className="border-0">
                    <Row>
                      <Col lg="6">
                        <h3 className="mb-0">All Coupons</h3>
                      </Col>
                      <Col lg="6" style={{'textAlign':'end'}}>
                        <Link to={'/admin/create-coupons'}><button class="btn btn-primary btn-sm">Create Coupons</button></Link>
                      </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Coupon Name</th>
                            <th scope="col">Coupon Type</th>
                            <th className='text-center'> Discount</th>
                            <th className='text-center'>Coupon Code</th>
                            <th className='text-center'>Coupon Active Status</th>
                            <th className='text-center'>Coupon Discount Type</th>
                            <th className='text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {couponList && couponList.map((coupon)=>(<tr key={coupon.coupon_id}>
                          <th scope="row">
                            <span>{coupon.archive===0 && <Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" />
                            </Badge>}
                            {coupon.archive===1 && <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                            </Badge>}</span><Link to={`/admin/coupon-detail/${coupon.coupon_id}`}>{coupon.coupon_name}</Link>
                            </th>
                            <td className='text-center'>{coupon.is_gift_card===false?"Coupon":"Gift Card"}</td>
                            { coupon.is_gift_card ===true && <td className='text-center'>{coupon.gift_card_amount}%</td>}
                            { coupon.is_gift_card ===false && <td className='text-center'>{coupon.is_percent_discount===false?"₹":""}{coupon.coupon_discount}{coupon.is_percent_discount===true?"%":""}</td>}
                            <td className='text-center'><strong>{coupon.coupon_code}</strong></td>
                            <td className="text-center">{coupon.is_active===true?"Yes":"No"}</td>
                            <td className="text-center">{coupon.is_percent_discount===true?"Percent":"Amount"}</td>
                            <td className='text-center'><button onClick={()=>handleDeleteCoupon(coupon.coupon_id)} class="btn btn-danger btn-sm">Delete</button></td>
                        </tr>))}
                    </tbody>
                </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </div>


  )
}
