// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lock{
    background-color: orange !important;
}
.trophy{
    background-color: rgb(9, 195, 9);
}
.level-connector {
    width: 100%;
    height: 2px; 
    background-color: #ccc; 
    position: absolute;
    top: 62%; 
    transform: translateY(-50%);
    z-index: -1;
  }
  .icon-container{
    margin-right: -6px;
  }
  .slick-next:before,.slick-prev:before{
    color:#09173A !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Parents/components/Slider.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;AACvC;AACA;IACI,gCAAgC;AACpC;AACA;IACI,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,WAAW;EACb;EACA;IACE,kBAAkB;EACpB;EACA;IACE,wBAAwB;AAC5B","sourcesContent":[".lock{\n    background-color: orange !important;\n}\n.trophy{\n    background-color: rgb(9, 195, 9);\n}\n.level-connector {\n    width: 100%;\n    height: 2px; \n    background-color: #ccc; \n    position: absolute;\n    top: 62%; \n    transform: translateY(-50%);\n    z-index: -1;\n  }\n  .icon-container{\n    margin-right: -6px;\n  }\n  .slick-next:before,.slick-prev:before{\n    color:#09173A !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
