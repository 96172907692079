import React from 'react';

export default function ContactUs() {
  return (
    <div className="container pt-7">
      <h1 className='text-center'>Contact Us</h1>
      <div className="text-center">
        <hr />
      </div>
      <div className="row">
        <div className="col-md-6">
          <h2>Contact Information</h2>
          <p>Feel free to get in touch with us for any enquiries or technical support.</p>
          <ul className="list-unstyled">
            <li><strong>Enquiries:</strong> <a href="tel:+918956281070">+91 8956281070</a></li>
            <li><strong>Tech support:</strong> <a href="tel:+919860652967">+91 9860652967</a></li>
            <li><strong>Office timings:</strong> 9.00 am - 5.00 pm (Wednesdays Off)</li>
            <li><strong>Email:</strong> <a href="mailto:camps@japalouppe.net">camps@japalouppe.net</a></li>
          </ul>
        </div>
        <div className="col-md-6">
          <h2>Address</h2>
          <p>Japalouppe Equestrian Centre Pvt. Ltd.</p>
          <p>Off Old Mumbai-Pune Highway,</p>
          <p>Near Somatne Toll Plaza, Talegaon Dabhade,</p>
          <p>Pune 410506, Maharashtra, INDIA.</p>
        </div>
      </div>
    </div>
  );
}
