import React, { useEffect, useState } from 'react';
import './UpcomingEvents.css';
import localImage from "../../../assets/img/theme/counter-bg.jpg"
import { useFetch } from 'hooks/useFetch';
import {
  Button
} from "reactstrap";
import { Link } from 'react-router-dom';
import CampTicket from './CampTicket';
export default function UpcomingEvents({selectedId}) {
  const {data} = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getMember/'+selectedId);
  const [courses,setCourses]=useState(null);
  const [coursesError,setCourseError]=useState(null);
  const fetchCourses = async () => {
  try {
    setCourses(null);
    setCourseError(null);
    const res = await fetch(process.env.REACT_APP_NODE_SERVER_URL+`/api/courses/${(data.memberDetails.current_level_id)}/${data.memberDetails.age}/${data.memberDetails.gender}`);
   
    if (!res.ok) {
      if(res.status===400){
        throw new Error('No course(s) available')
      }
      throw new Error('Something went wrong!')
    }
    
    const responseData = await res.json();
    console.log(responseData)
    setCourses(responseData);
  } catch (err) {
    setCourseError(err.message);
  }
}

  useEffect(()=>{
    if(data){
      fetchCourses();
    }
  },[data])

  return (
  <div className="next__level_camps_container py-0">
    <div className="container mt-6 py-5">
        <h2 className="mb-4">Next Level Camps</h2>
        {coursesError &&<div className='text-center'><h3>{coursesError}</h3></div>}
        <div className="row justify-content-center">
          {courses &&
            courses.map((each) => (
              // <div className="col-sm-4" key={each.course_id}>
              //   <div className="card" style={{ "width": "18rem" }}>
              //     <img src={localImage} className="card-img-top w-100" style={{ "height": "180px", "objectFit": "cover" }} alt="..." />
              //     <div className="card-body">
              //       <div className="row">
              //         <div className="col-sm-8">
              //           <h3 className="card-text">{each.course_name}</h3>
              //         </div>
              //         <div className="col-sm-4">
              //             <h3 className="card-text">₹ {each.price}</h3>
              //         </div>
              //       </div>
                    
              //       <div className="row my-3">
              //         <div className="col-sm-6">
              //         <div>
              //         <p> <small>Start Date</small></p>
              //         <p className='mt--3'><i className='ni ni-calendar-grid-58'></i><span style={{fontWeight:"500",fontSize:"15px"}}>&nbsp;&nbsp;&nbsp;{new Date(each.course_from_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}</span></p>   
              //     </div>
              //         </div>
              //         <div className="col-sm-6">
              //         <div>
              //         <p> <small>End Date</small></p>
              //         <p className='mt--3 text-right'><i className='ni ni-calendar-grid-58'></i><span style={{fontWeight:"500",fontSize:"15px"}}>&nbsp;&nbsp;&nbsp;{new Date(each.course_to_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}</span></p>   
              //     </div>
              //         </div>
                
              //       </div>
                    
              //       {/* <p className="card-text">
              //       {each.description.split(' ').slice(0, 8).join(' ')}...
              //       </p> */}
              //       <Link to={`/parent/course-details/${each.course_id}/${selectedId}`} className="d-flex justify-content-center">
              //         <Button color="primary" className="w-100 primary text-white">View Details</Button>
              //       </Link>
              //     </div>
              //   </div>
              // </div>
              <CampTicket
                key={each.course_id} 
                title={each.course_name} 
                startDate={new Date(each.course_from_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}
                endDate={new Date(each.course_to_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year:'2-digit' })}
                days= {Math.ceil((new Date(each.course_to_date) - new Date(each.course_from_date)) / (1000 * 60 * 60 * 24))+1}
                ages={`${each.from_age} to ${each.to_age}`} 
                price={new Intl.NumberFormat('en-US').format(each.price)} 
                seatsFilled={each.seats_remaining} 
                totalSeats={data.memberDetails.gender === "male" ? each.boys_seats : each.girls_seats } 
                link = {`/parent/course-details/${each.course_id}/${selectedId}`}
              />

            ))}
        </div>
    </div>
  </div>
  );
}
