import React from 'react'
import Slider from "react-slick";
import {
    Row,
    Container,
    Col,
  } from "reactstrap";
  import img1 from "../../assets/img/theme/rrp-bg.jpg"
  import aeg from "../../assets/img/about-us/aeg.jpg"
  import './About.css'
  import lorrainesImg from '../../assets/img/about-us/lorriane.png'
  import rohansImg from '../../assets/img/about-us/rohan-more.png'
  import ridingCampsImg from '../../assets/img/about-us/riding-camps.jpg'
  import pettingFarmImg from '../../assets/img/about-us/petting-farm.jpg'

export default function About() {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true,
            }
          }
        ]
      };
  return (
    <div>
        <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
            minHeight: "150px",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            }}
        >
        {/* Mask */}
        <span className="mask about-us" style={{background:"#f8ece4"}} />
        {/* Header container */}
        <Container fluid>
          <Row className='text-center'>
            <Col lg="12" md="12">
            <h1 className="mb-8 about__h1">Welcome to Japalouppe Equestrian Centre</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="container mt--9">
      <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
            minHeight: "500px",
            backgroundImage:
                "url(" + require("../../assets/img/about-us/banner-image.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            }}
        >
        {/* Mask */}
        {/* <span className="mask opacity-4 bg-gradient-default-1" /> */}
        {/* Header container */}
        {/* <Container fluid>
          <Row className='text-center'>
            <Col lg="12" md="12">
            </Col>
          </Row>
        </Container> */}
      </div>
      </div>
      <div className="container">
            <div className='text-center py-5'>
                <p> In the 1988 Olympic Games, competed a small french horse with big dreams. In 1998, Lorraine More and her son Rohan 
                    had 10 acres of land in Talegaon and the dream of an equestrian facility with end-to-end solutions. They decided to call 
                    the dream, Japalouppe, after the French horse who was considered too short to succeed in the world of show-jumping. 
                    Jappeloup and his rider Pierre Durand went on to win gold and just like our name sake we are now considered amongst 
                    the top riding schools in India.
                </p>
            </div>
            <>
            <div className='row justify-content-center'>
                <div className="col-sm-6 d-flex flex-column align-items-center">
                  <div className="text-center" style={{ width: '100%', height: '350px' }}>
                    <img
                      src={lorrainesImg}
                      alt="Lorraine More"
                      style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition:"top center" }}
                    />
                  </div>
                  <div className="text-center" style={{ background: "#f8ece4", width: "100%" }}>
                    <p className='pt-2'>LORRAINE MORE, FOUNDER & DIRECTOR</p>
                  </div>
                </div>
                <div className="col-sm-6 d-flex flex-column align-items-center">
                        <div className="text-center">
                            <img src={rohansImg} alt="" style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                        </div>
                        <div className="text-center" style={{background:"#f8ece4",width:"100%"}}>
                            <p className='pt-2'>ROHAN MORE, MANAGING DIRECTOR</p>
                        </div>
                </div>
            </div>
            <div className='text-center py-5'>
                <p>Located just off the old Pune-Mumbai Expressway in Maharashtra, the family-run equestrian centre is the only holistic 
                facility in the country. A deep understanding of human-horse relationship and international education has transformed 
                a family passion into an institution that breeds winsome horses, provides stabling facilities, consultancy services, horse 
                riding camps for children and adults, residential camps for schools and is one of the largest private riding schools with 
                over 28,000 riding lessons in various levels and skills</p>
            </div>
            </>
            <>
            <div className='row justify-content-center'>
                <div className="col-sm-6 d-flex flex-column align-items-center">
                        <div className="text-center">
                            <img src={img1} alt="" style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                        </div>
                        <div className="text-center" style={{background:"#f8ece4",width:"100%"}}>
                            <p className='pt-2'>HORSE RIDING LESSONS</p>
                        </div>
                </div>
                <div className="col-sm-6 d-flex flex-column align-items-center">
                        <div className="text-center">
                            <img src={ridingCampsImg} alt="" style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                        </div>
                        <div className="text-center" style={{background:"#f8ece4",width:"100%"}}>
                            <p className='pt-2'>RIDING CAMPS</p>
                        </div>
                </div>
            </div>
            <div className='text-center py-5'>
                <p>The Mores believe riding is for everyone and that a trusting relationship with the steed enriches both species. Skilled 
                instructors handhold newcomers of all ages through workshops to lead them into an understanding of the magical animal. 
                Competing in the three-day Annual Equestrian Games is now a mark of pride for every professional and enthusiast 
                horse-rider. Institutions from all over India gather at Japalouppe to test their skills</p>
            </div>
            </>
            <>
            <div className='row justify-content-center'>
                <div className="col-sm-6 d-flex flex-column align-items-center">
                        <div className="text-center">
                            <img src={aeg} alt="" style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                        </div>
                        <div className="text-center" style={{background:"#f8ece4",width:"100%"}}>
                            <p className='pt-2'>ANNUAL EQUESTRIAN GAMES</p>
                        </div>
                </div>
                <div className="col-sm-6 d-flex flex-column align-items-center">
                        <div className="text-center">
                            <img src={pettingFarmImg} alt="" style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                        </div>
                        <div className="text-center" style={{background:"#f8ece4",width:"100%"}}>
                            <p className='pt-2'>PETTING FARM EXPERIENCE</p>
                        </div>
                </div>
            </div>
            <div className='text-center py-5'>
                <p>Japalouppe’s petting farm has cuddly pigs, exotic and goofy dogs, nosy guinea pigs and rabbits, opinionated cats, chatty 
                exotic birds and sweet riding ponies all within reach of toddler and small children.</p>
            </div>
            </>
      </div>
      <div className='mt-5 py-5 text-center d-none' style={{backgroundColor:'#f8ece4'}}>
        <h1 className='pb-4 about__h2'> a photo tour of jap</h1>
        <div className="slider-container container ">
      <Slider {...settings}>
        <div className="text-center">
            <img src={img1}  style= {{width:"100%",height:"500px",objectFit:"cover"}}alt="" />
        </div>
        <div className="text-center">
            <img src={img1}  style= {{width:"100%",height:"500px",objectFit:"cover"}}alt="" />
        </div>
        <div className="text-center">
            <img src={img1}  style= {{width:"100%",height:"500px",objectFit:"cover"}}alt="" />
                <small className="text-white">ROHAN MORE,<br/>
                DIRECTOR, JAPALOUPPOE
        </small>
        </div>
        <div className="text-center">
            <img src={img1}  style= {{width:"100%",height:"500px",objectFit:"cover"}}alt="" />
        </div>
        <div className="text-center">
            <img src={img1}  style= {{width:"100%",height:"500px",objectFit:"cover"}}alt="" />
        </div>
        <div className="text-center">
            <img src={img1}  style= {{width:"100%",height:"500px",objectFit:"cover"}}alt="" />
        </div>
      </Slider>
    </div>
    </div>
      </div>
  )
}
